// SuccessPage.js

import React from "react";

const SuccessPage = ({ selectedInfluencers }) => {
  return (
    <div className="container mt-6 has-text-centered">
      <h2>Form submitted successfully</h2>
    </div>
  );
};

export default SuccessPage;
