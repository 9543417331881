import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { countryCode, countryName, value, percentage } = payload[0].payload;
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          padding: "10px",
        }}
      >
        <p>
          <strong>Country:</strong> {countryName} ({countryCode})
        </p>
        <p>
          <strong>Value:</strong> {value}
        </p>
        <p>
          <strong>Percentage:</strong> {percentage}%
        </p>
      </div>
    );
  }
  return null;
};

const CountryBarChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={data}
        layout="vertical" // Makes the chart horizontal
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis dataKey="countryName" type="category" width={100} />
        <Tooltip content={<CustomTooltip />} /> {/* Use the custom tooltip */}
        <Legend />
        <Bar dataKey="value" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CountryBarChart;
