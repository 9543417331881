// api.js
import { Config } from "../.env.js";

// Function to fetch all campaigns
export const getAllCampaigns = async () => {
  const response = await fetch(`${Config.API_URL}/show-campaign`);
  if (!response.ok) {
    throw new Error("Failed to fetch campaigns");
  }
  return response.json();
};

// components/campaignApi.js

// Update with your backend API base URL

export const updateApprove = async (campaignId) => {
  try {
    const baseURL = `${Config.API_URL}`;
    const response = await fetch(`${baseURL}/campaigns/${campaignId}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    // const text = await response.text(); // Read response as text first
    // console.log("Raw Response:", text); // Log response

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.message || "Failed to update campaign approval status"
      );
    }

    return await response.json();
  } catch (error) {
    throw new Error(
      error.message || "Failed to update campaign approval status"
    );
  }
};

// Function to create a campaign
export const createCampaign = async (campaignData) => {
  try {
    const BASE_URL = `${Config.API_URL}/create-campaign`;
    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(campaignData),
    });
    if (!response.ok) {
      throw new Error("Failed to create campaign: " + response.statusText);
    }
    const data = await response.json();
    console.log("Campaign created successfully:", data);
    return data;
  } catch (error) {
    console.error("Error creating campaign:", error);
    throw error; // Rethrow the error for handling in the calling code
  }
};
// Function to approve a campaign
// campaignApi.js

// Function to approve a campaign
export const approveCampaign = async (campaignId) => {
  try {
    const BASE_URL = `${Config.API_URL}`;
    const response = await fetch(`${BASE_URL}/${campaignId}/approve`, {
      method: "PUT",
    });
    if (!response.ok) {
      throw new Error(`Failed to approve campaign: ${response.statusText}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error approving campaign:", error);
    throw new Error("Failed to approve campaign");
  }
};
