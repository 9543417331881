import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "bulma/css/bulma.min.css"; // Import Bulma CSS
import "../styles/UserListPageInsta.css"; // Ensure your custom CSS is imported
import { Config } from "../.env.js";
import LogoutInsta from "../components/LogoutInsta";

const UserList = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    axios
      .get(`${Config.API_URL}/api/data`)
      .then((response) => setData(response.data))
      .catch((error) => console.error("Error fetching users:", error));
  }, []);

  if (!data || !data.profile) {
    return <div className="container">Loading...</div>;
  }

  // console.log("Data:", data);
  // console.log("Data Profile:", data.profile);

  return (
    <>
      <div className="container1">
        <h1 className="title is-3 my-5">Instagram Users</h1>
        <div className="columns is-multiline" style={{ width: "20%" }}>
          {data.profile.map((profile) => (
            <div key={profile.instagramAccountId} className="column">
              <div className="card">
                <Link to={`/influencer/${profile.instagramAccountId}`}>
                  <div className="card-image">
                    <figure className="image is-128x128">
                      <img
                        src={profile.profilePictureUrl || "default-profile.png"}
                        alt={profile.username}
                        className="is-rounded"
                      />
                    </figure>
                  </div>
                  <div>
                    <div className="media">
                      <div
                        className="media-content"
                        style={{ textAlign: "start", padding: "20px" }}
                      >
                        <p className="title is-4">{profile.username}</p>
                        <p className="subtitle is-6">
                          {profile.followersCount} Followers
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        // className="container"
        style={{
          position: "fixed",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          backgroundColor: "white",
          // gap: "20px",
          padding: "10px",
          // bottom: "30px",
          // right: "20px",
          // left: "0",
          // marginRight: "30px",
          top: "20px",
          right: "00px",
          borderRadius: "10px 0 0 10px",
        }}
      >
        <button
          className="button insta-insights-cta"
          style={{ margin: "10px 0 30px 0" }}
          onClick={() => (window.location.href = `/influencers`)}
        >
          <i class="fa-solid fa-house fa-lg"></i>
          <br></br>
          <span className="font-weight-800" style={{ marginTop: "-15px" }}>
            Home
          </span>
        </button>
        <LogoutInsta />
        {/* <button>
        <i className="fa-solid fa-gear mr-2"></i>Settings
      </button> */}
      </div>
    </>
  );
};

export default UserList;
