import { Config } from "../.env.js";
export const signup = async ({
  yourName,
  email,
  phone,
  password,
  otp,
  brandName,
}) => {
  const user = { yourName, email, phone, password, otp, brandName };

  try {
    const res = await fetch(`${Config.API_URL}/signup`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });
    return await res.json();
  } catch (err) {
    throw new Error(`cannot register at this time . ${err}`);
  }
};

// admin sugnin
export const login = async ({ email, password, brandName, role } = {}) => {
  const user = { email, password, brandName, role };

  try {
    const res = await fetch(`${Config.API_URL}/login`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });
    return await res.json();
  } catch (err) {
    throw new Error(`Cannot login at this time. ${err}`);
  }
};
export const signIn = async ({ email, password, brandName } = {}) => {
  const user = { email, password, brandName };

  try {
    const res = await fetch(`${Config.API_URL}/signIn`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });
    return await res.json();
  } catch (err) {
    throw new Error(`Cannot login at this time. ${err}`);
  }
};

// Influencer Signup
export const influencerSignup = async ({ FullName, Email, password, OTP }) => {
  const user = { FullName, Email, password, OTP };

  try {
    const res = await fetch(
      `${Config.API_URL}/api/influencer/influencer-signup`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      }
    );
    return await res.json();
  } catch (err) {
    throw new Error(`cannot register at this time . ${err}`);
  }
};

// Influencer Login
export const influencerLogin = async ({ email, password } = {}) => {
  const user = { email, password };

  try {
    const res = await fetch(
      `${Config.API_URL}/api/influencer/influencer-login`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      }
    );
    return await res.json();
  } catch (err) {
    throw new Error(`Cannot login at this time. ${err}`);
  }
};
