import React from "react";

const CampaignProgress = ({ campaign }) => {
  const getProgressColor = () => {
    if (campaign.approve) return "green";
    if (campaign.rejected) return "red";
    return "#3E3E3E";
  };

  return (
    <div className="progress-card">
      <div
        className="progress-card-sub"
        // style={{
        //   display: "flex",
        //   justifyContent: "space-between",
        //   marginBottom: "8px",
        //   fontSize: "14px",
        // }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="/images/codicon_request-changes.svg"
            alt="icon"
            style={{ width: "22px" }}
          ></img>
          <span
            style={{
              color: "#3E3E3E",
              //   fontFamily: "SF Pro Display",
              // fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              letterSpacing: "0.7px",
            }}
          >
            Requested
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="/images/Frame 179.svg"
            alt="icon"
            style={{ width: "33px" }}
          ></img>
          <span
            style={{
              color: "#3E3E3E",
              //   fontFamily: "SF Pro Display",
              // fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              letterSpacing: "0.7px",
              marginTop: "10px",
            }}
          >
            Pending for approval
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="/images/subway_tick.svg"
            alt="icon"
            style={{ width: "11px" }}
          ></img>
          <span
            style={{
              color: "#3E3E3E",
              //   fontFamily: "SF Pro Display",
              // fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              letterSpacing: "0.7px",
              marginTop: "7px",
            }}
          >
            {campaign.approve
              ? "Approved"
              : campaign.rejected
              ? "Rejected"
              : "Approved"}
          </span>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          height: "5px",
          // backgroundColor: "#e0e0e0",
          backgroundColor: "rgba(153, 153, 153, 0.20)",
          borderRadius: "24px",
          width: "100%",
          padding: "0px 3px",
        }}
      >
        <div
          style={{
            width: campaign.approve
              ? "100%"
              : campaign.rejected
              ? "100%"
              : "50%",
            backgroundColor: getProgressColor(),
            height: "100%",
            borderRadius: "4px",
            transition: "width 0.3s ease",
          }}
        />
        {!campaign.approve && !campaign.rejected ? (
          <div
            style={{
              // position: "absolute",
              marginTop: "-13px",
              marginLeft: "6px",
            }}
          >
            <img src="/images/Ellipse 65.svg" alt="icon" />
          </div>
        ) : (
          <div
            style={{
              position: "absolute",
              right: 1,
              marginTop: "-13px",
              // marginLeft: "6px",
            }}
          >
            <img src="/images/Ellipse 65.svg" alt="icon" />
          </div>
        )}

        {/* <div
          style={{
            position: "absolute",
            top: "-6px",
            left: "0%",
            transform: "translateX(-50%)",
            width: "10px",
            height: "10px",
            backgroundColor:
              campaign.approve || campaign.rejected
                ? getProgressColor()
                : "black",
            borderRadius: "50%",
          }}
        ></div> */}
        {/* <div
          style={{
            position: "absolute",
            top: "-6px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "10px",
            height: "10px",
            backgroundColor: campaign.approve ? getProgressColor() : "black",
            borderRadius: "50%",
          }}
        ></div> */}
        {/* <div
          style={{
            position: "absolute",
            top: "-6px",
            left: "100%",
            transform: "translateX(-50%)",
            width: "10px",
            height: "10px",
            backgroundColor: campaign.approve ? "green" : "#e0e0e0",
            borderRadius: "50%",
          }}
        ></div> */}
      </div>
    </div>
  );
};

export default CampaignProgress;
