import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Config } from "../.env.js";
import logo from "../logo192.png";
import CombinedRange from "../components/CombinedRange.js";
import axios from "axios";

const AllCamp = ({ campaign, email }) => {
  const navigate = useNavigate();
  const [isApproved, setIsApproved] = useState(null);
  const [isApplied, setIsApplied] = useState(null);
  const [isConfirmationApproved, setIsConfirmationApproved] = useState(null);
  const [isDeliverableApproved, setIsDeliverableApproved] = useState(null);
  // const [errorMessage, setErrorMessage] = useState("");
  console.log("Email:", email);
  console.log("Brand Name:", campaign.brandName);
  console.log("Campaign Name:", campaign.campaignName);

  // Function to check if influencer has applied once
  const checkInfluencerApplication = async () => {
    try {
      const response = await axios.post(
        `${Config.API_URL}/api/influencer/applied`,
        {
          email: email,
          brandName: campaign.brandName,
          campaignName: campaign.campaignName,
        }
      );
      // console.log("Influencer Application Response:", response);
      if (response.data.success) {
        setIsApplied(true); // Allow access
      } else {
        setIsApplied(false);
      }
    } catch (error) {
      setIsApplied(false);
    }
  };
  // Function to check influencer approval
  const checkInfluencerApproval = async () => {
    try {
      const response = await axios.post(
        `${Config.API_URL}/api/influencer/check-influencer-approval`,
        {
          email: email,
          brandName: campaign.brandName,
          campaignName: campaign.campaignName,
        }
      );

      if (response.data.success) {
        setIsApproved(true); // Allow access
      } else {
        setIsApproved(false);
        // setErrorMessage(response.data.message);
      }
    } catch (error) {
      setIsApproved(false);
      // setErrorMessage(
      //   error.response?.data?.message || "Failed to check approval status."
      // );
    }
  };
  // Function to check confirmation approval
  const checkConfirmationApproval = async () => {
    try {
      const response = await axios.post(
        `${Config.API_URL}/api/influencer/check-confirmation-approval`,
        {
          email: email,
          brandName: campaign.brandName,
          campaignName: campaign.campaignName,
        }
      );

      if (response.data.success) {
        setIsConfirmationApproved(true); // Allow access
      } else {
        setIsConfirmationApproved(false);
        // setErrorMessage(response.data.message);
      }
    } catch (error) {
      setIsConfirmationApproved(false);
      // setErrorMessage(
      //   error.response?.data?.message || "Failed to check approval status."
      // );
    }
  };
  // Function to check deliverable approval
  const checkDeliverableApproval = async () => {
    try {
      const response = await axios.post(
        `${Config.API_URL}/api/influencer/check-deliverable-approval`,
        {
          email: email,
          brandName: campaign.brandName,
          campaignName: campaign.campaignName,
        }
      );

      if (response.data.success) {
        setIsDeliverableApproved(true); // Allow access
      } else {
        setIsDeliverableApproved(false);
        // setErrorMessage(response.data.message);
      }
    } catch (error) {
      setIsDeliverableApproved(false);
      // setErrorMessage(
      //   error.response?.data?.message || "Failed to check approval status."
      // );
    }
  };

  // Run functions as soon as the page loads
  useEffect(() => {
    checkInfluencerApplication();
    checkInfluencerApproval();
    checkConfirmationApproval();
    checkDeliverableApproval();
  }, []); // Runs once when the component mounts

  const handleApply = () => {
    navigate("/application-form", { state: { campaign, email } });
    // navigate(`/application-form/${campaignName}`);
  };
  // const handleApply = () => {
  //     // Navigate to the external URL directly
  //     window.location.href = 'https://influencer.hobo.video/';
  // };

  const handleApplyDeliverables = () => {
    navigate("/inf-deliverbles", { state: { campaign, email } });
  };

  const handleConfirmation = () => {
    navigate("/confirmation-page", { state: { campaign, email } });
  };

  const handleSubmitLiveLinks = () => {
    navigate("/live-links", { state: { campaign, email } });
  };

  const {
    brandName,
    followersCriteria,
    infDeliverables,
    cashValue,
    campaignName,
    uploadBrandLogo,
    approve,
    rejected,
    dealtype,
  } = campaign;

  let statusLabel = "";
  let statusColor = "";
  if (approve) {
    statusLabel = "Approved Successfully";
    statusColor = "green";
  } else if (rejected) {
    statusLabel = "This campaign is Rejected";
    statusColor = "red";
  } else {
    statusLabel = "Pending";
    statusColor = "orange";
  }

  const backgroundImageStyle = {
    position: "relative",
    backgroundImage: `url(${Config.API_URL}/uploads/${encodeURIComponent(
      uploadBrandLogo
    )})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "125px", // Adjust height as needed
    // width: "486px", // Full width to match the button card below
    borderRadius: "6px",
    // marginBottom: "-10px",
    // marginLeft: "-15px",
    // marginTop: "-15px",
  };
  const logoStyle = {
    position: "absolute",
    bottom: "5px",
    left: "5px",
    width: "46px",
    height: "46px",
  };

  console.log("Email:", email);
  console.log("Brand Name:", brandName);
  console.log("Campaign Name:", campaignName);

  return (
    <div className="container" style={{ position: "relative" }}>
      <div
        className="card-content"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div
          className="box mt-2"
          style={{
            maxWidth: 496,
            width: "100%",
            // height: 510
            height: "auto",
            borderRadius: "10px",
            paddingBottom: "6px",
          }}
        >
          <div className="background-image-style" style={backgroundImageStyle}>
            <img src={logo} alt="Logo" style={logoStyle} />
          </div>
          <br />
          <div className="card-title-section">
            <p className="card-title-label">Title</p>
            <h1 className="subtitle card-title">
              <b>{campaignName}</b>
            </h1>
          </div>
          <div>
            {/* Brand Name and Followers Criteria Section */}
            <div
              style={{
                // display: "flex",
                // justifyContent: "space-between",
                marginTop: "10px",
                backgroundColor: "#FBFBFB",
                padding: "19px 11px",
                // marginLeft: "-15px",
                // marginRight: "-15px",
                border: "1px solid #F1F1F1",
                borderRadius: "3px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  // gap: "5px",
                  // marginTop: "30px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "26%",
                  }}
                >
                  <span className="icon">
                    {/* <i className="fas fa-tag"></i> */}
                    <img src="/images/BrandName.svg" alt="icon"></img>
                  </span>
                  <p
                    className="card-detail-label"
                    style={{ marginRight: "10px" }}
                  >
                    Brand Name
                  </p>
                  <p className="card-detail">{brandName}</p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "33%",
                  }}
                >
                  <span className="icon" style={{ marginRight: "125px" }}>
                    {/* Icon for followers */}
                    {/* <i className="fas fa-user-friends"></i>{" "} */}
                    <img src="/images/FollowersCriteria.svg" alt="icon"></img>
                  </span>
                  {/* <p className="card-detail-label">Followers Criteria</p>
                  <p className="card-detail">{followersCriteria}</p> */}
                  <CombinedRange followersCriteria={followersCriteria} />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "26%",
                  }}
                >
                  <span className="icon" style={{ marginRight: "125px" }}>
                    {/* <i className="fas fa-user-friends"></i>{" "} */}
                    {/* Icon for followers */}
                    <img src="/images/ph_handshake-thin.png" alt="icon"></img>
                  </span>
                  <p className="card-detail-label">Deal Type</p>
                  <p className="card-detail">{dealtype}</p>
                </div>
              </div>

              {/* deliverables and Give Products Worth Section */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "30px",
                  gap: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <span className="icon">
                    {/* <i className="fas fa-tag"></i> */}
                    <img src="/images/Deliverables.svg" alt="icon"></img>
                  </span>
                  <p
                    className="card-detail-label"
                    style={{ marginRight: "10px" }}
                  >
                    Deliverables
                  </p>
                  <p className="card-detail">{infDeliverables}</p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <span className="icon" style={{ marginRight: "130px" }}>
                    {/* <i className="fas fa-user-friends"></i>{" "} */}
                    {/* Icon for followers */}
                    <img src="/images/GiveProductsWorth.svg" alt="icon"></img>
                  </span>
                  <p
                    className="card-detail-label"
                    style={{ marginRight: "10px" }}
                  >
                    Give Products Worth
                  </p>
                  <p className="card-detail">{cashValue}</p>
                </div>
              </div>
            </div>
            {/* <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "25px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <span className="icon">
                  <i className="fas fa-tag"></i>
                </span>
                <p
                  className="has-text-grey-light"
                  style={{ marginRight: "10px" }}
                >
                  Brand Name
                </p>
                <p className="has-text-weight-bold">{brandName}</p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <span className="icon" style={{ marginRight: "125px" }}>
                  <i className="fas fa-user-friends"></i>{" "} */}
            {/* Icon for followers */}
            {/* </span>
                <p className="has-text-grey-light mr-5">Followers Criteria</p>
                <p className="has-text-weight-bold mr-5">{followersCriteria}</p>
              </div>
            </div> */}

            {/* deliverables and Give Products Worth Section */}
            {/* <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "50px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <span className="icon">
                  <i className="fas fa-tag"></i>
                </span>
                <p
                  className="has-text-grey-light"
                  style={{ marginRight: "10px" }}
                >
                  Deliverables
                </p>
                <p className="has-text-weight-bold">{infDeliverables}</p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <span className="icon" style={{ marginRight: "130px" }}>
                  <i className="fas fa-user-friends"></i>{" "} */}
            {/* Icon for followers */}
            {/* </span>
                <p
                  className="has-text-grey-light"
                  style={{ marginRight: "10px" }}
                >
                  Give Products Worth
                </p>
                <p className="has-text-weight-bold mr-1">{cashValue}</p>
              </div>
            </div> */}

            <div
              className="mt-3"
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                justifyContent: "space-between",
                // marginLeft: "-15px",
                // marginRight: "-15px",
              }}
            >
              {/* <p>isApplied: {String(isApplied)}</p> */}
              <button
                onClick={handleApply}
                className="button is-small-custom card-button"
                style={{ padding: "5px" }}
                disabled={isApplied}
                title={
                  isApplied ? "You have already applied" : "Click to apply"
                }
              >
                {isApplied}
                Apply Now
              </button>
              <button
                onClick={handleConfirmation}
                className="button is-small-custom card-button"
                style={{ padding: "5px" }}
                disabled={!isApproved}
              >
                Confirmation
              </button>
              <button
                onClick={handleApplyDeliverables}
                className="button is-small-custom card-button"
                style={{ padding: "5px" }}
                disabled={!isConfirmationApproved}
              >
                Deliverables
              </button>
              <button
                onClick={handleSubmitLiveLinks}
                className="button is-small-custom card-button"
                style={{ padding: "5px" }}
                disabled={!isDeliverableApproved}
              >
                Live Links
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCamp;

//   <p>Campaign Title: {campaignName}</p>
//                         <p>Brand Name: {brandName}</p>
//                         <p>Followers Criteria: {followersCriteria}</p>
//                         <p>Deliverables: {infDeliverables}</p>
//                         <p>Give Product Worth Rs: {cashValue}</p>

// import React, { useState, useEffect } from 'react';
// import topImage from './img/download.jpeg';
// import logo from "./img/Logo (1).png";
// import { useNavigate } from 'react-router-dom';
// import { Config } from '../.env.js';
// import axios from 'axios';

// const CustomerCard = ({ campaign = "Hello", alignLeft, }) => {
//     // Styles for the top image card
//     const { campaignName, managers } = campaign;
//     const [product, setProduct] = useState(null);
//     // const { image } = product
//     useEffect(() => {
//         const fetchProduct = async () => {
//             try {
//                 const response = await axios.get(`${Config.API_URL}/api/product`, {
//                     params: { campaignName } // Send campaignName as parameter if needed
//                 });
//                 setProduct(response.data); // Update state with fetched data
//             } catch (error) {
//                 console.error("Error fetching product:", error);
//             }
//         };

//         fetchProduct();
//     }, [campaignName]);
//     const topImageStyle = {
//         backgroundImage: `url(${topImage})`,
//         backgroundSize: 'cover',
//         backgroundPosition: 'center',
//         height: '170px', // Adjust height as needed
//         width: '450px', // Full width to match the button card below
//         borderRadius: '6px',
//         marginBottom: '-10px',
//         marginLeft: '-15px',
//         marginTop: '-13px',

//     };
//     const logoStyle = {
//         position: 'absolute',
//         bottom: '100px',
//         left: '35px',
//         width: '40px',
//         height: '36px',
//         top: "300px"
//     };

//     const navigate = useNavigate();

//     const title = {
//         title: "Subtitle"
//     };
//     const brandName = {
//         brandName: "BrandName"
//     }

//     const followersCriteria = {
//         followersCriteria: "followersCriteria"
//     }
//     const deliverables = {
//         deliverables: "deliverables"
//     }
//     const GiveProductsWorth = {
//         GiveProductWorth: "Give Products Worth"
//     }
//     const dealtype = {
//         dealtype: "deal type"
//     }
//     const handleApply = () => {
//         navigate(`/campaignDetails`, { state: { campaignName } });
//     };

//     const handleApplyDeliverables = () => {
//         navigate(`/order-form`, { state: { campaignName } });

//     };

//     const handleConfirmation = () => {
//         navigate(`/refund-form`, { state: { campaignName } });
//     };

//     const handleOrders = () => {
//         navigate(`/order-table`, { state: { campaignName } });
//     };

//     return (
//         <div className='is-max-desktop'>
//             <div >
//                 {/* Button Card */}
//                 <div className="card-content">
//                     <div className="box mt-6 ml-5" style={{ width: 476, height: 550, }}>
//                         <div style={topImageStyle}>
//                             <img src={logo} alt="Logo" style={logoStyle} />
//                         </div>
//                         <br />
//                         <div>
//                             {/* Brand Name and Followers Criteria Section */}
//                             <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "20px" }}>
//                                 <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
//                                     <span className="icon">
//                                         <i className="fas fa-tag"></i>
//                                     </span>
//                                     <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Brand Name</p>
//                                     <p className='has-text-weight-bold'>{brandName.brandName}</p>
//                                 </div>

//                                 <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
//                                     <span className="icon" style={{ marginRight: "125px" }}>
//                                         <i className="fas fa-user-friends"></i> {/* Icon for followers */}
//                                     </span>
//                                     <p className="has-text-grey-light mr-5" >Followers Criteria</p>
//                                     <p className='has-text-weight-bold mr-5'>{followersCriteria.followersCriteria}</p>
//                                 </div>
//                             </div>

//                             {/* deliverables and Give Products Worth Section */}
//                             <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "20px" }}>
//                                 <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
//                                     <span className="icon">
//                                         <i className="fas fa-tag"></i>
//                                     </span>
//                                     <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Deliverables</p>
//                                     <p className='has-text-weight-bold'>{deliverables.deliverables}</p>
//                                 </div>

//                                 <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
//                                     <span className="icon" style={{ marginRight: "130px" }}>
//                                         <i className="fas fa-user-friends"></i> {/* Icon for followers */}
//                                     </span>
//                                     <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Give Products Worth</p>
//                                     <p className='has-text-weight-bold mr-1'>{GiveProductsWorth.GiveProductWorth}</p>
//                                 </div>
//                             </div>

//                             <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "20px" }}>
//                                 <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
//                                     <span className="icon">
//                                         <i className="fas fa-tag"></i>
//                                     </span>
//                                     <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Deal Type</p>
//                                     <p className='has-text-weight-bold'>{dealtype.dealtype}</p>
//                                 </div>
//                             </div>

// <div style={{ flexDirection: 'row', gap: '10px', }}>
//     <button onClick={handleApply} className="button is-small-custom  mt-3 ml-1 has-text-info " style={{ width: '210px', height: '30px' }}>View Campaign</button>
//     <button onClick={handleApplyDeliverables} className="button is-small-custom  mt-3 ml-1 has-text-info " style={{ width: '210px', height: '30px' }}>View Order Form</button>

//     <button onClick={handleConfirmation} className="button is-small-custom  mt-2 ml-1 has-text-info " style={{ width: '210px', height: '30px' }}>View Refund Form</button>
//     <button onClick={handleOrders} className="button is-small-custom  mt-2 ml-1 has-text-info" style={{ width: '210px', height: '30px' }}>View Orders</button>

// </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div >
//     );
// }

// export default CustomerCard;
