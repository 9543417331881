import React from "react";

const PasswordValidation = ({
  hasSixChar,
  hasLowerChar,
  hasUpperChar,
  hasNumber,
  hasSpecialChar,
}) => {
  const renderValidation = (condition, text) => {
    return condition ? (
      <span className="has-text-success">
        <i className="fas fa-check-circle mr-1"></i>
        <small>{text}</small>
      </span>
    ) : (
      <span className="has-text-danger">
        <i className="fas fa-times-circle mr-1"></i>
        <small>{text}</small>
      </span>
    );
  };

  return (
    <div style={{ columns: 1 }} className="mr-5">
      <div className="has-text-left">
        {renderValidation(hasSixChar, "at least 6 characters")}
      </div>

      <div className="has-text-left">
        {renderValidation(hasLowerChar, "one lower case")}
      </div>

      <div className="has-text-left">
        {renderValidation(hasUpperChar, "one upper case")}
      </div>

      <div className="has-text-left">
        {renderValidation(hasNumber, "one number")}
      </div>

      <div className="has-text-left">
        {renderValidation(hasSpecialChar, "one special case")}
      </div>
    </div>
  );
};

export default PasswordValidation;
