import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../logo192.png";
const SignupDashboard = ({ setShowHeader }) => {
  useEffect(() => {
    // Set showHeader to false when the LoginDashboard component mounts
    setShowHeader(false);

    // Cleanup function to set showHeader back to true when the component unmounts
    return () => {
      setShowHeader(true);
    };
  }, [setShowHeader]);
  return (
    <div className="imgfull">
      <div className="logo-card" style={{ marginTop: "10%" }}>
        <figure className="image is-64x64">
          <img src={logo} alt="Logo" />
        </figure>
      </div>
      <div className="container has-text-centered mt-3">
        <h1 className="subtitle mt-5 has-text-white">Signup Dashboard</h1>
        <div className="control my-input">
          <Link to="/internal-signup">
            <button className="button is-primary is-rounded  is-focused is-medium is-fullwidth ">
              SignUp as Admin
            </button>
          </Link>
        </div>
        <div className="control mt-3 my-input">
          <Link to="/internal-signup">
            <button className="button is-primary is-rounded  is-focused is-medium is-fullwidth ">
              SignUp as Lead
            </button>
          </Link>
        </div>

        <div className="control mt-3 my-input">
          <Link to="/internal-signup">
            <button className="button is-primary is-rounded  is-focused is-medium is-fullwidth ">
              SignUp as Executive
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignupDashboard;
