import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // You can use this if the email is passed via location state
import axios from "axios"; // For making API requests
import { Config } from "../.env.js";

// Create the UserContext
const UserContext = createContext();

// Define and export the provider
export const UserProvider = ({ children }) => {
  const [userRole, setUserRole] = useState(null); // Start with null, as we need to fetch from DB
  const [loading, setLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Error state for handling API errors
  const location = useLocation(); // You may get the email from URL or context/state
  const [brandName, setBrandName] = useState([]); // State to store brand names

  // Get email from URL or use some other method to get the logged-in user
  const email = location.state?.email || ""; // Adjust if the email is passed via context, URL, or some other method
  // console.log("Email from User Context:", email);

  // Fetch the user role from the database when the component mounts
  useEffect(() => {
    // Check if email exists before making the request
    if (!email) {
      console.log("No email found, skipping API call.");
      setLoading(false); // Finish loading since no email
      return;
    }

    const fetchUserRole = async () => {
      try {
        setLoading(true); // Set loading true before making the request

        // Attempt to fetch the user role from the API
        const response = await axios.get(
          `${Config.API_URL}/api/getUserRole?email=${email}`
        );

        console.log("API Response:", response.data);

        // Check if the response contains role data
        if (response.data && response.data.role) {
          setUserRole(response.data.role);
        } else {
          setError("Role not found for the given email");
          setUserRole(null);
        }
      } catch (err) {
        // Catch any errors and log them
        console.error("Error fetching user role:", err);
        setError("Failed to fetch user role");
      } finally {
        setLoading(false); // Set loading false once request is complete
      }
    };

    const fetchBrandName = async () => {
      try {
        setLoading(true); // Set loading true before making the request

        // Attempt to fetch the brand name from the API
        const response = await axios.get(
          `${Config.API_URL}/api/getBrandName?email=${email}`
        );

        // Check if the response contains brand name data
        if (response.data && response.data.brandName) {
          setBrandName(response.data.brandName);
        } else {
          setError("Brand not found for the given email");
        }
      } catch (err) {
        // Catch any errors and log them
        console.error("Error fetching brand name:", err);
        setError("Failed to fetch brand name");
      } finally {
        setLoading(false); // Set loading false once request is complete
      }
    };
    console.log(`Fetching user role for email: ${email}`);
    fetchUserRole();
    fetchBrandName();
    // console.log("User Role from User Context:", userRole);
    // console.log("Brand Name from User Context:", brandName);
  }, [email]); // Dependency on email to re-fetch if the email changes

  // // Fetch the brand names
  // const fetchBrandName = async () => {
  //   console.log("Email for Brand fetch:", email);
  //   // Check if email exists before making the request
  //   if (!email) {
  //     setLoading(false); // Finish loading since no email
  //     return;
  //   }

  //   try {
  //     setLoading(true); // Set loading to true while fetching
  //     const response = await axios.get(`${Config.API_URL}/api/getBrandName`);

  //     if (response.data && response.data.brandName) {
  //       setBrandName(response.data.brandName); // Update state with fetched brand names
  //     } else {
  //       setError("Failed to fetch brand names");
  //     }
  //   } catch (err) {
  //     console.error("Error fetching brand names:", err);
  //     setError("Failed to fetch brand names");
  //   } finally {
  //     setLoading(false); // Set loading false after fetching
  //   }
  // };

  // // Fetch brand names when the component mounts
  // useEffect(() => {
  //   fetchBrandName();
  // }, [email]);

  return (
    <UserContext.Provider
      value={{
        userRole,
        brandName,
        loading,
        error,
      }}
    >
      {console.log("UserContext Providing Role:", userRole)}
      {children}
    </UserContext.Provider>
  );
};

// Create and export the custom hook
export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
