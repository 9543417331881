import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Config } from "../.env.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../logo192.png";
import PasswordValidation from "../components/PasswordValidation.js";
const UpdatePasswordInternal = () => {
  //   const [Password, setpassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle showPassword state
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword); // Toggle showPassword state
  };
  // password validations regx
  const [Password, setPassword] = useState("");
  const [validations, setValidations] = useState({
    hasSixChar: false,
    hasLowerChar: false,
    hasUpperChar: false,
    hasNumber: false,
    hasSpecialChar: false,
  });

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    setValidations({
      hasSixChar: value.length >= 6,
      hasLowerChar: /[a-z]/.test(value),
      hasUpperChar: /[A-Z]/.test(value),
      hasNumber: /[0-9]/.test(value),
      hasSpecialChar: /(.*[^a-zA-Z0-9].*)/.test(value),
    });
  };
  //   let hasSixChar = Password.length >= 6;
  //   let hasLowerChar = /[a-z]/.test(Password);
  //   let hasUpperChar = /[A-Z]/.test(Password);
  //   let hasNumber = /[0-9]/.test(Password);
  //   let hasSpacialChar = /(.*[^a-zA-Z0-9].*)/.test(Password);
  const [ConfirmPassword, setconfirmpassword] = useState("");
  const { email } = useParams();
  const navigate = useNavigate();
  const handleUpdatePassword = async () => {
    try {
      // Validate password and confirmPassword here if needed

      const response = await fetch(
        `${Config.API_URL}/update-passwordInternal`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            password: Password,
            confirmPassword: ConfirmPassword,
          }),
        }
      );

      const data = await response.json();

      if (data.success) {
        // Password updated successfully, you can handle this case
        toast.success("Password updated successfully");
        navigate("/internal-signin", { replace: true });
      } else {
        // Handle failed password update, you can display an error message
        // toast.error("Failed to update password:", data.error);
        toast.error(`Failed to update password: ${data.error}`);
      }
    } catch (error) {
      //   toast.error("Error updating password:", error);
      toast.error(`Error updating password: ${error.message}`);
    }
  };

  const isFormValid =
    Password &&
    ConfirmPassword &&
    Password === ConfirmPassword &&
    validations.hasSixChar &&
    validations.hasLowerChar &&
    validations.hasUpperChar &&
    validations.hasNumber &&
    validations.hasSpecialChar;

  return (
    <div className="imgfull">
      <div className="container1 has-text-centered">
        <div className="logo-card">
          <figure className="image is-64x64">
            <img src={logo} alt="Logo" />
          </figure>
        </div>
        <div className="form-container px-2">
          <h1 className="title has-text-white">Update Password</h1>
          <div className="field ">
            <label className="label has-text-left has-text-white">
              Password
            </label>
            <div className="control has-icons-left has-icons-right my-input">
              <div className="colomn">
                <input
                  className="input is-rounded "
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={Password}
                  onChange={handlePasswordChange}
                />

                <span className="icon is-small is-left">
                  <i className="fas fa-key"></i>
                </span>
                <span
                  className="icon is-small is-right"
                  onClick={togglePasswordVisibility}
                  style={{ cursor: "pointer", "pointer-events": "all" }}
                >
                  <i
                    className={`fas fa-eye${showPassword ? "-slash" : ""}`}
                  ></i>
                </span>
              </div>
            </div>
          </div>

          {/* password validation */}

          {Password && <PasswordValidation {...validations} />}

          {/* {Password && (
            <div style={{ columns: 1 }} className="mr-5">
              <div className="has-text-left">
                <div>
                  {hasSixChar ? (
                    <span className="has-text-success">
                      <i className="fas fa-check-circle mr-1"></i>
                      <small>at least 6 characters</small>
                    </span>
                  ) : (
                    <span className="has-text-danger">
                      <i className="fas fa-times-circle mr-1"></i>
                      <small>at least 6 characters</small>
                    </span>
                  )}
                </div>
              </div>

              <div className="has-text-left">
                {hasLowerChar ? (
                  <span className="has-text-success">
                    <i className="fas fa-check-circle mr-1"></i>
                    <small>one lower case</small>
                  </span>
                ) : (
                  <span className="has-text-danger">
                    <i className="fas fa-times-circle mr-1"></i>
                    <small>one lower case</small>
                  </span>
                )}
              </div>

              <div className="has-text-left">
                {hasUpperChar ? (
                  <span className="has-text-success">
                    <i className="fas fa-check-circle mr-1"></i>
                    <small>one Upper case</small>
                  </span>
                ) : (
                  <span className="has-text-danger">
                    <i className="fas fa-times-circle mr-1"></i>
                    <small>one Upper case</small>
                  </span>
                )}
              </div>

              <div className="has-text-left">
                {hasNumber ? (
                  <span className="has-text-success">
                    <i className="fas fa-check-circle mr-1"></i>
                    <small>one number</small>
                  </span>
                ) : (
                  <span className="has-text-danger">
                    <i className="fas fa-times-circle mr-1"></i>
                    <small>one number</small>
                  </span>
                )}
              </div>
              <div className="has-text-left">
                {hasSpacialChar ? (
                  <span className="has-text-success">
                    <i className="fas fa-check-circle mr-1"></i>
                    <small>one spacial case</small>
                  </span>
                ) : (
                  <span className="has-text-danger">
                    <i className="fas fa-times-circle mr-1"></i>
                    <small>one spacial case</small>
                  </span>
                )}
              </div>
            </div>
          )} */}

          <div className="field mt-2 ">
            <label
              className="label has-text-left  has-text-white "
              style={{ marginRight: "200px" }}
            >
              Confirm Password
            </label>
            <div className="control has-icons-left has-icons-right ">
              <div className="colomn">
                <input
                  className="input is-rounded "
                  // type="password"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  value={ConfirmPassword}
                  onChange={(e) => setconfirmpassword(e.target.value)}
                />
                <span
                  className="icon is-small is-right"
                  onClick={toggleConfirmPasswordVisibility}
                  style={{ cursor: "pointer", "pointer-events": "all" }}
                >
                  <i
                    className={`fas fa-eye${
                      showConfirmPassword ? "-slash" : ""
                    }`}
                  ></i>
                </span>
                {Password && ConfirmPassword && (
                  <div className="has-text-left">
                    <small>
                      {Password === ConfirmPassword ? (
                        <span className="has-text-success icon is-small is-left mt-1">
                          <i className="fas fa-check-circle"></i>
                        </span>
                      ) : (
                        <span className="has-text-danger icon is-small is-right mr-5 mt-1">
                          <i className="fas fa-times-circle"></i>
                        </span>
                      )}
                    </small>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="field">
            <div className="control ">
              <button
                className="button is-primary is-rounded  is-focused is-medium is-fullwidth "
                // disabled={
                //   !Password || !ConfirmPassword || Password !== ConfirmPassword
                // }
                disabled={!isFormValid}
                onClick={handleUpdatePassword}
              >
                Back to SignIn
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePasswordInternal;
