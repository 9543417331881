import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CampaignLive from "./CampaignLive";
import { getAllCampaigns } from "../components/campaignApi";
import BrandPage from "./BrandForm";
import AdminBrandPage from "../pages/AdminBrandPage";
import { sortByIdDescending } from "../utils/sortByIdDescending";

const ITEMS_PER_PAGE = 10;
const PAGES_DISPLAYED = 5;

const AllCampaigns = ({ userRole }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("all");
  const [loading, setLoading] = useState(true); // Loading state
  const [showBrandPage, setShowBrandPage] = useState(false);

  useEffect(() => {
    // console.log("useEffect triggered");
    const fetchCampaigns = async () => {
      // console.log("Fetching campaigns..."); // Debug log before API call
      try {
        const allCampaigns = await getAllCampaigns();
        // console.log("All Campaigns:", allCampaigns);
        const sortedCampaigns = sortByIdDescending(allCampaigns); // Use the utility function

        // Directly set campaigns without filtering
        setCampaigns(sortedCampaigns);
        setLoading(false); // Data loaded, update loading state
      } catch (error) {
        console.error("Error fetching campaigns:", error);
        setLoading(false); // Error occurred, update loading state
      }
    };

    fetchCampaigns();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleFilterChange = (filterValue) => {
    setFilter(filterValue);
    setCurrentPage(1); // Reset page when filter changes
  };

  const filteredCampaigns = campaigns.filter((campaign) => {
    if (filter === "all") return true;
    if (filter === "approved") return campaign.approve;
    if (filter === "pending") return !campaign.approve && !campaign.rejected;
    if (filter === "rejected") return campaign.rejected;
    return true;
  });

  const totalPages = Math.ceil(filteredCampaigns.length / ITEMS_PER_PAGE);

  const startPage = Math.max(
    1,
    Math.min(
      currentPage - Math.floor(PAGES_DISPLAYED / 2),
      totalPages - PAGES_DISPLAYED + 1
    )
  );
  const endPage = Math.min(totalPages, startPage + PAGES_DISPLAYED - 1);

  // Slice the campaigns array to display only the relevant portion for the current page
  const paginatedCampaigns = filteredCampaigns.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const renderCampaigns = () => {
    if (filteredCampaigns.length === 0) {
      if (filter === "all") {
        return (
          <div className="columns is-ancestor">
            {showBrandPage ? (
              <div
                className="is-child has-text-centered mb-5"
                style={{ height: "auto", width: "50vw", marginLeft: "250px" }}
              >
                <AdminBrandPage />
              </div>
            ) : (
              <div
                className="is-child has-text-centered"
                style={{ width: "80vw", marginTop: "15vw" }}
              >
                <h1 className="subtitle mt-5"> Create New Campaign</h1>
                <button
                  className="button is-large is-rounded is-primary"
                  onClick={() => setShowBrandPage(true)}
                >
                  {" "}
                  Create New Campaign
                </button>
              </div>
            )}
          </div>
        );
      } else {
        return (
          // <div>No {filter} campaigns available.</div>
          <>
            <div
              className="filter-buttons"
              style={{
                display: "flex",
                justifyContent: "space-around",
                border: "1px solid #4a4a4a",
                padding: "7px",
                borderRadius: "8px",
              }}
            >
              {["all", "approved", "pending", "rejected"].map((value) => (
                <button
                  key={value}
                  className={filter === value ? "active" : ""}
                  style={{
                    cursor: "pointer",
                    border: "none",
                    background: "none",
                    color: "#4a4a4a",
                    fontWeight: filter === value ? "bold" : "normal",
                    transition: "color 0.3s",
                  }}
                  onClick={() => handleFilterChange(value)}
                >
                  {value.charAt(0).toUpperCase() + value.slice(1)}
                </button>
              ))}
            </div>
            {loading ? (
              <div className="loading has-text-centered mt-6">Loading...</div>
            ) : (
              <div className="campaigns-container">
                {/* {paginatedCampaigns.length === 0 && ( */}
                <div
                  className="is-child has-text-centered"
                  style={{ width: "80vw", marginTop: "15vw" }}
                >
                  <p>No {filter} campaigns available.</p>
                  {/* <h1 className="subtitle mt-5"> Create New Campaign</h1> */}
                  {campaigns.length === 0 && userRole !== "brand-staff" && (
                    <button
                      className="button is-large is-rounded is-primary mt-5"
                      onClick={() => setShowBrandPage(true)}
                    >
                      {" "}
                      Create New Campaign
                    </button>
                  )}
                </div>
                {/* ) : (
                  paginatedCampaigns.map((campaign, index) => (
                    <div key={index} className="campaign-item is-half">
                      <CampaignLive
                        campaign={campaign}
                        alignLeft={index % 2 === 0}
                      />
                    </div>
                  ))
                )} */}
              </div>
            )}
          </>
        );
      }
    } else {
      return (
        <>
          <div
            className="filter-buttons"
            style={{
              display: "flex",
              justifyContent: "space-around",
              border: "1px solid #4a4a4a",
              padding: "7px",
              borderRadius: "8px",
            }}
          >
            {["all", "approved", "pending", "rejected"].map((value) => (
              <button
                key={value}
                className={filter === value ? "active" : ""}
                style={{
                  cursor: "pointer",
                  border: "none",
                  background: "none",
                  color: "#4a4a4a",
                  fontWeight: filter === value ? "bold" : "normal",
                  transition: "color 0.3s",
                }}
                onClick={() => handleFilterChange(value)}
              >
                {value.charAt(0).toUpperCase() + value.slice(1)}
              </button>
            ))}
          </div>
          {loading ? (
            <div className="loading has-text-centered mt-6">Loading...</div>
          ) : (
            <div className="campaigns-container">
              {paginatedCampaigns.map((campaign, index) => (
                <div key={index} className="campaign-item is-half">
                  <CampaignLive
                    campaign={campaign}
                    alignLeft={index % 2 === 0}
                  />
                </div>
              ))}
              {/* {paginatedCampaigns.length === 0 ? (
                <div
                  className="is-child has-text-centered"
                  style={{ width: "80vw", marginTop: "15vw" }}
                >
                  <p>No {filter} campaigns available.</p>
                  <h1 className="subtitle mt-5"> Create New Campaign</h1>
                  {campaigns.length === 0 && userRole !== "staff" && (
                    <button
                      className="button is-large is-rounded is-primary mt-5"
                      onClick={() => setShowBrandPage(true)}
                    >
                      {" "}
                      Create New Campaign
                    </button>
                  )}
                </div>
              ) 
              : (
                paginatedCampaigns.map((campaign, index) => (
                  <div key={index} className="campaign-item is-half">
                    <CampaignLive
                      campaign={campaign}
                      alignLeft={index % 2 === 0}
                    />
                  </div>
                ))
              )} */}
            </div>
          )}

          {paginatedCampaigns.length > 0 && (
            <div
              className="pagination-container"
              role="navigation"
              aria-label="pagination"
              style={
                {
                  // display: "flex",
                  // flexDirection: "row",
                  // justifyContent: "center",
                  // marginLeft: "30px",
                }
              }
            >
              {currentPage > 1 && (
                <button
                  className="pagination-previous"
                  style={{ cursor: "pointer" }}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Previous
                </button>
              )}

              {Array.from(
                { length: endPage - startPage + 1 },
                (_, i) => startPage + i
              ).map((page) => (
                <button
                  key={page}
                  className={`pagination-link ${
                    page === currentPage ? "is-current" : ""
                  }`}
                  aria-label={`Goto page ${page}`}
                  style={{ cursor: "pointer" }}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              ))}

              {currentPage < totalPages && (
                <button
                  className="pagination-next"
                  style={{ cursor: "pointer" }}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Next
                </button>
              )}
            </div>
          )}
        </>
      );
    }
  };

  // const renderCampaigns = () => {
  //   if (filteredCampaigns.length === 0) {
  //     if (filter === "all") {
  //       return (
  //         <div className="columns is-ancestor">
  //           {showBrandPage ? (
  //             <div
  //               className="is-child has-text-centered"
  //               style={{ height: "100vh", width: "50vw", marginLeft: "250px" }}
  //             >
  //               <BrandPage />
  //             </div>
  //           ) : (
  //             <div
  //               className="is-child has-text-centered"
  //               style={{ width: "80vw", marginTop: "15vw" }}
  //             >
  //               <h1 className="subtitle mt-5"> Create New Campaign</h1>
  //               <button
  //                 className="button is-large is-rounded is-primary"
  //                 onClick={() => setShowBrandPage(true)}
  //               >
  //                 Create New Campaign
  //               </button>
  //             </div>
  //           )}
  //         </div>
  //       );
  //     } else {
  //       return (
  //         <div className="campaigns-container">
  //           <div>No {filter} campaigns available.</div>
  //         </div>
  //       );
  //     }
  //   } else {
  //     return (
  //       <>
  //         <div className="dual-campaign-container">
  //           <div
  //             className="filter-buttons"
  //             style={{
  //               display: "flex",
  //               justifyContent: "space-around",
  //               border: "1px solid #4a4a4a",
  //               padding: "7px",
  //               borderRadius: "8px",
  //             }}
  //           >
  //             {["all", "approved", "pending", "rejected"].map((value) => (
  //               <button
  //                 key={value}
  //                 className={filter === value ? "active" : ""}
  //                 style={{
  //                   cursor: "pointer",
  //                   border: "none",
  //                   background: "none",
  //                   color: "#4a4a4a",
  //                   fontWeight: filter === value ? "bold" : "normal",
  //                   transition: "color 0.3s",
  //                 }}
  //                 onClick={() => handleFilterChange(value)}
  //               >
  //                 {value.charAt(0).toUpperCase() + value.slice(1)}
  //               </button>
  //             ))}
  //           </div>

  //           <div className="campaigns-container">
  //             {filteredCampaigns
  //               .slice(
  //                 (currentPage - 1) * ITEMS_PER_PAGE,
  //                 currentPage * ITEMS_PER_PAGE
  //               )
  //               .map((campaign, index) => (
  //                 <div key={index} className="campaign-item is-half ml-2">
  //                   <CampaignLive
  //                     key={index}
  //                     campaign={campaign}
  //                     alignLeft={index % 2 === 0}
  //                   />
  //                 </div>
  //               ))}
  //           </div>
  //           <div
  //             className="pagination-container"
  //             role="navigation"
  //             aria-label="pagination"
  //           >
  //             {currentPage > 1 && (
  //               <button
  //                 className="pagination-previous"
  //                 style={{ cursor: "pointer" }}
  //                 onClick={() => handlePageChange(currentPage - 1)}
  //               >
  //                 Previous
  //               </button>
  //             )}
  //             {Array.from(
  //               { length: endPage - startPage + 1 },
  //               (_, i) => startPage + i
  //             ).map((page) => (
  //               <button
  //                 key={page}
  //                 style={{ cursor: "pointer" }}
  //                 className={`pagination-link ${
  //                   page === currentPage ? "is-current" : ""
  //                 }`}
  //                 aria-label={`Goto page ${page}`}
  //                 onClick={() => handlePageChange(page)}
  //               >
  //                 {page}
  //               </button>
  //             ))}
  //             {currentPage < totalPages && (
  //               <button
  //                 className="pagination-next"
  //                 style={{ cursor: "pointer" }}
  //                 onClick={() => handlePageChange(currentPage + 1)}
  //               >
  //                 Next
  //               </button>
  //             )}
  //           </div>
  //         </div>
  //       </>
  //     );
  //   }
  // };

  return (
    <div className="dual-campaign-container">
      {loading ? <div>Loading...</div> : renderCampaigns()}
    </div>

    // <div className="dual-campaign-container">
    //   <div
    //     className="filter-buttons"
    //     style={{
    //       display: "flex",
    //       justifyContent: "space-around",
    //       border: "1px solid #4a4a4a",
    //       padding: "7px",
    //       borderRadius: "8px",
    //     }}
    //   >
    //     {["all", "approved", "pending", "rejected"].map((value) => (
    //       <button
    //         key={value}
    //         className={filter === value ? "active" : ""}
    //         style={{
    //           cursor: "pointer",
    //           border: "none",
    //           background: "none",
    //           color: "#4a4a4a",
    //           fontWeight: filter === value ? "bold" : "normal",
    //           transition: "color 0.3s",
    //         }}
    //         onClick={() => handleFilterChange(value)}
    //       >
    //         {value.charAt(0).toUpperCase() + value.slice(1)}
    //       </button>
    //     ))}
    //   </div>

    //   {loading ? (
    //     <div className="loading has-text-centered mt-6">Loading...</div>
    //   ) : (
    //     <div className="campaigns-container">
    //       {paginatedCampaigns.length === 0 ? (
    //         <div
    //           className="is-child has-text-centered"
    //           style={{ width: "80vw", marginTop: "15vw" }}
    //         >
    //           <p>No {filter} campaigns available.</p>
    //           {/* <h1 className="subtitle mt-5"> Create New Campaign</h1> */}
    //           {campaigns.length === 0 && userRole !== "staff" && (
    //             <button
    //               className="button is-large is-rounded is-primary mt-5"
    //               onClick={() => setShowBrandPage(true)}
    //             >
    //               {" "}
    //               Create New Campaign
    //             </button>
    //           )}
    //         </div>
    //       ) : (
    //         paginatedCampaigns.map((campaign, index) => (
    //           <div key={index} className="campaign-item is-half">
    //             <CampaignLive campaign={campaign} alignLeft={index % 2 === 0} />
    //           </div>
    //         ))
    //       )}
    //     </div>
    //   )}

    //   {paginatedCampaigns.length > 0 && (
    //     <div
    //       className="pagination-container"
    //       role="navigation"
    //       aria-label="pagination"
    //       style={{
    //         // display: "flex",
    //         // flexDirection: "row",
    //         // justifyContent: "center",
    //         marginLeft: "30px",
    //       }}
    //     >
    //       {currentPage > 1 && (
    //         <button
    //           className="pagination-previous"
    //           style={{ cursor: "pointer" }}
    //           onClick={() => handlePageChange(currentPage - 1)}
    //         >
    //           Previous
    //         </button>
    //       )}

    //       {Array.from(
    //         { length: endPage - startPage + 1 },
    //         (_, i) => startPage + i
    //       ).map((page) => (
    //         <button
    //           key={page}
    //           className={`pagination-link ${
    //             page === currentPage ? "is-current" : ""
    //           }`}
    //           aria-label={`Goto page ${page}`}
    //           style={{ cursor: "pointer" }}
    //           onClick={() => handlePageChange(page)}
    //         >
    //           {page}
    //         </button>
    //       ))}

    //       {currentPage < totalPages && (
    //         <button
    //           className="pagination-next"
    //           style={{ cursor: "pointer" }}
    //           onClick={() => handlePageChange(currentPage + 1)}
    //         >
    //           Next
    //         </button>
    //       )}
    //     </div>
    //   )}
    // </div>
  );
};

export default AllCampaigns;
