import React, { useState } from "react";
import { useParams } from "react-router-dom";
import InfluencerTableLead from "./influencerTableLead";
import InfluencerDeliverablesTableLead from "./infDeliverablesLead";
import InfluencerConformationTableLead from "./infConfTableLead";
import InfluencerTableAdmin from "./InfluencerTableAdmin";

const ViewMoreLead = ({ campaign }) => {
  // const [showInfluencerTable, setShowInfluencerTable] = useState(false);
  // const [showInfluencerDeliverables, setShowInfluencerDeliverables] = useState(false);
  // const [ShowInfluencerConformationTable, setShowInfluencerConformationTable] = useState(false);
  // const [brandName, setBrandName] = useState("");

  // const handleInfluencerApplicationsClick = () => {
  //     setShowInfluencerTable(true);
  //     setShowInfluencerDeliverables(false);
  //     setShowInfluencerConformationTable(false);
  //     // setBrandName(brandName);
  // };

  // const handleInfluencerDeliverablesTableAdmin = () => {
  //     setShowInfluencerDeliverables(true);
  //     setShowInfluencerTable(false);
  //     setShowInfluencerConformationTable(false);
  // };
  // const handleInfluencerConformationTable = () => {
  //     setShowInfluencerConformationTable(true);
  //     setShowInfluencerDeliverables(false);
  //     setShowInfluencerTable(false);
  // }
  // // useEffect(() => {
  // //     if (campaign) {
  // //         setBrandName(campaign.brandName);
  // //     }
  // // }, [campaign]);
  // const {brandName} = campaign;
  const { brandName } = useParams();
  // console.log("brand" , brandName);
  return (
    <div className="container has-text-centered mt-3">
      <div style={{ position: "relative", overflowY: "auto" }}>
        <div>
          <InfluencerTableLead campaign={campaign} brandName={brandName} />
          {/* <InfluencerTableAdmin campaign={campaign} brandName={brandName}/> */}
        </div>

        {/* <button className="mr-2" onClick={handleInfluencerDeliverablesTableAdmin} campaign={campaign} brandName={brandName}>Influencer Deliverables</button> */}
        <div style={{ position: "absolute", top: "4.7rem", left: "119rem" }}>
          <InfluencerDeliverablesTableLead
            campaign={campaign}
            brandName={brandName}
          />
        </div>
        <div style={{ position: "absolute", top: "4.7rem", left: "153rem" }}>
          <InfluencerConformationTableLead
            campaign={campaign}
            brandName={brandName}
          />
        </div>
      </div>
    </div>
  );
};
export default ViewMoreLead;
