// api.js
// import fetch from "node-fetch";
import { Config } from "../.env.js";
// Define the URL of your backend API endpoint
const API_URL = `${Config.API_URL}/submit`; // Update this with your actual API URL

// Function to fetch brand campaigns from the backend
export const getInfluencerCampaigns = async () => {
  try {
    // Make a GET request to the backend API endpoint that returns brand campaigns
    const response = await fetch(`${API_URL}/influencer-campaigns`);
    // console.log("Raw response:", response);
    // Check if the response is successful (status code 200)
    if (response.ok) {
      // If successful, return the response
      return response;
      //   console.log(response);
      //   const data = await response.json();
      //   console.log("Parsed data:", data);
      //   return data;
    } else {
      // If not successful, throw an error with the response status text
      //   throw new Error(response.statusText);
      console.error("API error:", response.status, response.statusText);
      throw new Error(
        `API returned an error: ${response.statusText} (${response.status})`
      );
    }
  } catch (error) {
    // If an error occurs during the fetch operation, throw an error with the error message
    console.error("Error in getInfluencerCampaigns:", error.message);
    throw new Error(`Failed to fetch influencer campaigns: ${error.message}`);
  }
};
export const approveCampaignExecutive = async (campaignId) => {
  try {
    // Make a POST request to the backend API endpoint to approve the campaign
    const response = await fetch(
      `${API_URL}/influencer-campaigns/${campaignId}/approvedexecutive`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // Check if the response is successful (status code 200)
    if (response.ok) {
      // If successful, return the response
      return response;
    } else {
      // If not successful, throw an error with the response status text
      throw new Error(response.statusText);
    }
  } catch (error) {
    // If an error occurs during the fetch operation, throw an error with the error message
    throw new Error(`Failed to approve campaign: ${error.message}`);
  }
};

export const approveCampaignLead = async (campaignId) => {
  try {
    // Make a POST request to the backend API endpoint to approve the campaign
    const response = await fetch(
      `${API_URL}/influencer-campaigns/${campaignId}/approvedlead`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // Check if the response is successful (status code 200)
    if (response.ok) {
      // If successful, return the response
      return response;
    } else {
      // If not successful, throw an error with the response status text
      throw new Error(response.statusText);
    }
  } catch (error) {
    // If an error occurs during the fetch operation, throw an error with the error message
    throw new Error(`Failed to approve campaign: ${error.message}`);
  }
};

export const approveCampaignAdmin = async (campaignId) => {
  try {
    // Make a POST request to the backend API endpoint to approve the campaign
    const response = await fetch(
      `${API_URL}/influencer-campaigns/${campaignId}/approvedadmin`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // Check if the response is successful (status code 200)
    if (response.ok) {
      // If successful, return the response
      return response;
    } else {
      // If not successful, throw an error with the response status text
      throw new Error(response.statusText);
    }
  } catch (error) {
    // If an error occurs during the fetch operation, throw an error with the error message
    throw new Error(`Failed to approve campaign: ${error.message}`);
  }
};

export const approveCampaignBrand = async (campaignId) => {
  try {
    // Make a POST request to the backend API endpoint to approve the campaign
    const response = await fetch(
      `${API_URL}/influencer-campaigns/${campaignId}/approvedbrand`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // Check if the response is successful (status code 200)
    if (response.ok) {
      // If successful, return the response
      return response;
    } else {
      // If not successful, throw an error with the response status text
      throw new Error(response.statusText);
    }
  } catch (error) {
    // If an error occurs during the fetch operation, throw an error with the error message
    throw new Error(`Failed to approve campaign: ${error.message}`);
  }
};

export const rejectCampaignExecutive = async (campaignId, remarks) => {
  try {
    // Make a POST request to the backend endpoint
    const response = await fetch(
      `${Config.API_URL}/reject-campaign-executive`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          campaignId: campaignId,
          remarks: remarks,
        }),
      }
    );
    // console.log("Reject Response:", response);
    // Check if the response is successful
    if (!response.ok) {
      // If response is not OK, throw an error with the status text
      throw new Error(`Failed to reject campaign: ${response.statusText}`);
    }

    // Extract and return the response data
    // return await response.json();
    return response;
  } catch (error) {
    // Handle any errors that occur during the request
    throw new Error(`Failed to reject campaign: ${error.message}`);
  }
};

export const rejectCampaignLead = async (campaignId, remarks) => {
  try {
    // Make a POST request to the backend endpoint
    const response = await fetch(`${Config.API_URL}/reject-campaign-lead`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campaignId: campaignId,
        remarks: remarks,
      }),
    });
    // console.log("Reject Response:", response);
    // Check if the response is successful
    if (!response.ok) {
      // If response is not OK, throw an error with the status text
      throw new Error(`Failed to reject campaign: ${response.statusText}`);
    }

    // Extract and return the response data
    // return await response.json();
    return response;
  } catch (error) {
    // Handle any errors that occur during the request
    throw new Error(`Failed to reject campaign: ${error.message}`);
  }
};

export const rejectCampaignAdmin = async (campaignId, remarks) => {
  try {
    // Make a POST request to the backend endpoint
    const response = await fetch(`${Config.API_URL}/reject-campaign-admin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campaignId: campaignId,
        remarks: remarks,
      }),
    });
    // console.log("Reject Response:", response);
    // Check if the response is successful
    if (!response.ok) {
      // If response is not OK, throw an error with the status text
      throw new Error(`Failed to reject campaign: ${response.statusText}`);
    }

    // Extract and return the response data
    // return await response.json();
    return response;
  } catch (error) {
    // Handle any errors that occur during the request
    throw new Error(`Failed to reject campaign: ${error.message}`);
  }
};

export const rejectCampaignBrand = async (campaignId, remarks) => {
  try {
    // Make a POST request to the backend endpoint
    const response = await fetch(`${Config.API_URL}/reject-campaign-brand`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campaignId: campaignId,
        remarks: remarks,
      }),
    });
    // console.log("Reject Response:", response);
    // Check if the response is successful
    if (!response.ok) {
      // If response is not OK, throw an error with the status text
      throw new Error(`Failed to reject campaign: ${response.statusText}`);
    }

    // Extract and return the response data
    // return await response.json();
    return response;
  } catch (error) {
    // Handle any errors that occur during the request
    throw new Error(`Failed to reject campaign: ${error.message}`);
  }
};

//On Hold

export const onHoldCampaignExecutive = async (campaignId) => {
  try {
    // Make a POST request to the backend API endpoint to approve the campaign
    const response = await fetch(`${Config.API_URL}/onHoldCampaignExecutive`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campaignId: campaignId,
      }),
    });

    // console.log("Reject Response:", response);
    // Check if the response is successful
    if (!response.ok) {
      // If response is not OK, throw an error with the status text
      throw new Error(`Failed to update campaign: ${response.statusText}`);
    }

    // Extract and return the response data
    // return await response.json();
    return response;
  } catch (error) {
    // If an error occurs during the fetch operation, throw an error with the error message
    throw new Error(`Failed to update campaign: ${error.message}`);
  }
};

export const onHoldCampaignLead = async (campaignId) => {
  try {
    // Make a POST request to the backend API endpoint to approve the campaign
    const response = await fetch(`${Config.API_URL}/onHoldCampaignLead`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campaignId: campaignId,
      }),
    });

    // Check if the response is successful (status code 200)
    if (response.ok) {
      // If successful, return the response
      return response;
    } else {
      // If not successful, throw an error with the response status text
      throw new Error(response.statusText);
    }
  } catch (error) {
    // If an error occurs during the fetch operation, throw an error with the error message
    throw new Error(`Failed to approve campaign: ${error.message}`);
  }
};

export const onHoldCampaignAdmin = async (campaignId) => {
  try {
    // Make a POST request to the backend API endpoint to approve the campaign
    const response = await fetch(`${Config.API_URL}/onHoldCampaignAdmin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campaignId: campaignId,
      }),
    });

    // Check if the response is successful (status code 200)
    if (response.ok) {
      // If successful, return the response
      return response;
    } else {
      // If not successful, throw an error with the response status text
      throw new Error(response.statusText);
    }
  } catch (error) {
    // If an error occurs during the fetch operation, throw an error with the error message
    throw new Error(`Failed to approve campaign: ${error.message}`);
  }
};

export const onHoldCampaignBrand = async (campaignId) => {
  try {
    // Make a POST request to the backend API endpoint to approve the campaign
    const response = await fetch(`${Config.API_URL}/onHoldCampaignBrand`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campaignId: campaignId,
      }),
    });

    // Check if the response is successful (status code 200)
    if (response.ok) {
      // If successful, return the response
      return response;
    } else {
      // If not successful, throw an error with the response status text
      throw new Error(response.statusText);
    }
  } catch (error) {
    // If an error occurs during the fetch operation, throw an error with the error message
    throw new Error(`Failed to approve campaign: ${error.message}`);
  }
};

// for InfDeliverables form create api

export const approvedExecutive = async (influencerId) => {
  try {
    // Make a POST request to the backend API endpoint to approve the campaign
    const response = await fetch(
      `${API_URL}/influencer-campaigns/${influencerId}/approvedbyexecutive`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // Check if the response is successful (status code 200)
    if (response.ok) {
      // If successful, return the response
      return response;
    } else {
      // If not successful, throw an error with the response status text
      throw new Error(response.statusText);
    }
  } catch (error) {
    // If an error occurs during the fetch operation, throw an error with the error message
    throw new Error(`Failed to approve campaign: ${error.message}`);
  }
};

export const approvedLead = async (influencerId) => {
  try {
    // Make a POST request to the backend API endpoint to approve the campaign
    const response = await fetch(
      `${API_URL}/influencer-campaigns/${influencerId}/approvedbylead`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // Check if the response is successful (status code 200)
    if (response.ok) {
      // If successful, return the response
      return response;
    } else {
      // If not successful, throw an error with the response status text
      throw new Error(response.statusText);
    }
  } catch (error) {
    // If an error occurs during the fetch operation, throw an error with the error message
    throw new Error(`Failed to approve campaign: ${error.message}`);
  }
};

export const approvedAdmin = async (influencerId) => {
  try {
    // Make a POST request to the backend API endpoint to approve the campaign
    const response = await fetch(
      `${API_URL}/influencer-campaigns/${influencerId}/approvedbyadmin`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // Check if the response is successful (status code 200)
    if (response.ok) {
      // If successful, return the response
      return response;
    } else {
      // If not successful, throw an error with the response status text
      throw new Error(response.statusText);
    }
  } catch (error) {
    // If an error occurs during the fetch operation, throw an error with the error message
    throw new Error(`Failed to approve campaign: ${error.message}`);
  }
};

export const approvedBrand = async (influencerId) => {
  try {
    // Make a POST request to the backend API endpoint to approve the campaign
    const response = await fetch(
      `${API_URL}/influencer-campaigns/${influencerId}/approvedbybrand`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // Check if the response is successful (status code 200)
    if (response.ok) {
      // If successful, return the response
      return response;
    } else {
      // If not successful, throw an error with the response status text
      throw new Error(response.statusText);
    }
  } catch (error) {
    // If an error occurs during the fetch operation, throw an error with the error message
    throw new Error(`Failed to approve campaign: ${error.message}`);
  }
};

export const rejectDeliverables = async (campaignId, remarks) => {
  try {
    // Make a POST request to the backend endpoint
    const response = await fetch(`${Config.API_URL}/reject-deliverables`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campaignId: campaignId,
        remarks: remarks,
      }),
    });
    // console.log("Reject Response:", response);
    // Check if the response is successful
    if (!response.ok) {
      // If response is not OK, throw an error with the status text
      throw new Error(`Failed to reject deliverables: ${response.statusText}`);
    }

    // Extract and return the response data
    // return await response.json();
    return response;
  } catch (error) {
    // Handle any errors that occur during the request
    throw new Error(`Failed to reject deliverables: ${error.message}`);
  }
};

//Reject

export const rejectDeliverableExecutive = async (campaignId, remarks) => {
  try {
    // Make a POST request to the backend endpoint
    const response = await fetch(
      `${Config.API_URL}/reject-deliverable-executive`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          campaignId: campaignId,
          remarks: remarks,
        }),
      }
    );
    // console.log("Reject Response:", response);
    // Check if the response is successful
    if (!response.ok) {
      // If response is not OK, throw an error with the status text
      throw new Error(`Failed to reject campaign: ${response.statusText}`);
    }

    // Extract and return the response data
    // return await response.json();
    return response;
  } catch (error) {
    // Handle any errors that occur during the request
    throw new Error(`Failed to reject campaign: ${error.message}`);
  }
};

export const rejectDeliverableLead = async (campaignId, remarks) => {
  try {
    // Make a POST request to the backend endpoint
    const response = await fetch(`${Config.API_URL}/reject-deliverable-lead`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campaignId: campaignId,
        remarks: remarks,
      }),
    });
    // console.log("Reject Response:", response);
    // Check if the response is successful
    if (!response.ok) {
      // If response is not OK, throw an error with the status text
      throw new Error(`Failed to reject campaign: ${response.statusText}`);
    }

    // Extract and return the response data
    // return await response.json();
    return response;
  } catch (error) {
    // Handle any errors that occur during the request
    throw new Error(`Failed to reject campaign: ${error.message}`);
  }
};

export const rejectDeliverableAdmin = async (campaignId, remarks) => {
  try {
    // Make a POST request to the backend endpoint
    const response = await fetch(`${Config.API_URL}/reject-deliverable-admin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campaignId: campaignId,
        remarks: remarks,
      }),
    });
    // console.log("Reject Response:", response);
    // Check if the response is successful
    if (!response.ok) {
      // If response is not OK, throw an error with the status text
      throw new Error(`Failed to reject campaign: ${response.statusText}`);
    }

    // Extract and return the response data
    // return await response.json();
    return response;
  } catch (error) {
    // Handle any errors that occur during the request
    throw new Error(`Failed to reject campaign: ${error.message}`);
  }
};

export const rejectDeliverableBrand = async (campaignId, remarks) => {
  try {
    // Make a POST request to the backend endpoint
    const response = await fetch(`${Config.API_URL}/reject-deliverable-brand`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campaignId: campaignId,
        remarks: remarks,
      }),
    });
    // console.log("Reject Response:", response);
    // Check if the response is successful
    if (!response.ok) {
      // If response is not OK, throw an error with the status text
      throw new Error(`Failed to reject campaign: ${response.statusText}`);
    }

    // Extract and return the response data
    // return await response.json();
    return response;
  } catch (error) {
    // Handle any errors that occur during the request
    throw new Error(`Failed to reject campaign: ${error.message}`);
  }
};

//On Hold

export const onHoldDeliverableExecutive = async (campaignId) => {
  try {
    // Make a POST request to the backend API endpoint to approve the campaign
    const response = await fetch(
      `${Config.API_URL}/onHoldDeliverableExecutive`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          campaignId: campaignId,
        }),
      }
    );

    // console.log("Reject Response:", response);
    // Check if the response is successful
    if (!response.ok) {
      // If response is not OK, throw an error with the status text
      throw new Error(`Failed to update campaign: ${response.statusText}`);
    }

    // Extract and return the response data
    // return await response.json();
    return response;
  } catch (error) {
    // If an error occurs during the fetch operation, throw an error with the error message
    throw new Error(`Failed to update campaign: ${error.message}`);
  }
};

export const onHoldDeliverableLead = async (campaignId) => {
  try {
    // Make a POST request to the backend API endpoint to approve the campaign
    const response = await fetch(`${Config.API_URL}/onHoldDeliverableLead`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campaignId: campaignId,
      }),
    });

    // Check if the response is successful (status code 200)
    if (response.ok) {
      // If successful, return the response
      return response;
    } else {
      // If not successful, throw an error with the response status text
      throw new Error(response.statusText);
    }
  } catch (error) {
    // If an error occurs during the fetch operation, throw an error with the error message
    throw new Error(`Failed to approve campaign: ${error.message}`);
  }
};

export const onHoldDeliverableAdmin = async (campaignId) => {
  try {
    // Make a POST request to the backend API endpoint to approve the campaign
    const response = await fetch(`${Config.API_URL}/onHoldDeliverableAdmin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campaignId: campaignId,
      }),
    });

    // Check if the response is successful (status code 200)
    if (response.ok) {
      // If successful, return the response
      return response;
    } else {
      // If not successful, throw an error with the response status text
      throw new Error(response.statusText);
    }
  } catch (error) {
    // If an error occurs during the fetch operation, throw an error with the error message
    throw new Error(`Failed to approve campaign: ${error.message}`);
  }
};

export const onHoldDeliverableBrand = async (campaignId) => {
  try {
    // Make a POST request to the backend API endpoint to approve the campaign
    const response = await fetch(`${Config.API_URL}/onHoldDeliverableBrand`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campaignId: campaignId,
      }),
    });

    // Check if the response is successful (status code 200)
    if (response.ok) {
      // If successful, return the response
      return response;
    } else {
      // If not successful, throw an error with the response status text
      throw new Error(response.statusText);
    }
  } catch (error) {
    // If an error occurs during the fetch operation, throw an error with the error message
    throw new Error(`Failed to approve campaign: ${error.message}`);
  }
};

//  for Influencer conformation page
export const approvelExecutive = async (influencerId) => {
  try {
    // Make a POST request to the backend API endpoint to approve the campaign
    const response = await fetch(
      `${API_URL}/influencer-campaigns/${influencerId}/approvelbyexecutive`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // Check if the response is successful (status code 200)
    if (response.ok) {
      // If successful, return the response
      return response;
    } else {
      // If not successful, throw an error with the response status text
      throw new Error(response.statusText);
    }
  } catch (error) {
    // If an error occurs during the fetch operation, throw an error with the error message
    throw new Error(`Failed to approve campaign: ${error.message}`);
  }
};

export const approvelLead = async (influencerId) => {
  try {
    // Make a POST request to the backend API endpoint to approve the campaign
    const response = await fetch(
      `${API_URL}/influencer-campaigns/${influencerId}/approvelbylead`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // Check if the response is successful (status code 200)
    if (response.ok) {
      // If successful, return the response
      return response;
    } else {
      // If not successful, throw an error with the response status text
      throw new Error(response.statusText);
    }
  } catch (error) {
    // If an error occurs during the fetch operation, throw an error with the error message
    throw new Error(`Failed to approve campaign: ${error.message}`);
  }
};

export const approvelAdmin = async (influencerId) => {
  try {
    // Make a POST request to the backend API endpoint to approve the campaign
    const response = await fetch(
      `${API_URL}/influencer-campaigns/${influencerId}/approvelbyadmin`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // Check if the response is successful (status code 200)
    if (response.ok) {
      // If successful, return the response
      return response;
    } else {
      // If not successful, throw an error with the response status text
      throw new Error(response.statusText);
    }
  } catch (error) {
    // If an error occurs during the fetch operation, throw an error with the error message
    throw new Error(`Failed to approve campaign: ${error.message}`);
  }
};

export const approvelBrand = async (influencerId) => {
  try {
    // Make a POST request to the backend API endpoint to approve the campaign
    const response = await fetch(
      `${API_URL}/influencer-campaigns/${influencerId}/approvelbybrand`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // Check if the response is successful (status code 200)
    if (response.ok) {
      // If successful, return the response
      return response;
    } else {
      // If not successful, throw an error with the response status text
      throw new Error(response.statusText);
    }
  } catch (error) {
    // If an error occurs during the fetch operation, throw an error with the error message
    throw new Error(`Failed to approve campaign: ${error.message}`);
  }
};

//Reject

export const rejectConfirmationExecutive = async (campaignId, remarks) => {
  try {
    // Make a POST request to the backend endpoint
    const response = await fetch(
      `${Config.API_URL}/reject-confirmation-executive`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          campaignId: campaignId,
          remarks: remarks,
        }),
      }
    );
    // console.log("Reject Response:", response);
    // Check if the response is successful
    if (!response.ok) {
      // If response is not OK, throw an error with the status text
      throw new Error(`Failed to reject campaign: ${response.statusText}`);
    }

    // Extract and return the response data
    // return await response.json();
    return response;
  } catch (error) {
    // Handle any errors that occur during the request
    throw new Error(`Failed to reject campaign: ${error.message}`);
  }
};

export const rejectConfirmationLead = async (campaignId, remarks) => {
  try {
    // Make a POST request to the backend endpoint
    const response = await fetch(`${Config.API_URL}/reject-confirmation-lead`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campaignId: campaignId,
        remarks: remarks,
      }),
    });
    // console.log("Reject Response:", response);
    // Check if the response is successful
    if (!response.ok) {
      // If response is not OK, throw an error with the status text
      throw new Error(`Failed to reject campaign: ${response.statusText}`);
    }

    // Extract and return the response data
    // return await response.json();
    return response;
  } catch (error) {
    // Handle any errors that occur during the request
    throw new Error(`Failed to reject campaign: ${error.message}`);
  }
};

export const rejectConfirmationAdmin = async (campaignId, remarks) => {
  try {
    // Make a POST request to the backend endpoint
    const response = await fetch(
      `${Config.API_URL}/reject-confirmation-admin`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          campaignId: campaignId,
          remarks: remarks,
        }),
      }
    );
    // console.log("Reject Response:", response);
    // Check if the response is successful
    if (!response.ok) {
      // If response is not OK, throw an error with the status text
      throw new Error(`Failed to reject campaign: ${response.statusText}`);
    }

    // Extract and return the response data
    // return await response.json();
    return response;
  } catch (error) {
    // Handle any errors that occur during the request
    throw new Error(`Failed to reject campaign: ${error.message}`);
  }
};

export const rejectConfirmationBrand = async (campaignId, remarks) => {
  try {
    // Make a POST request to the backend endpoint
    const response = await fetch(
      `${Config.API_URL}/reject-confirmation-brand`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          campaignId: campaignId,
          remarks: remarks,
        }),
      }
    );
    // console.log("Reject Response:", response);
    // Check if the response is successful
    if (!response.ok) {
      // If response is not OK, throw an error with the status text
      throw new Error(`Failed to reject campaign: ${response.statusText}`);
    }

    // Extract and return the response data
    // return await response.json();
    return response;
  } catch (error) {
    // Handle any errors that occur during the request
    throw new Error(`Failed to reject campaign: ${error.message}`);
  }
};

//On Hold

export const onHoldConfirmationExecutive = async (campaignId) => {
  try {
    // Make a POST request to the backend API endpoint to approve the campaign
    const response = await fetch(
      `${Config.API_URL}/onHoldConfirmationExecutive`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          campaignId: campaignId,
        }),
      }
    );

    // console.log("Reject Response:", response);
    // Check if the response is successful
    if (!response.ok) {
      // If response is not OK, throw an error with the status text
      throw new Error(`Failed to update campaign: ${response.statusText}`);
    }

    // Extract and return the response data
    // return await response.json();
    return response;
  } catch (error) {
    // If an error occurs during the fetch operation, throw an error with the error message
    throw new Error(`Failed to update campaign: ${error.message}`);
  }
};

export const onHoldConfirmationLead = async (campaignId) => {
  try {
    // Make a POST request to the backend API endpoint to approve the campaign
    const response = await fetch(`${Config.API_URL}/onHoldConfirmationLead`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campaignId: campaignId,
      }),
    });

    // console.log("Reject Response:", response);
    // Check if the response is successful
    if (!response.ok) {
      // If response is not OK, throw an error with the status text
      throw new Error(`Failed to update campaign: ${response.statusText}`);
    }

    // Extract and return the response data
    // return await response.json();
    return response;
  } catch (error) {
    // If an error occurs during the fetch operation, throw an error with the error message
    throw new Error(`Failed to approve campaign: ${error.message}`);
  }
};

export const onHoldConfirmationAdmin = async (campaignId) => {
  try {
    // Make a POST request to the backend API endpoint to approve the campaign
    const response = await fetch(`${Config.API_URL}/onHoldConfirmationAdmin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campaignId: campaignId,
      }),
    });

    // Check if the response is successful (status code 200)
    if (response.ok) {
      // If successful, return the response
      return response;
    } else {
      // If not successful, throw an error with the response status text
      throw new Error(response.statusText);
    }
  } catch (error) {
    // If an error occurs during the fetch operation, throw an error with the error message
    throw new Error(`Failed to approve campaign: ${error.message}`);
  }
};

export const onHoldConfirmationBrand = async (campaignId) => {
  try {
    // Make a POST request to the backend API endpoint to approve the campaign
    const response = await fetch(`${Config.API_URL}/onHoldConfirmationBrand`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campaignId: campaignId,
      }),
    });

    // Check if the response is successful (status code 200)
    if (response.ok) {
      // If successful, return the response
      return response;
    } else {
      // If not successful, throw an error with the response status text
      throw new Error(response.statusText);
    }
  } catch (error) {
    // If an error occurs during the fetch operation, throw an error with the error message
    throw new Error(`Failed to approve campaign: ${error.message}`);
  }
};

// for brand Application
export const rejectedCampaignBrand = async (campaignId, remarks) => {
  try {
    console.log("Remarks:", remarks);
    // Make a POST request to the backend endpoint
    const response = await fetch(`${Config.API_URL}/rejected-campaign`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campaignId: campaignId,
        remarks: remarks,
      }),
    });
    // console.log("Response:", response);
    // Check if the response is successful
    if (!response.ok) {
      // If response is not OK, throw an error with the status text
      throw new Error(`Failed to reject campaign: ${response.statusText}`);
    }

    // Extract and return the response data
    // return await response.json();
    return response;
  } catch (error) {
    // Handle any errors that occur during the request
    throw new Error(`Failed to reject campaign: ${error.message}`);
  }
};

export const approve = async (campaignId) => {
  try {
    // Make a POST request to the backend API endpoint to approve the campaign
    const response = await fetch(
      `${API_URL}/influencer-campaigns/${campaignId}/approve`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // Check if the response is successful (status code 200)
    if (response.ok) {
      // If successful, return the response
      return response;
    } else {
      // If not successful, throw an error with the response status text
      throw new Error(response.statusText);
    }
  } catch (error) {
    // If an error occurs during the fetch operation, throw an error with the error message
    throw new Error(`Failed to approve campaign: ${error.message}`);
  }
};

export const rejectConfirmation = async (campaignId, remarks) => {
  try {
    // Make a POST request to the backend endpoint
    const response = await fetch(`${Config.API_URL}/reject-confirmation`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campaignId: campaignId,
        remarks: remarks,
      }),
    });
    // console.log("Reject Response:", response);
    // Check if the response is successful
    if (!response.ok) {
      // If response is not OK, throw an error with the status text
      throw new Error(`Failed to reject confirmation: ${response.statusText}`);
    }

    // Extract and return the response data
    // return await response.json();
    return response;
  } catch (error) {
    // Handle any errors that occur during the request
    throw new Error(`Failed to reject confirmation: ${error.message}`);
  }
};
