import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Config } from "../.env.js";
import { useLocation } from "react-router-dom";
import logo from "../logo192.png";
const ApplicationPage3 = () => {
  const [AuthName, setAuthName] = useState("");
  const [AuthContact, setAuthContact] = useState("");
  const [AuthEmail, setAuthEmail] = useState("");
  const [AuthOfficePosition, setAuthOfficePosition] = useState("");
  const [AuthDepartment, setAuthDepartment] = useState("");
  const [PrContact, setPrContact] = useState("");
  const [PrName, setPrName] = useState("");
  const [PrOfficePosition, setPrOfficePosition] = useState("");
  const [PrDepartment, setPrDepartment] = useState("");
  const [PrEmail, setPrEmail] = useState("");
  const [SrName, setSrName] = useState("");
  const [SrContact, setSrContact] = useState("");
  const [SrEmail, setSrEmail] = useState("");
  const [SrOfficePosition, setSrOfficePosition] = useState("");
  const [SrDepartment, setSrDepartment] = useState("");
  const [TrContact, setTrContact] = useState("");
  const [TrName, setTrName] = useState("");
  const [TrOfficePosition, setTrOfficePosition] = useState("");
  const [TrDepartment, setTrDepartment] = useState("");
  const [TrEmail, setTrEmail] = useState("");
  const [uploadGst, setUploadGst] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const { brandName } = location.state;
  const email = localStorage.getItem("Brand Email") || null;
  console.log("Email from GST Page:", email);
  console.log("Brand Names in GST Page:", brandName);

  let hasValNumber = /^(\+?\d{1,3})? ?\d{10}$/.test(AuthContact);
  let valprcontact = /^(\+?\d{1,3})? ?\d{10}$/.test(PrContact);
  let valSrContact = /^(\+?\d{1,3})? ?\d{10}$/.test(SrContact);
  let valTrContact = /^(\+?\d{1,3})? ?\d{10}$/.test(TrContact);
  //email validatin regx
  let hasValEmail = /^[a.-zA.-Z0.-9]+@[a.-zA.-Z0.-9]+\.[A-Za-z]+$/.test(
    AuthEmail
  );
  let valprEmail = /^[a.-zA.-Z0.-9]+@[a.-zA.-Z0.-9]+\.[A-Za-z]+$/.test(PrEmail);
  let valSrEmail = /^[a.-zA.-Z0.-9]+@[a.-zA.-Z0.-9]+\.[A-Za-z]+$/.test(SrEmail);
  let valTrEmail = /^[a.-zA.-Z0.-9]+@[a.-zA.-Z0.-9]+\.[A-Za-z]+$/.test(TrEmail);

  // password validations regx
  //let hasSixChar = Password.length >= 6;
  //let hasLowerChar = /[a-z]/.test(Password);
  //let hasUpperChar = /[A-Z]/.test(Password);
  //let hasNumber = /[0-9]/.test(Password);
  //let hasSpacialChar = /(.*[^a-zA-Z0-9].*)/.test(Password);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!location.state || !location.state.brandName) {
      console.error("Brand name not found in state");
      return;
    }
    // const phoneNumberPattern = /^[0-9]{10}$/;

    // // Email regex pattern
    // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // // Validate phone numbers
    // if (!phoneNumberPattern.test(AuthContact) || !phoneNumberPattern.test(PrContact) || !phoneNumberPattern.test(SrContact) || !phoneNumberPattern.test(TrContact)) {
    //     console.error('Invalid phone number');
    //     // Handle error - Display error message or prevent form submission
    //     return;
    // }

    // // Validate emails
    // if (!emailPattern.test(AuthEmail) || !emailPattern.test(PrEmail) || !emailPattern.test(SrEmail) || !emailPattern.test(TrEmail)) {
    //     console.error('Invalid email');
    //     // Handle error - Display error message or prevent form submission
    //     return;
    // }

    try {
      const { brandName } = location.state;
      console.log("Brand Names:", brandName);
      const response = await axios.post(`${Config.API_URL}/submitGst`, {
        brandName,
        AuthName,
        AuthContact,
        AuthEmail,
        AuthOfficePosition,
        AuthDepartment,
        PrContact,
        PrEmail,
        PrDepartment,
        PrOfficePosition,
        PrName,
        SrDepartment,
        SrOfficePosition,
        SrEmail,
        SrContact,
        SrName,
        TrEmail,
        TrDepartment,
        TrOfficePosition,
        TrName,
        TrContact,

        // Include other form data here
      });
      console.log(response.data);
      // const { brandName } = response.data;

      // navigate("/sign-in", { replace: true, state: { brandName: brandName } });
      if (email) {
        navigate("/brand-dashboard", { replace: true, state: { email } });
      } else {
        navigate("/sign-in", { replace: true });
      }
      console.log(response.data);
      // Handle successful form submission
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error
    }
  };
  return (
    <div className="container">
      <div className="container1 mt-5">
        <figure className="image is-64x64">
          {" "}
          {/* Adjust size as needed */}
          <img src={logo} alt="Logo" />
        </figure>
      </div>
      <form onSubmit={handleSubmit}>
        {/* <h1>welcome[{brandName}]</h1> */}
        <div className="field">
          <h1 className="title has-text-centered mt-5">
            Escalation Matrix Form
          </h1>

          <label className="label has-text-centered mt-5 pt-4 mr-2 ">
            Authorised Contact Person{" "}
          </label>
          <level className="label has-text-centered pb-6 ">
            {" "}
            (For Invoice, Contracts and Payments only)*
          </level>

          <label className="label has-text-left ">Name*</label>
          <div className="control">
            <input
              className="input is-rounded "
              type="text"
              placeholder="Name"
              value={AuthName}
              onChange={(e) => setAuthName(e.target.value)}
              style={{ minWidth: "600px", width: "100%" }}
            />
          </div>
        </div>

        <div className="field is-small">
          <label className="label has-text-left">Phone Number*</label>
          <div className="control">
            <input
              className="input is-rounded"
              type="tel"
              placeholder="Phone Number"
              value={AuthContact}
              onChange={(e) => setAuthContact(e.target.value)}
            />
            {AuthContact && !hasValNumber && (
              <div>
                <small
                  className={`ml-1 mt-1  ${
                    !hasValNumber && AuthContact !== ""
                      ? "has-text-danger"
                      : "has-text-success"
                  }`}
                >
                  {!hasValNumber && AuthContact !== ""
                    ? "Please Enter valid Mobile number"
                    : ""}
                </small>
              </div>
            )}
          </div>
          {/* {phoneNumberError && (
                        <p className="help is-danger">{phoneNumberError}</p>
                    )} */}
        </div>

        <div className="field">
          <label className="label has-text-left ">Email*</label>
          <div className="control">
            <input
              className="input is-rounded"
              type="email"
              placeholder="Email"
              value={AuthEmail}
              onChange={(e) => setAuthEmail(e.target.value)}
            />
            {AuthEmail && !hasValEmail && (
              <div>
                <small
                  className={`ml-1 mt-1  ${
                    !hasValEmail && AuthEmail !== ""
                      ? "has-text-danger"
                      : "has-text-success"
                  }`}
                >
                  {!hasValEmail && AuthEmail !== ""
                    ? "Please Enter valid Email"
                    : ""}
                </small>
              </div>
            )}
          </div>
        </div>

        <div className="field">
          <label className="label has-text-left">
            Official Position in the Organisation*
          </label>
          <div className="control">
            <input
              className="input is-rounded"
              type="text"
              placeholder="Official Position"
              value={AuthOfficePosition}
              onChange={(e) => setAuthOfficePosition(e.target.value)}
            />
          </div>
        </div>

        <div className="field">
          <label className="label has-text-left ">Department*</label>
          <div className="control">
            <input
              className="input is-rounded"
              type="text"
              placeholder="Department"
              value={AuthDepartment}
              onChange={(e) => setAuthDepartment(e.target.value)}
            />
          </div>
        </div>
        <div className="field">
          <label className="label has-text-centered mt-5 pt-5 pb-5">
            Primary Contact For Queries (SPOC)*
          </label>
          <label className="label has-text-left ">Name*</label>
          <div className="control">
            <input
              className="input is-rounded"
              type="text"
              placeholder="Name"
              value={PrName}
              onChange={(e) => setPrName(e.target.value)}
            />
          </div>
        </div>

        <div className="field">
          <label className="label has-text-left ">Phone Number*</label>
          <div className="control">
            <input
              className="input is-rounded"
              type="tel"
              placeholder="Phone Number"
              value={PrContact}
              onChange={(e) => setPrContact(e.target.value)}
            />
            {PrContact && !valprcontact && (
              <div>
                <small
                  className={`ml-1 mt-1  ${
                    !valprcontact && PrContact !== ""
                      ? "has-text-danger"
                      : "has-text-success"
                  }`}
                >
                  {!valprcontact && PrContact !== ""
                    ? "Please Enter valid Mobile number"
                    : ""}
                </small>
              </div>
            )}
          </div>
        </div>

        <div className="field">
          <label className="label has-text-left">Email*</label>
          <div className="control">
            <input
              className="input is-rounded"
              type="email"
              placeholder="Email"
              value={PrEmail}
              onChange={(e) => setPrEmail(e.target.value)}
            />
            {PrEmail && !valprEmail && (
              <div>
                <small
                  className={`ml-1 mt-1  ${
                    !valprEmail && PrEmail !== ""
                      ? "has-text-danger"
                      : "has-text-success"
                  }`}
                >
                  {!valprEmail && PrEmail !== ""
                    ? "Please Enter valid Email"
                    : ""}
                </small>
              </div>
            )}
          </div>
        </div>

        <div className="field">
          <label className="label has-text-left ">
            Official Position in the Organisation*
          </label>
          <div className="control">
            <input
              className="input is-rounded"
              type="text"
              placeholder="Official Position"
              value={PrOfficePosition}
              onChange={(e) => setPrOfficePosition(e.target.value)}
            />
          </div>
        </div>

        <div className="field">
          <label className="label has-text-left">Department*</label>
          <div className="control">
            <input
              className="input is-rounded"
              type="text"
              placeholder="Department"
              value={PrDepartment}
              onChange={(e) => setPrDepartment(e.target.value)}
            />
          </div>
        </div>
        <div className="field">
          <label className="label has-text-centered mt-5 pt-5 pb-5">
            Secondary Contact for Queries
          </label>
          <label className="label has-text-left  ">Name</label>
          <div className="control">
            <input
              className="input is-rounded"
              type="text"
              placeholder="Name"
              value={SrName}
              onChange={(e) => setSrName(e.target.value)}
            />
          </div>
        </div>

        <div className="field">
          <label className="label has-text-left ">Phone Number</label>
          <div className="control">
            <input
              className="input is-rounded"
              type="tel"
              placeholder="Phone Number"
              value={SrContact}
              onChange={(e) => setSrContact(e.target.value)}
            />
            {SrContact && !valSrContact && (
              <div>
                <small
                  className={`ml-1 mt-1  ${
                    !valSrContact && SrContact !== ""
                      ? "has-text-danger"
                      : "has-text-success"
                  }`}
                >
                  {!valSrContact && SrContact !== ""
                    ? "Please Enter valid Mobile number"
                    : ""}
                </small>
              </div>
            )}
          </div>
        </div>

        <div className="field">
          <label className="label has-text-left ">Email</label>
          <div className="control">
            <input
              className="input is-rounded"
              type="email"
              placeholder="Email"
              value={SrEmail}
              onChange={(e) => setSrEmail(e.target.value)}
            />
            {SrEmail && !valSrEmail && (
              <div>
                <small
                  className={`ml-1 mt-1  ${
                    !valSrEmail && SrEmail !== ""
                      ? "has-text-danger"
                      : "has-text-success"
                  }`}
                >
                  {!valSrEmail && SrEmail !== ""
                    ? "Please Enter valid Email"
                    : ""}
                </small>
              </div>
            )}
          </div>
        </div>

        <div className="field">
          <label className="label has-text-left ">
            Official Position in the Organisation
          </label>
          <div className="control">
            <input
              className="input is-rounded"
              type="text"
              placeholder="Official Position"
              value={SrOfficePosition}
              onChange={(e) => setSrOfficePosition(e.target.value)}
            />
          </div>
        </div>

        <div className="field">
          <label className="label has-text-left ">Department</label>
          <div className="control">
            <input
              className="input is-rounded"
              type="text"
              placeholder="Department"
              value={SrDepartment}
              onChange={(e) => setSrDepartment(e.target.value)}
            />
          </div>
        </div>
        <div className="field">
          <label className="label has-text-centered mt-5 pt-5 pb-5">
            Tertiary Contact For Queries
          </label>
          <label className="label has-text-left  ">Name</label>
          <div className="control">
            <input
              className="input is-rounded"
              type="text"
              placeholder="Name"
              value={TrName}
              onChange={(e) => setTrName(e.target.value)}
            />
          </div>
        </div>

        <div className="field">
          <label className="label has-text-left  ">Phone Number</label>
          <div className="control">
            <input
              className="input is-rounded"
              type="tel"
              placeholder="Phone Number"
              value={TrContact}
              onChange={(e) => setTrContact(e.target.value)}
            />
            {TrContact && !valTrContact && (
              <div>
                <small
                  className={`ml-1 mt-1  ${
                    !valTrContact && TrContact !== ""
                      ? "has-text-danger"
                      : "has-text-success"
                  }`}
                >
                  {!valTrContact && TrContact !== ""
                    ? "Please Enter valid Mobile number"
                    : ""}
                </small>
              </div>
            )}
          </div>
        </div>

        <div className="field">
          <label className="label has-text-left ">Email</label>
          <div className="control">
            <input
              className="input is-rounded"
              type="email"
              placeholder="Email"
              value={TrEmail}
              onChange={(e) => setTrEmail(e.target.value)}
            />
            {TrEmail && !valTrEmail && (
              <div>
                <small
                  className={`ml-1 mt-1  ${
                    !valTrEmail && TrEmail !== ""
                      ? "has-text-danger"
                      : "has-text-success"
                  }`}
                >
                  {!valTrEmail && TrEmail !== ""
                    ? "Please Enter valid Email"
                    : ""}
                </small>
              </div>
            )}
          </div>
        </div>

        <div className="field">
          <label className="label has-text-left ">
            Official Position in the Organisation
          </label>
          <div className="control">
            <input
              className="input is-rounded"
              type="text"
              placeholder="Official Position"
              value={TrOfficePosition}
              onChange={(e) => setTrOfficePosition(e.target.value)}
            />
          </div>
        </div>

        <div className="field">
          <label className="label has-text-left ">Department</label>
          <div className="control">
            <input
              className="input is-rounded"
              type="text"
              placeholder="Department"
              value={TrDepartment}
              onChange={(e) => setTrDepartment(e.target.value)}
            />
          </div>
        </div>
        <button
          className="button is-primary is-rounded is-focused is-medium is-fullwidth my-5"
          type="submit"
          disabled={
            !AuthName ||
            !AuthContact ||
            !AuthEmail ||
            !AuthOfficePosition ||
            !AuthDepartment ||
            !PrName ||
            !PrContact ||
            !PrEmail ||
            !PrOfficePosition ||
            !PrDepartment
          }
        >
          SUBMIT
        </button>
      </form>
    </div>
  );
};

export default ApplicationPage3;
