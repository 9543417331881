import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Config } from "../.env.js";

const Logout = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      // Call the logout API
      const response = await axios.post(
        `${Config.API_URL}/auth/logout`,
        {},
        { withCredentials: true }
      );

      if (response.status === 200) {
        // console.log(response.data.message); // "Logout successful"
        // Redirect to login page
        navigate("/");
      }
    } catch (error) {
      console.error("Error during logout:", error);
      alert("Logout failed. Please try again.");
    }
  };

  return (
    <div
      // className="container"
      style={
        {
          // position: "absolute",
          // display: "flex",
          // justifyContent: "end",
          // gap: "20px",
          // padding: "40px 0 30px 0",
          // bottom: "30px",
          // right: "20px",
          // left: "0",
          // marginRight: "30px",
        }
      }
    >
      <button className="button insta-insights-cta" onClick={handleLogout}>
        <i className="fas fa-sign-out-alt fa-lg"></i>
        <br></br>
        <span className="font-weight-800" style={{ marginTop: "-15px" }}>
          Logout
        </span>
      </button>
      {/* <button>
        <i className="fa-solid fa-gear mr-2"></i>Settings
      </button> */}
    </div>
  );
};

export default Logout;
