import React, { useState, useEffect } from "react";
import InfluencerConformationTableAdmin from "./infConfTableAdmin";
import InfluencerDeliverablesTableAdmin from "./infDeliverablesAdmin";
import InfluencerTableAdmin from "./InfluencerTableAdmin";
import { useParams } from "react-router-dom";
import { getInfluencerCampaigns } from "../components/infApi";

const ViewInfluencer = ({ campaign }) => {
  const { brandName } = useParams();
  // const [brandName, setBrandName] = useState('');
  // const brandName = campaign;

  // console.log("brand" , brandName);
  return (
    <div className="container has-text-centered mt-3">
      <div style={{ position: "relative", overflowY: "auto" }}>
        <div>
          <InfluencerTableAdmin campaign={campaign} brandName={brandName} />
        </div>
        <div style={{ position: "absolute", top: "4.7rem", left: "113rem" }}>
          <InfluencerDeliverablesTableAdmin
            campaign={campaign}
            brandName={brandName}
          />
        </div>
        <div style={{ position: "absolute", top: "4.7rem", left: "143.5rem" }}>
          <InfluencerConformationTableAdmin
            campaign={campaign}
            brandName={brandName}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewInfluencer;
