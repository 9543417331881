import React, { useState } from "react";

const InfluencerProfile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const handleEditProfile = () => {
    setIsEditing(true);
  };
  const [UserName, setUserName] = useState("");
  const [followerRange, setFollowerRange] = useState("");
  const [ExactFollower, setExactFollower] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [otherCategory, setOtherCategory] = useState("");
  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCategory(selectedValue);

    if (selectedValue === "other") {
      setOtherCategory("");
    }
  };
  const [PhoneNumber, setPhoneNumber] = useState("");

  //phone number validations
  //   let hasValNumber = /^[0-9]{1,10}$/.test(PhoneNumber);
  let hasValNumber = /^(\+?\d{1,3})? ?\d{10}$/.test(PhoneNumber);

  const [WhatsappNumber, setWhatsappNumber] = useState("");
  // validations
  //   let hasWhatsappVal = /^[0-9]{1,10}$/.test(WhatsappNumber);
  let hasWhatsappVal = /^(\+?\d{1,3})? ?\d{10}$/.test(WhatsappNumber);

  const [Email, setemail] = useState("");
  //email validatin regx
  let hasValEmail = /^[a.-zA.-Z0.-9]+@[a.-zA.-Z0.-9]+\.[A-Za-z]+$/.test(Email);

  const [Age, setAge] = useState("");
  const [YouTubeLink, setYouTubeLink] = useState("");
  const [YouTubeSubRange, setYouTubeSubRange] = useState("");

  return (
    <div className="container has-text-centered mt-5">
      <h1 className="subtitle">Influencer Profile Page</h1>

      {isEditing ? (
        // Render input fields for Data Points in edit mode
        <div>
          <div className="field">
            <label className="label my-2 has-text-left">User Name</label>
            <input
              type="text"
              className="input is-rounded"
              placeholder="User name"
              value={UserName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </div>

          <div className="field">
            <label className="label my-2 has-text-left">
              Instagram Followers Range
            </label>
            <div className="select  is-rounded is-fullwidth">
              <select
                value={followerRange}
                onChange={(e) => setFollowerRange(e.target.value)}
              >
                <option>Select</option>
                <option>under 1k</option>
                <option>1k-5k</option>
                <option>5k-10k</option>
                <option>10k-50k</option>
                <option>50k and above</option>
              </select>
            </div>
          </div>
          {/* Exact Followers  */}
          <div className="field">
            <div className="control">
              <div className="colomn">
                <label className="label my-2 has-text-left">
                  Please Enter Exact Followers
                </label>
                <input
                  type="number"
                  className="input is-rounded"
                  placeholder="Exact Follower"
                  value={ExactFollower}
                  onChange={(e) => setExactFollower(e.target.value)}
                />
              </div>
            </div>
          </div>

          {/* Exact category */}
          <div className="field">
            <div className="control">
              <div className="colomn">
                <div className="my-2 has-text-left">
                  <strong>
                    What is your Exact category? For example: <br />
                    Cosmetics or Skincare etc. Pls fill the
                    <br /> true details here!
                  </strong>
                </div>
                <div className="select  is-rounded is-fullwidth">
                  <select
                    value={selectedCategory}
                    onChange={handleDropdownChange}
                    className="dropdown is-rounded"
                  >
                    <option>Select</option>
                    <option>Skincare</option>
                    <option>Dermatologist</option>
                    <option>Beauty and Makeup</option>
                    <option>Hair Care</option>
                    <option>Body Care</option>
                    <option>Personal Care</option>
                    <option>Health & Wellness</option>
                    <option>Fashion</option>
                    <option>LifeStyle</option>
                    <option>Travel</option>
                    <option>Other</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          {selectedCategory === "Other" && (
            <div className="field">
              <label className="label has-text-left ml-3">Other Category</label>
              <div className="control">
                <input
                  className="input is-rounded"
                  placeholder="Enter other category"
                  value={otherCategory}
                  onChange={(e) => setOtherCategory(e.target.value)}
                />
              </div>
            </div>
          )}
          {/* phonenumber */}
          <div className="field ">
            <label className="label has-text-left ml-3">
              Phone Number (Calling number to contact)
            </label>
            <div className="control  ">
              <div className="colomn">
                <input
                  className="input  is-rounded "
                  type="text"
                  placeholder="Phone Number"
                  value={PhoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />

                {/* mobile number validation */}
                {PhoneNumber && !hasValNumber && (
                  <div>
                    <small
                      className={`ml-1 mt-1  ${
                        !hasValNumber && PhoneNumber !== ""
                          ? "has-text-danger"
                          : "has-text-success"
                      }`}
                    >
                      {!hasValNumber && PhoneNumber !== ""
                        ? "Please Enter valid Mobile number"
                        : ""}
                    </small>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* whatsapp */}
          <div className="field ">
            <label className="label has-text-left ml-3">
              WhatsApp no (Should be available)
            </label>
            <div className="control  ">
              <div className="colomn">
                <input
                  className="input  is-rounded "
                  type="text"
                  placeholder="Whatsapp Number"
                  value={WhatsappNumber}
                  onChange={(e) => setWhatsappNumber(e.target.value)}
                />

                {/* mobile number validation */}
                {WhatsappNumber && !hasWhatsappVal && (
                  <div>
                    <small
                      className={`ml-1 mt-1  ${
                        !hasWhatsappVal && WhatsappNumber !== ""
                          ? "has-text-danger"
                          : "has-text-success"
                      }`}
                    >
                      {!hasWhatsappVal && WhatsappNumber !== ""
                        ? "Please Enter valid Whatsapp Number"
                        : ""}
                    </small>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* email id */}
          <div className="field ">
            <label className="label has-text-left ml-3">Email</label>
            <div className="control ">
              <div className="colomn">
                <input
                  className="input is-rounded "
                  type="text"
                  placeholder="Email ID"
                  value={Email}
                  onChange={(e) => setemail(e.target.value)}
                />
                {Email && !hasValEmail && (
                  <small
                    className={`ml-1 mt-1  ${
                      !hasValEmail && Email !== ""
                        ? "has-text-danger"
                        : "has-text-success"
                    }`}
                  >
                    {!hasValEmail && Email !== ""
                      ? "Enter a valid email address"
                      : ""}
                  </small>
                )}
              </div>
            </div>
          </div>
          {/* age */}
          <div className="field ">
            <label className="label has-text-left ml-3">Age</label>
            <div className="control">
              <div className="colomn">
                <input
                  className="input is-rounded"
                  type="number"
                  placeholder="Age"
                  value={Age}
                  onChange={(e) => setAge(e.target.value)}
                />
              </div>
            </div>
          </div>

          {/* youtube link */}
          <div className="field ">
            <label className="label has-text-left ml-3">
              YouTube Channel Link
            </label>
            <div className="control">
              <div className="colomn">
                <input
                  className="input is-rounded"
                  type="text"
                  placeholder="Channel Link"
                  value={YouTubeLink}
                  onChange={(e) => setYouTubeLink(e.target.value)}
                />
              </div>
            </div>
          </div>

          {/* youtube sub range */}
          <div className="field">
            <label className="label has-text-left ml-3">
              YouTube Subscribers Range
            </label>
            <div className="control">
              <div className="colomn">
                <div className="select  is-rounded is-fullwidth">
                  <select
                    value={YouTubeSubRange}
                    onChange={(e) => setYouTubeSubRange(e.target.value)}
                  >
                    <option>Select</option>
                    <option>under 1k</option>
                    <option>1k-5k</option>
                    <option>5k-10k</option>
                    <option>10k-50k</option>
                    <option>50k and above</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          {/* Add more input fields for other data points  */}

          <button
            className="button is-primary is-rounded my-5"
            onClick={() => setIsEditing(false)}
          >
            Save
          </button>
        </div>
      ) : (
        // Render the profile display
        <div style={{ textAlign: "left" }}>
          <p>
            User Name: <strong>{UserName}</strong>
          </p>
          <p>
            Instagram Followers Range: <strong>{followerRange}</strong>
          </p>
          <p>
            Please Enter Exact Followers:<strong>{ExactFollower}</strong>
          </p>
          <p>
            Exact category: <strong>{selectedCategory}</strong>
          </p>
          <p>
            Phone Number : <strong>{PhoneNumber}</strong>
          </p>
          <p>
            WhatsApp Number : <strong>{WhatsappNumber}</strong>
          </p>
          <p>
            Email : <strong>{Email}</strong>
          </p>
          <p>
            Age : <strong>{Age}</strong>
          </p>
          <p>
            YouTube Channel Link : <strong>{YouTubeLink}</strong>
          </p>
          <p>
            YouTube Subscribers Range : <strong>{YouTubeSubRange}</strong>
          </p>

          {/* Display other data points  */}

          <button
            className="button is-info is-rounded mt-5"
            onClick={handleEditProfile}
          >
            Edit Profile
          </button>
        </div>
      )}
    </div>
  );
};

export default InfluencerProfile;
