import React from "react";
import { Link } from "react-router-dom";
const Home = () => {
  return (
    <div className="imgfull">
      <div
        className="container1 has-text-centered  "
        style={{ paddingBottom: "29rem" }}
      >
        <h1 className="subtitle mt-5 has-text-white">Home Page</h1>
        <div className="link">
          <p className="has-text-weight-bold has-text-white">
            Internal Team Dashboard
            <Link to="/internal-signin">
              <strong className=" has-text-link ml-3 has-test-blue ">
                {" "}
                Login{" "}
              </strong>
            </Link>
            <Link to="/internal-signup">
              <strong className="has-text-link ml-3"> Signup</strong>
            </Link>
          </p>
        </div>
        <div className="link mt-5">
          <p className="has-text-weight-bold has-text-white ">
            Brand
            <Link to="/signinbrand">
              <strong className=" has-text-link ml-3"> Login </strong>
            </Link>
            <Link to="/sign-up">
              <strong className=" has-text-link ml-3"> Signup </strong>
            </Link>
          </p>
        </div>
        <div className="link mt-5">
          <p className="has-text-weight-bold has-text-white ">
            Influencer
            <Link to="/influencer-login">
              <strong className=" has-text-link ml-3"> Login </strong>
            </Link>
            <Link to="/influencer-signup">
              <strong className=" has-text-link ml-3"> Signup </strong>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
