import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Config } from "../.env.js";

const Logout = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      // Call the logout API
      const response = await axios.post(
        `${Config.API_URL}/logout`,
        {},
        { withCredentials: true }
      );

      if (response.status === 200) {
        // console.log(response.data.message); // "Logout successful"
        // Redirect to login page
        navigate("/");
      }
    } catch (error) {
      console.error("Error during logout:", error);
      alert("Logout failed. Please try again.");
    }
  };

  return (
    <li className="my-5 has-text-white ml-4" onClick={handleLogout}>
      <MenuItem title="Logout" icon="fas fa-sign-out-alt" />
    </li>
  );
};

function MenuItem({ title, icon, clickHandler }) {
  return (
    <div
      className="ml-2 my-2"
      style={{ cursor: "pointer" }}
      onClick={clickHandler}
    >
      <span className="icon is-small has-text-success mr-1">
        <i
          className={icon}
          style={{ marginRight: "50px", color: "#FFF623", fontSize: "20px" }}
        ></i>
      </span>
      <span style={{ marginLeft: "-25px", fontSize: "18px" }}>{title}</span>
    </div>
  );
}

export default Logout;
