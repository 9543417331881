import React, { useState, useEffect } from "react";
import { getBrandCampaigns1 } from "../components/api";
import { useNavigate } from "react-router-dom";
import {
  approveCampaign,
  createCampaign,
  updateApprove,
} from "../components/campaignApi";
import { updateCampaign } from "../components/apiEdit";
import { approve, rejectedCampaignBrand } from "../components/infApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
const ViewCampaign = (campaign) => {
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [editingCampaign, setEditingCampaign] = useState(null);
  const [error, setError] = useState();

  const { campaignName } = useParams();

  useEffect(() => {
    fetchBrandCampaigns();
    // fetchAllCampaigns();
  }, [campaignName]);

  const fetchBrandCampaigns = async () => {
    try {
      const response = await getBrandCampaigns1();
      const data = await response.json();
      const campaigndata = data.filter(
        (campaign) => campaign.campaignName === campaignName
      );
      if (response.ok) {
        setCampaigns(campaigndata);
      } else {
        setError(data.message || "Failed to fetch brand campaigns");
      }
    } catch (error) {
      console.error("Error fetching campaigns:", error);
      setError("Error fetching campaigns: " + error.message);
    }
  };

  const constructViewPhotoLink = (filename) => {
    return `/uploads/${encodeURIComponent(filename)}`;
  };

  const [isadmin, setisAdmin] = useState(false);
  const [islead, setisLead] = useState(false);
  const [isexecutive, setisExecutive] = useState(false);
  const [isbrand, setisBrand] = useState(false);

  const [isadminContent, setisAdminContent] = useState(false);
  const [isleadContent, setisLeadContent] = useState(false);
  const [isexecutiveContent, setisExecutiveContent] = useState(false);
  const [isbrandContent, setisBrandContent] = useState(false);

  const [products, setProducts] = useState(false);
  const [other, setOther] = useState(false);
  const [CashMoney, setCashMoney] = useState(false);

  const [male, setMale] = useState(false);
  const [female, setfemale] = useState(false);
  const [transgender, setTransgender] = useState(false);
  const [uploadBrandLogo, setUploadBrandLogo] = useState(null);
  const handleEdit = (campaign) => {
    // Copy the campaign object to avoid mutating the original data
    const editedCampaign = { ...campaign };
    setEditingCampaign(editedCampaign);

    // Set the checkbox states based on the selected campaign's data
    setisAdmin(editedCampaign.isAdmin);
    setisLead(editedCampaign.isLead);
    setisExecutive(editedCampaign.isExecutive);
    setisBrand(editedCampaign.isBrand);
    setisAdminContent(editedCampaign.isadminContent);
    setisLeadContent(editedCampaign.isleadContent);
    setisExecutiveContent(editedCampaign.isexecutiveContent);
    setisBrandContent(editedCampaign.isbrandContent);

    setProducts(editedCampaign.products);
    setCashMoney(editedCampaign.CashMoney);
    setOther(editedCampaign.other);

    setMale(editedCampaign.male);
    setfemale(editedCampaign.female);
    setTransgender(editedCampaign.transgender);
  };

  const handleCheckboxChange2 = (e) => {
    const { name, checked } = e.target;
    switch (name) {
      case "products":
        setProducts(checked);

        break;
      case "CashMoney":
        setCashMoney(checked);

        break;
      case "other":
        setOther(checked);

        break;
      default:
        break;
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    switch (name) {
      case "admin":
        setisAdmin(checked);
        break;
      case "lead":
        setisLead(checked);
        break;
      case "executive":
        setisExecutive(checked);
        break;
      case "brand":
        setisBrand(checked);
        break;
      default:
        break;
    }
  };

  const handleCheckboxChange1 = (e) => {
    const { name, checked } = e.target;
    switch (name) {
      case "adminContent":
        setisAdminContent(checked);
        break;
      case "leadContent":
        setisLeadContent(checked);
        break;
      case "executiveContent":
        setisExecutiveContent(checked);
        break;
      case "brandContent":
        setisBrandContent(checked);
        break;
      default:
        break;
    }
  };

  const handleUpdate = async () => {
    try {
      const { _id, ...updatedData } = editingCampaign;
      // Remove _id field before updating
      const updatedCampaign = {
        ...updatedData,
        isAdmin: isadmin,
        isLead: islead,
        isExecutive: isexecutive,
        isBrand: isbrand,
        isadminContent: isadminContent,
        isleadContent: isleadContent,
        isexecutiveContent: isexecutiveContent,
        isbrandContent: isbrandContent,

        products: products,
        CashMoney: CashMoney,
        other: other,
        male: male,
        female: female,
        transgender: transgender,
      };
      await updateCampaign(_id, updatedCampaign); // Send updated data to backend
      // If update successful, reset editingCampaign state and fetch updated campaigns
      setEditingCampaign(null);
      fetchBrandCampaigns();
      toast.success("Campaign updated successfully");
    } catch (error) {
      setError("Error updating campaign: " + error.message);
      console.error("Error updating campaign:", error);
    }
  };

  const handleCancelEdit = () => {
    setEditingCampaign(null); // Reset editingCampaign state on cancel
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    // Extracting just the file name
    const fileName = file.name;
    setUploadBrandLogo(file);
    setEditingCampaign({ ...editingCampaign, uploadBrandLogo: fileName });
  };

  const handleCheckboxgenderchange = (e) => {
    const { name } = e.target;
    switch (name) {
      case "male":
        setMale((prevState) => !prevState);
        break;
      case "female":
        setfemale((prevState) => !prevState);
        break;
      case "transgender":
        setTransgender((prevState) => !prevState);
        break;
      default:
        break;
    }
  };

  const handlechackboxesChange = (e) => {
    const { name, checked } = e.target;
    setEditingCampaign((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  return (
    <div className="container mt-3 ">
      <form>
        {campaigns.map((campaign) => (
          <div key={campaign._id}>
            <div className="has-text-right ml-5">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleEdit(campaign);
                }}
                disabled={campaign.approve || campaign.rejected}
                className="button is-primary"
              >
                Edit
              </button>
            </div>
            <div className="field">
              <label className="label">Brand Name :</label>
              <div className="control">
                <input type=" text" readOnly value={campaign.brandName} />
              </div>
            </div>
            <div className="field">
              <label className="label">
                {" "}
                How many influencers are you looking forward to work with?:
              </label>
              <div className="control">
                <input type=" text" readOnly value={campaign.influencer} />
              </div>
            </div>

            <div className="field">
              <label className="label">
                Please specify the exact deliverables you want from Influencers:
              </label>
              <div className="control">
                <input type=" text" readOnly value={campaign.infDeliverables} />
              </div>
            </div>
            <div className="field">
              <label className="label has-text-left ml-3">
                What would you be giving to influencers in exchange for
                deliverables?
              </label>
              <div className="control">
                <input
                  type=" text"
                  readOnly
                  value={
                    campaign.products
                      ? "Products"
                      : campaign.cashMoney
                      ? "Cash/Money"
                      : campaign.other
                      ? "Other"
                      : "Not Specified"
                  }
                />
              </div>
            </div>

            <div className="field">
              <label className="additionalQuestionLabel">
                Value (in Rs.) of the cash/products being given to influencers?
                *
              </label>
              <div className="control">
                <input type=" text" readOnly value={campaign.cashValue} />
              </div>
            </div>
            <div className="field">
              <label className="label">Target influencers' gender*:</label>
              <div className="control">
                <input
                  type=" text"
                  readOnly
                  value={
                    campaign.male
                      ? "Male"
                      : campaign.female
                      ? "Female"
                      : campaign.transgender
                      ? "Transgender"
                      : "Not specified"
                  }
                />
              </div>
            </div>
            <div className="field">
              <label className="label"> Followers criteria:</label>
              <div className="control">
                <input
                  type=" text"
                  readOnly
                  value={campaign.followersCriteria}
                />
              </div>
            </div>

            <div className="field">
              <label className="label">
                {" "}
                What is your target audience age group?:
              </label>
              <div className="control">
                <input type=" text" readOnly value={campaign.ageGroup} />
              </div>
            </div>

            <div className="field">
              <strong>Influencers Categories You Want to Target *</strong>
              <div className="control">
                <input
                  type=" text"
                  readOnly
                  value={
                    campaign.Cosmetics
                      ? "Cosmetics"
                      : campaign.Lifestyle
                      ? "Lifestyle"
                      : campaign.Fashion
                      ? "Fashion"
                      : campaign.Food
                      ? "Food"
                      : campaign.HealthFitness
                      ? "Health & Fitnesss"
                      : "not specified"
                  }
                />
              </div>
            </div>

            <div className="field">
              <label className="label  ">
                {" "}
                Please mention the links of the products, influencers shall be
                getting (*If product shipment is involved)
                <b>
                  <br /> Please write the correct product link. Writing wrong or
                  general link will disqualify the campaign.
                </b>
              </label>
              <div className="control">
                <input type=" text" readOnly value={campaign.productLink} />
              </div>
            </div>

            <div className="field">
              <label className="label">
                {" "}
                For how many weeks are you planning to run the campaign?
              </label>
              <div className="control">
                <input type=" text" readOnly value={campaign.campainTime} />
              </div>
            </div>
            <div className="field">
              <label className="label "> Campaign Name</label>
              <div className="control">
                <input type=" text" readOnly value={campaign.campaignName} />
              </div>
            </div>
            <div className="field">
              <label className="label">
                Upload Cover Image For The Campaign{" "}
              </label>
              <div className="control">
                <input
                  type=" text"
                  readOnly
                  value={
                    campaign.uploadBrandLogo ? campaign.uploadBrandLogo : "N/A"
                  }
                />
              </div>
            </div>

            <div className="field">
              <label className="label">Upload Brief pdf</label>
              <div className="control">
                <input type=" text" readOnly value={campaign.uploadBrief} />
              </div>
            </div>

            <div className="field">
              <label className="label">
                Is this campaign for a specific location?
              </label>
              <div className="control">
                <input
                  type=" text"
                  readOnly
                  value={
                    campaign.isSpecificLocation
                      ? campaign.isSpecificLocation
                      : "N/A"
                  }
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Write location/city/state</label>
              <div className="control">
                <input
                  type=" text"
                  readOnly
                  value={campaign.location ? campaign.location : "N/A"}
                />
              </div>
            </div>

            <div className="field">
              <label className="label ">
                Any other eligibility criteria to select Influencers?
              </label>
              <div className="control">
                <input
                  type=" text"
                  readOnly
                  value={campaign.selectionCriteria}
                />
              </div>
            </div>
            <div className="field">
              <label className="label ">Any message for us?</label>
              <div className="control">
                <input type=" text" readOnly value={campaign.message} />
              </div>
            </div>

            <div className=" has-text-blue has-text-left my-4">
              <a className="has-text-left" href="javascript:history.back()">
                Back to Previous Page
              </a>
            </div>
          </div>
        ))}
      </form>

      {editingCampaign && (
        <div
          className="modal is-active"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div
            className="modal-content"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "8px",
              width: "80%",
              maxWidth: "800px",
            }}
          >
            <button
              className="delete"
              aria-label="close"
              onClick={handleCancelEdit}
            ></button>
            <h2 className="title is-4">Edit Campaign</h2>
            {/* Input fields for editing campaign attributes */}
            <div className="field">
              <label className="label">Brand Name:</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  id="brandName"
                  value={editingCampaign.brandName}
                  onChange={(e) =>
                    setEditingCampaign({
                      ...editingCampaign,
                      brandName: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="field">
              <label className="label">
                {" "}
                How many influencers are you looking forward to work with?:
              </label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  id="influencer"
                  value={editingCampaign.influencer}
                  onChange={(e) =>
                    setEditingCampaign({
                      ...editingCampaign,
                      influencer: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="field">
              <label className="label">
                Please specify the exact deliverables you want from Influencers:
              </label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  id="infDeliverables"
                  value={editingCampaign.infDeliverables}
                  onChange={(e) =>
                    setEditingCampaign({
                      ...editingCampaign,
                      infDeliverables: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="field mr-6">
              <label className="label has-text-left ml-3">
                What would you be giving to influencers in exchange for
                deliverables?
              </label>

              <div className="field has-text-left">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    name="products"
                    checked={products}
                    onChange={handleCheckboxChange2}
                  />
                  Products
                </label>
              </div>
              <div className="field has-text-left">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    name="CashMoney"
                    checked={CashMoney}
                    onChange={handleCheckboxChange2}
                  />
                  Cash/Money
                </label>
              </div>
              <div className="field has-text-left">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    name="other"
                    checked={other}
                    onChange={handleCheckboxChange2}
                  />
                  Other
                </label>
              </div>
            </div>

            <div className="additionalQuestionContainer">
              <label className="additionalQuestionLabel">
                Value (in Rs.) of the cash/products being given to influencers?
                *
              </label>
              <input
                type="text"
                className="input"
                placeholder="Your answer"
                placeholderTextColor="#999"
                value={editingCampaign.cashValue}
                onChange={(e) =>
                  setEditingCampaign({
                    ...editingCampaign,
                    cashValue: e.target.value,
                  })
                }
              />
            </div>

            <div className="field">
              <label className="label">Target influencers' gender*:</label>
              <div className="checkboxContainer mr-6">
                <input
                  type="checkbox"
                  name="male"
                  checked={male}
                  onChange={handleCheckboxgenderchange}
                />
                <label className="checkboxLabel mr-6">Male</label>
                <input
                  type="checkbox"
                  name="female"
                  checked={female}
                  onChange={handleCheckboxgenderchange}
                />
                <label className="checkboxLabel mr-6">Female</label>
                <input
                  type="checkbox"
                  name="transgender"
                  checked={transgender}
                  onChange={handleCheckboxgenderchange}
                  className="checkbox"
                />
                <label className="checkboxLabel mr-6">Transgender</label>
              </div>
            </div>
            <div className="field">
              <label className="label"> Followers criteria:</label>
              <div className="select">
                <select
                  value={editingCampaign.followersCriteria}
                  onChange={(e) =>
                    setEditingCampaign({
                      ...editingCampaign,
                      followersCriteria: e.target.value,
                    })
                  }
                >
                  <option value="">Select criteria</option>
                  <option value="1000">under1k</option>
                  <option value="1000-5000">1k-5k</option>
                  {/* Add more options as needed */}
                </select>
              </div>
            </div>
            <div className="field">
              <label className="label">
                {" "}
                What is your target audience age group?:
              </label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  id="deliverables"
                  value={editingCampaign.ageGroup}
                  onChange={(e) =>
                    setEditingCampaign({
                      ...editingCampaign,
                      ageGroup: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="field">
              <div className="control">
                <div className="colomn">
                  <div className="my-2 has-text-left">
                    <strong>Influencers Categories You Want to Target *</strong>
                  </div>
                  <div className="checkboxContainer mr-6">
                    <input
                      type="checkbox"
                      disabled={false}
                      name="skincare"
                      checked={editingCampaign.skincare}
                      onChange={handlechackboxesChange}
                      className="checkbox"
                    />
                    <label className="checkboxLabel mr-4"> Skincare </label>

                    <input
                      type="checkbox"
                      disabled={false}
                      name="cosmetics"
                      checked={editingCampaign.cosmetics}
                      onChange={handlechackboxesChange}
                      className="checkbox"
                    />
                    <label className="checkboxLabel mr-4"> Cosmetics </label>

                    <input
                      type="checkbox"
                      disabled={false}
                      name="lifeStyle"
                      checked={editingCampaign.lifeStyle}
                      onChange={handlechackboxesChange}
                      className="checkbox"
                    />
                    <label className="checkboxLabel mr-4"> Lifestyle </label>

                    <input
                      type="checkbox"
                      disabled={false}
                      name="haircare"
                      checked={editingCampaign.haircare}
                      onChange={handlechackboxesChange}
                      className="checkbox"
                    />
                    <label className="checkboxLabel mr-4"> Haircare </label>

                    <input
                      type="checkbox"
                      disabled={false}
                      name="beauty"
                      checked={editingCampaign.beauty}
                      onChange={handlechackboxesChange}
                      className="checkbox"
                    />
                    <label className="checkboxLabel mr-4"> Beauty </label>

                    <input
                      type="checkbox"
                      disabled={false}
                      name="food"
                      checked={editingCampaign.food}
                      onChange={handlechackboxesChange}
                      className="checkbox"
                    />
                    <label className="checkboxLabel mr-4"> Food </label>

                    <input
                      type="checkbox"
                      disabled={false}
                      name="fashion"
                      checked={editingCampaign.fashion}
                      onChange={handlechackboxesChange}
                      className="checkbox"
                    />
                    <label className="checkboxLabel mr-4"> Fashion </label>

                    <input
                      type="checkbox"
                      disabled={false}
                      name="HealthFitness"
                      checked={editingCampaign.HealthFitness}
                      onChange={handlechackboxesChange}
                      className="checkbox"
                    />
                    <label className="checkboxLabel mr-4">
                      {" "}
                      Health & Fitness{" "}
                    </label>

                    <input
                      type="checkbox"
                      disabled={false}
                      name="travel"
                      checked={editingCampaign.travel}
                      onChange={handlechackboxesChange}
                      className="checkbox"
                    />
                    <label className="checkboxLabel mr-4"> Travel </label>

                    <input
                      type="checkbox"
                      disabled={false}
                      name="student"
                      checked={editingCampaign.student}
                      onChange={handlechackboxesChange}
                      className="checkbox"
                    />
                    <label className="checkboxLabel mr-4"> Student </label>

                    <input
                      type="checkbox"
                      disabled={false}
                      name="houseWife"
                      checked={editingCampaign.houseWife}
                      onChange={handlechackboxesChange}
                      className="checkbox"
                    />
                    <label className="checkboxLabel mr-4"> HouseWife </label>

                    <input
                      type="checkbox"
                      disabled={false}
                      name="gaming"
                      checked={editingCampaign.gaming}
                      onChange={handlechackboxesChange}
                      className="checkbox"
                    />
                    <label className="checkboxLabel mr-4"> Gaming </label>

                    <input
                      type="checkbox"
                      disabled={false}
                      name="dance"
                      checked={editingCampaign.dance}
                      onChange={handlechackboxesChange}
                      className="checkbox"
                    />
                    <label className="checkboxLabel mr-4"> Dance </label>
                    <input
                      type="checkbox"
                      disabled={false}
                      name="yoga"
                      checked={editingCampaign.yoga}
                      onChange={handlechackboxesChange}
                      className="checkbox"
                    />
                    <label className="checkboxLabel mr-4"> Yoga </label>

                    <input
                      type="checkbox"
                      disabled={false}
                      name="gadgets"
                      checked={editingCampaign.gadgets}
                      onChange={handlechackboxesChange}
                      className="checkbox"
                    />
                    <label className="checkboxLabel mr-4"> Gadgets </label>

                    <input
                      type="checkbox"
                      disabled={false}
                      name="tech"
                      checked={editingCampaign.tech}
                      onChange={handlechackboxesChange}
                      className="checkbox"
                    />
                    <label className="checkboxLabel mr-4"> Tech </label>

                    <input
                      type="checkbox"
                      disabled={false}
                      name="cooking"
                      checked={editingCampaign.cooking}
                      onChange={handlechackboxesChange}
                      className="checkbox"
                    />
                    <label className="checkboxLabel mr-4"> Cooking </label>

                    <input
                      type="checkbox"
                      disabled={false}
                      name="workingClass"
                      checked={editingCampaign.workingClass}
                      onChange={handlechackboxesChange}
                      className="checkbox"
                    />
                    <label className="checkboxLabel mr-4">
                      {" "}
                      Working Class{" "}
                    </label>

                    <input
                      type="checkbox"
                      disabled={false}
                      name="smallEntrepreneurs"
                      checked={editingCampaign.smallEntrepreneurs}
                      onChange={handlechackboxesChange}
                      className="checkbox"
                    />
                    <label className="checkboxLabel mr-4">
                      Small Entrepreneurs{" "}
                    </label>

                    <input
                      type="checkbox"
                      disabled={false}
                      name="diy"
                      checked={editingCampaign.diy}
                      onChange={handlechackboxesChange}
                      className="checkbox"
                    />
                    <label className="checkboxLabel mr-4"> DIY</label>

                    <input
                      type="checkbox"
                      disabled={false}
                      name="electronics"
                      checked={editingCampaign.electronics}
                      onChange={handlechackboxesChange}
                      className="checkbox"
                    />
                    <label className="checkboxLabel mr-4"> Electronics </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="field">
              <label className="label">Product Link:</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  id="productLink"
                  value={editingCampaign.productLink}
                  onChange={(e) =>
                    setEditingCampaign({
                      ...editingCampaign,
                      productLink: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="field">
              <label className="label">
                {" "}
                For how many weeks are you planning to run the campaign?:
              </label>
              <div className="select">
                <select
                  value={editingCampaign.campainTime}
                  onChange={(e) =>
                    setEditingCampaign({
                      ...editingCampaign,
                      campainTime: e.target.value,
                    })
                  }
                >
                  <option value="">Select </option>
                  <option value="1">1 weeks</option>
                  <option value="2">2 weeks</option>
                  <option value="3">3 weeks</option>
                  <option value="4">4 weeks</option>
                  <option value="5">5 weeks</option>
                  <option value="6">6 weeks</option>
                  <option value="7">7 weeks</option>
                  <option value="8">8 weeks</option>
                  <option value="9">9 weeks</option>
                  <option value="10">10 weeks</option>
                  <option value="11">11 weeks</option>
                  <option value="12">12 weeks</option>
                  <option value="13">13 weeks</option>
                  <option value="14">14 weeks</option>
                  <option value="15">15 weeks</option>
                  <option value="16">16 weeks</option>
                  <option value="17">17 weeks</option>
                  <option value="18">18 weeks</option>
                  <option value="19">19 weeks</option>
                  <option value="20">20 weeks</option>
                  <option value="21">21 weeks</option>
                  <option value="22">22 weeks</option>
                  <option value="23">23 weeks</option>
                  <option value="24">24 weeks</option>
                  <option value="25">25 weeks</option>
                  <option value="26">26 weeks</option>
                  <option value="27">27 weeks</option>
                  <option value="28">28 weeks</option>
                  <option value="29">29 weeks</option>
                  <option value="30">30 weeks</option>
                  <option value="31">31 weeks</option>
                  <option value="32">32 weeks</option>
                  <option value="33">33 weeks</option>
                  <option value="34">34 weeks</option>
                  <option value="35">35 weeks</option>
                  <option value="36">36 weeks</option>
                  <option value="37">37 weeks</option>
                  <option value="38">38 weeks</option>
                  <option value="39">39 weeks</option>
                  <option value="40">40 weeks</option>
                  <option value="41">41 weeks</option>
                  <option value="42">42 weeks</option>
                  <option value="43">43 weeks</option>
                  <option value="44">44 weeks</option>
                  <option value="45">45 weeks</option>
                  <option value="46">46 weeks</option>
                  <option value="47">47 weeks</option>
                  <option value="48">48 weeks</option>
                  <option value="49">49 weeks</option>
                  <option value="50">50 weeks</option>
                  <option value="51">51 weeks</option>
                  <option value="52">52 weeks</option>
                </select>
              </div>
            </div>
            <div className="field">
              <label className="label">Campaign Name:</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  id="campaignName"
                  value={editingCampaign.campaignName}
                  onChange={(e) =>
                    setEditingCampaign({
                      ...editingCampaign,
                      campaignName: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="field">
              <label className="field">Cover Image For The Campaign:</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  id="uploadBrandLogo"
                  value={editingCampaign.uploadBrandLogo}
                  onChange={(e) =>
                    setEditingCampaign({
                      ...editingCampaign,
                      uploadBrandLogo: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div>
              <label className="label">Reupload Brand Photo:</label>
              <input
                type="file"
                id="uploadBrandLogo"
                name="uploadBrandLogo"
                onChange={handleImageChange}
              />
            </div>
            <div className="field">
              <label className="label"> Write location/city/state :</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  id="location1"
                  value={editingCampaign.location1}
                  onChange={(e) =>
                    setEditingCampaign({
                      ...editingCampaign,
                      location: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="field">
              <label className="label">
                Any other eligibility criteria to select Influencers?:
              </label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  id="selectionCriteria"
                  value={editingCampaign.selectionCriteria}
                  onChange={(e) =>
                    setEditingCampaign({
                      ...editingCampaign,
                      selectionCriteria: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Any message for us?:</label>
              <div className="control">
                <textarea
                  className="textarea"
                  type="text"
                  id="message"
                  value={editingCampaign.message}
                  onChange={(e) =>
                    setEditingCampaign({
                      ...editingCampaign,
                      message: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <label className="label">
              Who's Approvel Required For Influencer Selection
            </label>

            <div className="field has-text-left">
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="executive"
                  checked={isexecutive}
                  onChange={handleCheckboxChange}
                />
                Executive
              </label>
            </div>
            <div className="field has-text-left">
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="lead"
                  checked={islead}
                  onChange={handleCheckboxChange}
                />
                Lead
              </label>
            </div>
            <div className="field has-text-left">
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="admin"
                  checked={isadmin}
                  onChange={handleCheckboxChange}
                />
                Admin
              </label>
            </div>
            <div className="field has-text-left">
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="brand"
                  checked={isbrand}
                  onChange={handleCheckboxChange}
                />
                Brand
              </label>
            </div>

            <label className="label">
              Whose Approval Required For Influencer Content
            </label>
            <div className="field">
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="executiveContent"
                  checked={isexecutiveContent}
                  onChange={handleCheckboxChange1}
                />
                Executive
              </label>
            </div>
            <div className="field">
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="leadContent"
                  checked={isleadContent}
                  onChange={handleCheckboxChange1}
                />
                Lead
              </label>
            </div>
            <div className="field">
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="adminContent"
                  checked={isadminContent}
                  onChange={handleCheckboxChange1}
                />
                Admin
              </label>
            </div>

            <div className="field">
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="brandContent"
                  checked={isbrandContent}
                  onChange={handleCheckboxChange1}
                />
                Brand
              </label>
            </div>

            {/* Add similar input fields for other campaign attributes */}

            {/* Buttons for updating and canceling */}
            <div className="field is-grouped">
              <div className="control">
                <button onClick={handleUpdate}>Update</button>
              </div>
              <div className="control">
                <button onClick={handleCancelEdit}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewCampaign;
