import React, { useEffect, useMemo, useState } from "react";

const ComboChart = ({ data }) => {
  const [chartLoaded, setChartLoaded] = useState(false);

  const chartData = useMemo(
    () => [
      [
        "City",
        "Value",
        "Percentage",
        { role: "tooltip", type: "string", p: { html: true } },
      ],
      ...data.map((item) => [
        item.cityName,
        item.value,
        item.percentage,
        `<div>
        <strong>City:</strong> ${item.cityName}<br>
        <strong>Value:</strong> ${item.value}<br>
        <strong>Percentage:</strong> ${item.percentage}%
      </div>`,
      ]),
    ],
    [data]
  );

  const options = useMemo(
    () => ({
      title: "City Data",
      hAxis: {
        title: "City",
        slantedText: true,
        slantedTextAngle: 45,
      },
      vAxis: {
        title: "Value",
      },
      seriesType: "bars", // Default chart type for the series
      series: {
        1: { type: "line" }, // Second series as line chart
      },
      legend: { position: "none" }, // Hide the legend if not needed
      tooltip: {
        isHtml: true, // Enable HTML tooltips
      },
      colors: ["#1b9e77", "#d95f02"], // Customize the colors for different series
    }),
    []
  );

  useEffect(() => {
    // Load Google Charts library dynamically if not already loaded
    if (!window.google || !window.google.charts) {
      const script = document.createElement("script");
      script.src = "https://www.gstatic.com/charts/loader.js";
      script.onload = () => {
        window.google.charts.load("current", { packages: ["corechart"] });
        window.google.charts.setOnLoadCallback(() => {
          setChartLoaded(true);
        });
      };
      document.head.appendChild(script);
    } else {
      setChartLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (chartLoaded) {
      // Initialize and draw the chart once the library is loaded
      const chart = new window.google.visualization.ComboChart(
        document.getElementById("chart_div")
      );
      chart.draw(
        window.google.visualization.arrayToDataTable(chartData),
        options
      );
    }
  }, [chartLoaded, chartData, options]);

  return <div id="chart_div" style={{ width: "100%", height: "400px" }}></div>;
};

export default ComboChart;
