import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const AgeRangeLineChart = ({ data }) => {
  // Ensure data is defined and has the correct structure before processing it
  const chartData = data?.length
    ? data.flatMap((demographic) =>
        demographic.totalValue.map((item, index) => ({
          ageRange:
            ["13-17", "18-24", "25-34", "35-44", "45-54", "55-64", "65+"][
              index
            ] || "Unknown",
          value: item.value,
          percentage: item.percentage,
        }))
      )
    : [];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const { value, percentage } = payload[0].payload;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            padding: "10px",
          }}
        >
          <p className="label">{`Age Range: ${label}`}</p>
          <p className="intro">{`Value: ${value}`}</p>
          <p className="intro">{`Percentage: ${percentage}%`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="ageRange" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Line
          type="monotone"
          dataKey="value"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default AgeRangeLineChart;
