import { Config } from "../.env.js";
// admin signup
export const adminsignup = async ({ username, email, password } = {}) => {
  const user = { username, email, password };

  try {
    const res = await fetch(`${Config.API_URL}/admin-sign-up`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });
    return await res.json();
  } catch (err) {
    throw new Error(`cannot register at this time . ${err}`);
  }
};

// admin signin
export const adminsignin = async ({ username, email, password } = {}) => {
  const user = { username, email, password };

  try {
    const res = await fetch(`${Config.API_URL}/admin-sign-in`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });
    return await res.json();
  } catch (err) {
    throw new Error(`cannot login at this time . ${err}`);
  }
};
// logout
export const logout = async () => {
  try {
    const res = await fetch(`${Config.API_URL}/logout`, {
      method: "GET",
      credentials: "include",
    });
    return await res.json();
  } catch (err) {
    console.log(err);
  }
};

// lead signup
export const Leadsignup = async ({ username, email, password } = {}) => {
  const user = { username, email, password };

  try {
    const res = await fetch(`${Config.API_URL}/lead-sign-up`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });
    return await res.json();
  } catch (err) {
    throw new Error(`cannot register at this time . ${err}`);
  }
};

// lead signin
export const Laedsignin = async ({ username, email, password } = {}) => {
  const user = { username, email, password };

  try {
    const res = await fetch(`${Config.API_URL}/lead-sign-in`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });
    return await res.json();
  } catch (err) {
    throw new Error(`cannot login at this time . ${err}`);
  }
};

// executive signup
export const Executivesignup = async ({ username, email, password } = {}) => {
  const user = { username, email, password };

  try {
    const res = await fetch(`${Config.API_URL}/executive-sign-up`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });
    return await res.json();
  } catch (err) {
    throw new Error(`cannot register at this time . ${err}`);
  }
};

// executive signin

export const Executivesignin = async ({ username, email, password } = {}) => {
  const user = { username, email, password };

  try {
    const res = await fetch(`${Config.API_URL}/executive-sign-in`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });
    return await res.json();
  } catch (err) {
    throw new Error(`cannot login at this time . ${err}`);
  }
};
