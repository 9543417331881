import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Config } from "../.env.js";
import axios from "axios";
import logo from "../logo192.png"; // Update logo path as needed
import CampaignProgress from "./campaignProgress.js";
import CombinedRange from "../components/CombinedRange.js";

const CampaignLiveAdmin = ({ campaign, role }) => {
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  // console.log("Role:", role);

  // useEffect(() => {
  //   const fetchProduct = async () => {
  //     try {
  //       const response = await axios.get(`${Config.API_URL}/api/product`, {
  //         params: { campaignName: campaign.campaignName }, // Send campaignName as parameter
  //       });
  //       setProduct(response.data); // Update state with fetched data
  //     } catch (error) {
  //       console.error("Error fetching product:", error);
  //     }
  //   };

  //   fetchProduct();
  // }, [campaign.campaignName]);
  const title = {
    title: "Subtitle",
  };
  // const brandName = {
  //     brandName: "BrandName"
  // }

  // const followersCriteria = {
  //     followersCriteria: "followersCriteria"
  // }
  // const deliverables = {
  //     deliverables: "deliverables"
  // }
  // const GiveProductsWorth = {
  //     GiveProductWorth: "Give Products Worth"
  // }
  // const dealtype = {
  //     dealtype: "deal type"
  // }
  // Styles for the background card
  const topImageStyle = {
    position: "relative",
    backgroundImage: `url(${Config.API_URL}/uploads/${encodeURIComponent(
      campaign.uploadBrandLogo
    )})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "180px", // Adjust height as needed
    maxWidth: "465px", // Full width to match the button card below
    width: "100%",
    borderRadius: "6px",
    // marginBottom: "-10px",
    // marginLeft: "-15px",
    // marginTop: "-15px",
  };
  const logoStyle = {
    position: "absolute",
    bottom: "5px",
    left: "5px",
    width: "46px",
    height: "46px",
    // top: "160px",
  };
  const {
    brandName,
    followersCriteria,
    infDeliverables,
    cashValue,
    campaignName,
    dealtype,
    uploadBrandLogo,
    approve,
    rejected,
  } = campaign;

  return (
    <div className="container" style={{ position: "relative" }}>
      <div className="card-content">
        <div
          className="box mt-2"
          style={{
            maxWidth: 475,
            width: "100%",
            // height: 625,
            height: "auto",
            borderRadius: "10px",
            paddingBottom: "5px",
          }}
        >
          <div style={topImageStyle}>
            <img src={logo} alt="Logo" style={logoStyle} />
          </div>
          <br />
          <div className="card-title-section">
            <p className="card-title-label">Title</p>
            <h1 className="subtitle card-title">
              <b>{campaignName}</b>
            </h1>
          </div>
          <div>
            {/* Brand Name and Followers Criteria Section */}
            <div
              style={{
                // display: "flex",
                // justifyContent: "space-between",
                marginTop: "10px",
                backgroundColor: "#FBFBFB",
                padding: "19px 11px",
                // marginLeft: "-15px",
                // marginRight: "-15px",
                border: "1px solid #F1F1F1",
                borderRadius: "3px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  // gap: "5px",
                  // marginTop: "30px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "25%",
                  }}
                >
                  <span className="icon">
                    {/* <i className="fas fa-tag"></i> */}
                    <img src="/images/BrandName.svg" alt="icon"></img>
                  </span>
                  <p
                    className="card-detail-label"
                    style={{ marginRight: "10px" }}
                  >
                    Brand Name
                  </p>
                  <p className="card-detail">{brandName}</p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "34%",
                  }}
                >
                  <span className="icon" style={{ marginRight: "125px" }}>
                    {/* Icon for followers */}
                    {/* <i className="fas fa-user-friends"></i>{" "} */}
                    <img src="/images/FollowersCriteria.svg" alt="icon"></img>
                  </span>
                  {/* <p className="card-detail-label">Followers Criteria</p>
                  <p className="card-detail">
                    {followersCriteria_combinedRange}
                  </p> */}
                  <CombinedRange followersCriteria={followersCriteria} />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "26%",
                  }}
                >
                  <span className="icon" style={{ marginRight: "125px" }}>
                    {/* <i className="fas fa-user-friends"></i>{" "} */}
                    {/* Icon for followers */}
                    <img src="/images/ph_handshake-thin.png" alt="icon"></img>
                  </span>
                  <p className="card-detail-label">Deal Type</p>
                  <p className="card-detail">{dealtype}</p>
                </div>
              </div>

              {/* deliverables and Give Products Worth Section */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "30px",
                  gap: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "45%",
                  }}
                >
                  <span className="icon">
                    {/* <i className="fas fa-tag"></i> */}
                    <img src="/images/Deliverables.svg" alt="icon"></img>
                  </span>
                  <p
                    className="card-detail-label"
                    style={{ marginRight: "10px" }}
                  >
                    Deliverables
                  </p>
                  <p className="card-detail">{infDeliverables}</p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "45%",
                  }}
                >
                  <span className="icon" style={{ marginRight: "130px" }}>
                    {/* <i className="fas fa-user-friends"></i>{" "} */}
                    {/* Icon for followers */}
                    <img src="/images/GiveProductsWorth.svg" alt="icon"></img>
                  </span>
                  <p
                    className="card-detail-label"
                    style={{ marginRight: "10px" }}
                  >
                    Give Products Worth
                  </p>
                  <p className="card-detail">{cashValue}</p>
                </div>
              </div>
            </div>
            {/* <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "30px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <span className="icon">
                  <i className="fas fa-tag"></i>
                </span>
                <p
                  className="has-text-grey-light"
                  style={{ marginRight: "10px" }}
                >
                  Deal Type
                </p>
                <p className="has-text-weight-bold">{dealtype}</p>
              </div>
            </div> */}

            {/* Dynamic Status */}
            <div
              style={{
                // display: "flex",
                // justifyContent: "space-between",
                marginTop: "5px",
                backgroundColor: "#FBFBFB",
                // padding: "3px 15px",
                // marginLeft: "-15px",
                // marginRight: "-15px",
                border: "1px solid #F1F1F1",
                borderRadius: "3px",
              }}
            >
              <CampaignProgress campaign={campaign} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                // gap: "6px",
                marginTop: "10px",
                // marginLeft: "-15px",
                // marginRight: "-15px",
              }}
            >
              <button
                onClick={() =>
                  navigate(`/view-campaignBrand/${campaign.campaignName}`)
                }
                className="button is-small-custom card-button"
                // style={{ width: "210px" }}
                // style={{
                //   width: "49%",
                //   border: "0.5px solid #3E8CE8",
                //   borderRadius: "0px",
                //   color: "#3E8CE8",
                //   fontFamily: "Arial",
                //   fontSize: "20px",
                //   fontStyle: "normal",
                //   fontWeight: "400",
                //   lineHeight: "normal",
                // }}
              >
                View Campaign
              </button>
              <button
                onClick={() =>
                  navigate(`/tbladmin/${campaign.campaignName}`, {
                    state: { role },
                  })
                }
                className="button is-small-custom card-button"
                // style={{ width: "210px" }}
                // style={{
                //   width: "49%",
                //   border: "0.5px solid #3E8CE8",
                //   borderRadius: "0px",
                //   color: "#3E8CE8",
                //   fontFamily: "Arial",
                //   fontSize: "20px",
                //   fontStyle: "normal",
                //   fontWeight: "400",
                //   lineHeight: "normal",
                // }}
              >
                View More
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignLiveAdmin;
