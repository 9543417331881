import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { createCampaign } from "../components/campaignApi";
import { Config } from "../.env.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
const BrandPage = ({ brandName, onBrandFormSubmit }) => {
  const navigate = useNavigate();

  // const  [brandName, setbrandName] = useState('');
  const location = useLocation();

  // const { brandName } = location.state;
  // const { brandName } = location?.state || "";
  // const brandName = location.state?.brandName || "";

  // const brandName = location.state ? location.state.brandName : null;
  // const { brandName } = location.state ? location.state.brandName : [];
  // console.log("BrandName", brandName);

  // console.log("Brand Names in GST Page:", brandName);

  const [cashValue, setCashValue] = useState("");
  const [influencer, setInfluencer] = useState("");
  const [infDeliverables, setInfDeliverables] = useState("");
  const [ageGroup, setAgeGroup] = useState("");
  const [travel, setTravel] = useState(false);
  const [productLink, setProductLink] = useState("");
  const [campainTime, setCampainTime] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [isSpecificLocation, setIsSpecificLocation] = useState(false);
  const [location1, setLocation1] = useState("");
  const [selectionCriteria, setSelectionCriteria] = useState("");
  const [message, setMessage] = useState("");
  const [lifeStyle, setLifeStyle] = useState(false);
  const [cosmetics, setCosmetics] = useState(false);
  const [skincare, setSkincare] = useState(false);
  const [Delivrables, setDelivrables] = useState(false);
  const [other, setOther] = useState(false);
  const [CashMoney, setCashMoney] = useState(false);
  const [male, setMale] = useState(false);
  const [female, setfemale] = useState(false);
  const [transgender, setTransgender] = useState(false);
  const [HealthFitness, setHealthFitness] = useState(false);
  const [fashion, setFashion] = useState(false);
  const [food, setFood] = useState(false);
  const [beauty, setBeauty] = useState(false);
  const [haircare, setHaircare] = useState(false);
  const [products, setProducts] = useState(false);

  const [student, setStudent] = useState(false);
  const [diy, setDiy] = useState(false);
  const [electronics, setElectronics] = useState(false);
  const [smallEntrepreneurs, setSmallEntrepreneurs] = useState(false);
  const [gadgets, setGadgets] = useState(false);
  const [workingClass, setWorkingClass] = useState(false);
  const [tech, setTech] = useState(false);
  const [gaming, setGaming] = useState(false);
  const [houseWife, setHouseWife] = useState(false);
  const [yoga, setYoga] = useState(false);
  const [dance, setDance] = useState(false);
  const [cooking, setCooking] = useState(false);

  const [uploadBrandLogo, setUploadBrandLogo] = useState(null);
  const [uploadBrief, setUploadBrief] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [pdfFileName, setPdfFileName] = useState("");
  // const [followersCriteria, setFollowersCriteria] = useState([]);
  const [followersCriteria, setFollowersCriteria] = useState("");
  const [SocialmediaPlatform, setSocialmediaPlatform] = useState("");
  // const [isSpecificLocation, setIsSpecificLocation] = useState('');
  // const [pdfUrl, setPdfUrl] = useState('');
  // const examplePdfUrl = 'example.pdf';
  //const [location, setLocation] = useState('');

  const [uploadFileName, setUploadFileName] = useState("");

  const fetchFilteredBrandCampaigns = async (filters) => {
    try {
      const response = await fetch(
        `${Config.API_URL}/filtered-brand-campaigns`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(filters),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch filtered brand campaigns");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching filtered brand campaigns:", error);
      // Handle error (e.g., show error message to the user)
    }
  };

  const [isadmin, setisAdmin] = useState(false);
  const [islead, setisLead] = useState(false);
  const [isexecutive, setisExecutive] = useState(false);
  const [isbrand, setisBrand] = useState(false);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);

  const [isadminContent, setisAdminContent] = useState(false);
  const [isleadContent, setisLeadContent] = useState(false);
  const [isexecutiveContent, setisExecutiveContent] = useState(false);
  const [isbrandContent, setisBrandContent] = useState(false);
  // const [rejected, setRejected] = useState(false);
  // const [approve, setapprove] = useState(false);

  const examplePdfUrl =
    "https://docs.google.com/presentation/d/1Yf2C23GdKI1w4XxiM8MdxNGk7EBWB6LOAHjGitV_IOQ/edit#slide=id.gf9808550a8_0_6";

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Required field validation for checkboxes
    if (!products && !CashMoney && !other) {
      toast.warning("Please select an item to give to Influencers.");
      return;
    }
    if (!male && !female && !transgender) {
      toast.warning("Please select target influencers' gender.");
      return;
    }
    if (!followersCriteria) {
      toast.warning("Please select followers criteria.");
      return;
    }
    if (
      !skincare &&
      !cosmetics &&
      !lifeStyle &&
      !haircare &&
      !beauty &&
      !food &&
      !fashion &&
      !HealthFitness &&
      !travel &&
      !student &&
      !houseWife &&
      !gaming &&
      !dance &&
      !yoga &&
      !gadgets &&
      !tech &&
      !cooking &&
      !workingClass &&
      !smallEntrepreneurs &&
      !diy &&
      !electronics
    ) {
      toast.warning("Please select influencers categories you want to target.");
      return;
    }
    // if (!isexecutive && !islead && !isadmin && !isbrand) {
    //   toast.warning(
    //     "Please select whose approvel is required for Influencer Selection."
    //   );
    //   return;
    // }
    // if (
    //   !isexecutiveContent &&
    //   !isleadContent &&
    //   !isadminContent &&
    //   !isbrandContent
    // ) {
    //   toast.warning(
    //     "Please select whose approvel is required for Influencer Content."
    //   );
    //   return;
    // }
    try {
      const { brandName } = location.state;
      const bodyData = new FormData();
      const filters = {
        executive: isexecutive,
        lead: islead,
        admin: isadmin,
        brand: isbrand,
      };
      const campaigns = await fetchFilteredBrandCampaigns(filters);
      setFilteredCampaigns(campaigns);

      const formData = {
        brandName: selectedOption,
        followersCriteria,
        influencer,
        infDeliverables,
        CashMoney,
        other,
        products,
        cashValue,
        transgender,
        female,
        male,
        ageGroup,
        skincare,
        cosmetics,
        lifeStyle,
        haircare,
        beauty,
        food,
        fashion,
        travel,
        cooking,
        tech,
        gadgets,
        diy,
        houseWife,
        dance,
        yoga,
        gaming,
        student,
        electronics,
        smallEntrepreneurs,
        workingClass,
        isSpecificLocation,
        HealthFitness,
        productLink,
        campainTime,
        campaignName,
        location1,
        selectionCriteria,
        message,
        SocialmediaPlatform,
        // Include other form data here
      };

      bodyData.append("isExecutive", isexecutive);
      bodyData.append("isLead", islead);
      bodyData.append("isAdmin", isadmin);
      bodyData.append("isBrand", isbrand);
      bodyData.append("isexecutiveContent", isexecutiveContent);
      bodyData.append("isleadContent", isleadContent);
      bodyData.append("isadminContent", isadminContent);
      bodyData.append("isbrandContent", isbrandContent);
      // bodyData.append('rejected', rejected);
      // bodyData.append("approve",approve);

      for (const key in formData) {
        bodyData.append(key, formData[key]);
      }

      if (uploadBrandLogo) {
        // Assuming uploadBrandPhoto is a string (file path or URL)
        bodyData.append("uploadBrandLogo", uploadBrandLogo);
      }
      if (uploadBrief) {
        bodyData.append("uploadBrief", uploadBrief);
      }

      const response = await fetch(`${Config.API_URL}/submitForm1`, {
        method: "POST",

        body: bodyData,
      });

      if (response.ok) {
        // Form submitted successfully
        // console.log("Form submitted successfully");
        toast.success("Form submitted successfully");
        window.location.reload();
      }

      if (!response.ok) {
        throw new Error("Failed to submit form");
      }

      // const data = await response.json();
      // console.log(data);
      // Handle successful form submission
      // } else {
      //     // If "Executive" checkbox is not checked, log a message
      //     console.log(' checkbox is not checked. Form not submitted.');
      //     toast.warning(" checkbox is not checked. Form not submitted.");
      // }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

    if (file && fileTypes.includes(file.type)) {
      if (e.target.name === "uploadBrandLogo") {
        setUploadBrandLogo(file);
        setUploadFileName(file.name);
      }
    } else {
      toast.error("Only JPG and JPEG files are allowed");
      e.target.value = null; // Reset the file input
    }
  };
  const handleLocationChange = (e) => {
    setLocation1(e.target.value);
  };

  const handleSpecificLocationChange = (e) => {
    setIsSpecificLocation(e.target.value);
  };

  // const handlePdfChange = (e) => {
  //     // Update the state with the selected file
  //     setUploadBrief(e.target.files[0]);
  // };
  // const filterBrandNames = (brandNames) => {
  //   return brandNames.filter((name) => name && name.trim() !== "");
  // };

  // const filteredBrandNames = filterBrandNames(brandName);
  const filterBrandNames = (brandNames = []) => {
    // Ensure brandNames is an array
    return brandNames.filter((name) => name && name.trim() !== "");
  };

  // Example: Use a fallback for brandName in case it's undefined
  const filteredBrandNames = filterBrandNames(
    Array.isArray(brandName) ? brandName : []
  );

  console.log("Brand Names", filteredBrandNames);
  console.log("Selected:", selectedOption);

  const handlePdfChange = (event) => {
    const file = event.target.files[0];
    const maxSizeInMB = 25;
    const fileTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    if (file) {
      if (!fileTypes.includes(file.type)) {
        toast.error("Please select a valid PDF, DOC, or DOCX file.");
        event.target.value = null;
        return;
      }

      if (file.size / 1024 / 1024 > maxSizeInMB) {
        toast.error("File size exceeds 25 MB.");
        event.target.value = null;
        return;
      }

      setPdfFileName(file.name); // Set the file name

      const reader = new FileReader();
      reader.onload = () => {
        setPdfUrl(reader.result);
      };
      reader.readAsDataURL(file);

      // Set the selected file in component state
      setUploadBrief(file);
    } else {
      toast.error("Please select a valid file.");
    }
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFollowersCriteria((prev) => [...prev, value]);
    } else {
      setFollowersCriteria((prev) =>
        prev.filter((criteria) => criteria !== value)
      );
    }
  };

  return (
    <div className="container has-text-centered mt-3">
      <h1 className="title mt-5 ">Create New Campaign</h1>
      {/* <strong>Campaign Details Form for Hobo.Video Platform<br />Details to kickstart the campaign</strong> */}
      <form onSubmit={handleSubmit}>
        <div className="my-2 has-text-left has-text-weight-bold">
          <label>Brand Name *</label>
        </div>
        <div className="field">
          <div className="control" style={{ width: "100%" }}>
            <div
              className="select is-rounded "
              style={{ marginRight: "31rem" }}
            >
              <select
                value={selectedOption}
                onChange={handleOptionChange}
                style={{ width: "900px" }}
                required
              >
                <option className=" mr-5" value="">
                  Select Brand Name
                </option>
                {brandName.filter(Boolean).map((name, index) => (
                  <option key={index} value={name}>
                    {name}
                  </option>
                ))}
                {/* {filteredBrandNames.map((name, index) => (
                  <option key={index} value={name}>
                    {name}
                  </option>
                ))} */}
              </select>
            </div>
          </div>
        </div>
        <div className="has-text-left  my-1 has-text-weight-bold">
          <label>
            How Many Barter Influencers are You Looking Forward to Work With? *
          </label>
        </div>
        <div className="field">
          <div className="control">
            <input
              className="input is-rounded"
              type="text"
              placeholder="Your Answer"
              value={influencer}
              onChange={(e) => setInfluencer(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="field">
          <div className="control">
            <div className="colomn">
              <div className="has-text-left my-1 has-text-weight-bold">
                <label>
                  {" "}
                  Please Specify the Exact Deliverables You want from
                  Influencers *
                </label>
              </div>
              <div className="control">
                <div className="colomn">
                  <input
                    className="input is-rounded"
                    type="text"
                    placeholder="e.g.-1 Reel + 1 Story"
                    value={infDeliverables}
                    onChange={(e) => setInfDeliverables(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="field has-text-left">
          <label className="label has-text-left ">
            What would You be Giving to Influencers in Exchange for
            Deliverables? *
          </label>

          <div className="checkboxContainer ">
            <input
              id="products"
              type="checkbox"
              checked={products}
              onChange={(e) => setProducts(e.target.checked)}
              className="checkbox mr-1"
            />
            <label htmlFor="products" className="checkboxLabel mr-6">
              Products
            </label>
            <input
              id="CashMoney"
              type="checkbox"
              checked={CashMoney}
              onChange={(e) => setCashMoney(e.target.checked)}
              className="checkbox mr-1"
            />
            <label htmlFor="CashMoney" className="checkboxLabel mr-6">
              Cash/Money
            </label>
            <input
              id="other"
              type="checkbox"
              checked={other}
              onChange={(e) => setOther(e.target.checked)}
              className="checkbox mr-1"
            />
            <label htmlFor="other" className="checkboxLabel mr-6">
              Other
            </label>
          </div>
        </div>
        <div className="field">
          <div className="additionalQuestionContainer ">
            <label className="additionalQuestionLabel label has-text-left">
              Value (in Rs.) of the cash/products being Given to Influencers? *
            </label>
            <input
              type="text"
              className="input is-rounded"
              placeholder="e.g. Rs. 1000 worth products + Rs. 500 Cash"
              // placeholderTextColor="#999"
              value={cashValue}
              onChange={(e) => setCashValue(e.target.value)}
              required
            />
          </div>
          <div className="field">
            <div className="control has-text-left">
              <div className="colomn">
                <div className="my-2 has-text-left">
                  <strong>Target Influencers' Gender *</strong>
                </div>
                <div className="checkboxContainer style={{ display: 'flex', flexDirection: 'coloumn', alignItems: 'center' }}">
                  <input
                    id="male"
                    type="checkbox"
                    disabled={false}
                    checked={male}
                    onChange={(e) => setMale(e.target.checked)}
                    className="checkbox mr-1"
                  />
                  <label htmlFor="male" className="checkboxLabel mr-4">
                    Male
                  </label>

                  <input
                    id="female"
                    type="checkbox"
                    disabled={false}
                    checked={female}
                    onChange={(e) => setfemale(e.target.checked)}
                    className="checkbox mr-1"
                  />
                  <label htmlFor="female" className="checkboxLabel mr-4">
                    Female
                  </label>

                  <input
                    id="transgender"
                    type="checkbox"
                    disabled={false}
                    checked={transgender}
                    onChange={(e) => setTransgender(e.target.checked)}
                    className="checkbox mr-1"
                  />
                  <label htmlFor="transgender" className="checkboxLabel">
                    Transgender
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="field">
          <label className="label my-2 has-text-left">
            Followers Criteria *
          </label>
          <div className="control has-text-left">
            <div className="checkbox-group">
              <label className="checkbox mr-4 ">
                <input
                  className="mr-1"
                  type="checkbox"
                  value="1-2000"
                  checked={followersCriteria.includes("1-2000")}
                  onChange={handleCheckboxChange}
                />
                Less then 2K
              </label>
              <label className="checkbox mr-4">
                <input
                  className="mr-1"
                  type="checkbox"
                  value="2000-10000"
                  checked={followersCriteria.includes("2000-10000")}
                  onChange={handleCheckboxChange}
                />
                2k-10k
              </label>
              <label className="checkbox mr-4">
                <input
                  className="mr-1"
                  type="checkbox"
                  value="10000-50000"
                  checked={followersCriteria.includes("10000-50000")}
                  onChange={handleCheckboxChange}
                />
                10k-50k
              </label>
              <label className="checkbox">
                <input
                  className="mr-1"
                  type="checkbox"
                  value="50000-100000"
                  checked={followersCriteria.includes("50000-100000")}
                  onChange={handleCheckboxChange}
                />
                50k-100k
              </label>
              {/* Add more checkboxes as needed */}
            </div>
          </div>
        </div>

        <div className="field ">
          <label className="label has-text-left">
            {" "}
            What is Your Target Audience Age Group? *
            <br />
          </label>
          <div className="control  ">
            <div className="colomn">
              <input
                className="input  is-rounded "
                type="text"
                placeholder="E.g. Loreal has target audience age group of 20-45 years *"
                value={ageGroup}
                onChange={(e) => setAgeGroup(e.target.value)}
                required
              />
            </div>
          </div>
        </div>
        <div className="field">
          <div className="control">
            <div className="colomn">
              <div className="my-2 has-text-left">
                <strong>Influencers Categories You Want to Target *</strong>
              </div>
              <div
                className="checkboxContainer"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                  maxWidth: "100%",
                }}
              >
                <div
                  style={{
                    flex: "1 1 calc(14.28% - 10px)",
                    marginBottom: "5px",
                  }}
                >
                  <input
                    id="skincare"
                    type="checkbox"
                    disabled={false}
                    checked={skincare}
                    onChange={(e) => setSkincare(e.target.checked)}
                    className="checkbox"
                  />
                  <label htmlFor="skincare" className="checkboxLabel mr-2">
                    {" "}
                    Skincare{" "}
                  </label>
                </div>
                {/* <div style={{ flex: '1 1 calc(33.33% - 10px)', marginBottom: '10px' }}> */}
                <input
                  id="cosmetics"
                  type="checkbox"
                  disabled={false}
                  checked={cosmetics}
                  onChange={(e) => setCosmetics(e.target.checked)}
                  className="checkbox"
                />
                <label htmlFor="cosmetics" className="checkboxLabel mr-2">
                  {" "}
                  Cosmetics{" "}
                </label>
                {/* </div> */}
                {/* <div style={{ flex: '1 1 calc(33.33% - 10px)', marginBottom: '10px' }}> */}
                <input
                  id="lifeStyle"
                  type="checkbox"
                  disabled={false}
                  checked={lifeStyle}
                  onChange={(e) => setLifeStyle(e.target.checked)}
                  className="checkbox"
                />
                <label htmlFor="lifeStyle" className="checkboxLabel mr-2">
                  {" "}
                  Lifestyle{" "}
                </label>
                {/* </div> */}
                {/* <div style={{ flex: '1 1 calc(33.33% - 10px)', marginBottom: '10px' }}> */}
                <input
                  id="haircare"
                  type="checkbox"
                  disabled={false}
                  checked={haircare}
                  onChange={(e) => setHaircare(e.target.checked)}
                  className="checkbox"
                />
                <label htmlFor="haircare" className="checkboxLabel mr-2">
                  {" "}
                  Haircare{" "}
                </label>
                {/* </div> */}
                {/* <div style={{ flex: '1 1 calc(33.33% - 10px)', marginBottom: '10px' }}> */}
                <input
                  id="beauty"
                  type="checkbox"
                  disabled={false}
                  checked={beauty}
                  onChange={(e) => setBeauty(e.target.checked)}
                  className="checkbox"
                />
                <label htmlFor="beauty" className="checkboxLabel mr-2">
                  {" "}
                  Beauty{" "}
                </label>
                {/* </div> */}
                {/* <div style={{ flex: '1 1 calc(33.33% - 10px)', marginBottom: '10px' }}> */}
                <input
                  id="food"
                  type="checkbox"
                  disabled={false}
                  checked={food}
                  onChange={(e) => setFood(e.target.checked)}
                  className="checkbox"
                />
                <label htmlFor="food" className="checkboxLabel mr-2">
                  {" "}
                  Food{" "}
                </label>
                {/* </div> */}
                {/* <div style={{ flex: '1 1 calc(33.33% - 10px)', marginBottom: '10px' }}> */}
                <input
                  id="fashion"
                  type="checkbox"
                  disabled={false}
                  checked={fashion}
                  onChange={(e) => setFashion(e.target.checked)}
                  className="checkbox"
                />
                <label htmlFor="fashion" className="checkboxLabel mr-2">
                  {" "}
                  Fashion{" "}
                </label>
                {/* </div> */}
                {/* /<div style={{ flex: '1 1 calc(33.33% - 10px)', marginBottom: '10px' }}> */}
                <input
                  id="HealthFitness"
                  type="checkbox"
                  disabled={false}
                  checked={HealthFitness}
                  onChange={(e) => setHealthFitness(e.target.checked)}
                  className="checkbox"
                />
                <label htmlFor="HealthFitness" className="checkboxLabel mr-2">
                  {" "}
                  Health & Fitness{" "}
                </label>
                {/* </div> */}
                {/* <div style={{ flex: '1 1 calc(33.33% - 10px)', marginBottom: '10px' }}> */}
                <input
                  id="travel"
                  type="checkbox"
                  disabled={false}
                  checked={travel}
                  onChange={(e) => setTravel(e.target.checked)}
                  className="checkbox"
                />
                <label htmlFor="travel" className="checkboxLabel mr-2">
                  {" "}
                  Travel{" "}
                </label>
                {/* </div> */}
                {/* <div style={{ flex: '1 1 calc(33.33% - 10px)', marginBottom: '10px' }}> */}
                <input
                  id="student"
                  type="checkbox"
                  disabled={false}
                  checked={student}
                  onChange={(e) => setStudent(e.target.checked)}
                  className="checkbox"
                />
                <label htmlFor="student" className="checkboxLabel mr-2">
                  {" "}
                  Student{" "}
                </label>
                {/* </div> */}
                {/* <div style={{ flex: '1 1 calc(33.33% - 10px)', marginBottom: '10px' }}> */}
                <input
                  id="houseWife"
                  type="checkbox"
                  disabled={false}
                  checked={houseWife}
                  onChange={(e) => setHouseWife(e.target.checked)}
                  className="checkbox"
                />
                <label htmlFor="houseWife" className="checkboxLabel mr-2">
                  {" "}
                  Housewife{" "}
                </label>
                {/* </div> */}
                {/* <div style={{ flex: '1 1 calc(33.33% - 10px)', marginBottom: '10px' }}> */}
                <input
                  id="gaming"
                  type="checkbox"
                  disabled={false}
                  checked={gaming}
                  onChange={(e) => setGaming(e.target.checked)}
                  className="checkbox"
                />
                <label htmlFor="gaming" className="checkboxLabel mr-2">
                  {" "}
                  Gaming{" "}
                </label>
                {/* </div> */}
                {/* <div style={{ flex: '1 1 calc(33.33% - 10px)', marginBottom: '10px' }}> */}
                <input
                  id="dance"
                  type="checkbox"
                  disabled={false}
                  checked={dance}
                  onChange={(e) => setDance(e.target.checked)}
                  className="checkbox"
                />
                <label htmlFor="dance" className="checkboxLabel mr-2">
                  {" "}
                  Dance{" "}
                </label>
                {/* </div> */}
                {/* <div style={{ flex: '1 1 calc(33.33% - 10px)', marginBottom: '10px' }}> */}
                <input
                  id="yoga"
                  type="checkbox"
                  disabled={false}
                  checked={yoga}
                  onChange={(e) => setYoga(e.target.checked)}
                  className="checkbox"
                />
                <label htmlFor="yoga" className="checkboxLabel mr-2">
                  {" "}
                  Yoga{" "}
                </label>
                {/* </div> */}
                {/* <div style={{ flex: '1 1 calc(33.33% - 10px)', marginBottom: '10px' }}> */}
                <input
                  id="gadgets"
                  type="checkbox"
                  disabled={false}
                  checked={gadgets}
                  onChange={(e) => setGadgets(e.target.checked)}
                  className="checkbox"
                />
                <label htmlFor="gadgets" className="checkboxLabel mr-2">
                  {" "}
                  Gadgets{" "}
                </label>
                {/* </div> */}
                {/* <div style={{ flex: '1 1 calc(33.33% - 10px)', marginBottom: '10px' }}> */}
                <input
                  id="tech"
                  type="checkbox"
                  disabled={false}
                  checked={tech}
                  onChange={(e) => setTech(e.target.checked)}
                  className="checkbox"
                />
                <label htmlFor="tech" className="checkboxLabel mr-2">
                  {" "}
                  Tech{" "}
                </label>
                {/* </div>
                                <div style={{ flex: '1 1 calc(33.33% - 10px)', marginBottom: '10px' }}> */}
                <input
                  id="cooking"
                  type="checkbox"
                  disabled={false}
                  checked={cooking}
                  onChange={(e) => setCooking(e.target.checked)}
                  className="checkbox"
                />
                <label htmlFor="cooking" className="checkboxLabel mr-2">
                  {" "}
                  Cooking{" "}
                </label>
                {/* </div>
                                <div style={{ flex: '1 1 calc(33.33% - 10px)', marginBottom: '10px' }}> */}
                <input
                  id="workingClass"
                  type="checkbox"
                  disabled={false}
                  checked={workingClass}
                  onChange={(e) => setWorkingClass(e.target.checked)}
                  className="checkbox"
                />
                <label htmlFor="workingClass" className="checkboxLabel mr-2">
                  {" "}
                  Working Class{" "}
                </label>
                {/* </div>
                                <div style={{ flex: '1 1 calc(14.28% - 10px)', marginBottom: '10px' }}> */}
                <input
                  id="smallEntrepreneurs"
                  type="checkbox"
                  disabled={false}
                  checked={smallEntrepreneurs}
                  onChange={(e) => setSmallEntrepreneurs(e.target.checked)}
                  className="checkbox"
                />
                <label
                  htmlFor="smallEntrepreneurs"
                  className="checkboxLabel mr-2"
                >
                  {" "}
                  Small Entrepreneurs{" "}
                </label>
                {/* </div>
                                <div style={{ flex: '1 1 calc(14.28% - 10px)', marginBottom: '10px' }}> */}
                <input
                  id="diy"
                  type="checkbox"
                  disabled={false}
                  checked={diy}
                  onChange={(e) => setDiy(e.target.checked)}
                  className="checkbox"
                />
                <label htmlFor="diy" className="checkboxLabel mr-2">
                  {" "}
                  DIY{" "}
                </label>
                {/* </div> */}
                {/* <div style={{ flex: '1 1 calc(14.28% - 10px)', marginBottom: '10px' }}> */}
                <input
                  id="electronics"
                  type="checkbox"
                  disabled={false}
                  checked={electronics}
                  onChange={(e) => setElectronics(e.target.checked)}
                  className="checkbox"
                />
                <label htmlFor="electronics" className="checkboxLabel mr-4">
                  {" "}
                  Electronics{" "}
                </label>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="field ">
          <label className="label has-text-left">
            {" "}
            Please Mention the Links of the Products, Influencers shall be
            Getting *
          </label>
          <div className="field ">
            <div className="control">
              <div className="colomn">
                <input
                  className="input is-rounded"
                  type="text"
                  placeholder="Your Answer"
                  value={productLink}
                  onChange={(e) => setProductLink(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
        </div>

        <div className="field">
          <label className="label has-text-left">
            For How Many Weeks are You Planning to Run the Campaign? *
          </label>
          <div className="control has-text-left ">
            <div className="select is-rounded" style={{ borderRadius: "20px" }}>
              <select
                value={campainTime}
                onChange={(e) => setCampainTime(e.target.value)}
                style={{ width: "900px" }}
                required
              >
                <option value="">Select </option>
                <option value="1">1 weeks</option>
                <option value="2">2 weeks</option>
                <option value="3">3 weeks</option>
                <option value="4">4 weeks</option>
                <option value="5">5 weeks</option>
                <option value="6">6 weeks</option>
                <option value="7">7 weeks</option>
                <option value="8">8 weeks</option>
                <option value="9">9 weeks</option>
                <option value="10">10 weeks</option>
                <option value="11">11 weeks</option>
                <option value="12">12 weeks</option>
                <option value="13">13 weeks</option>
                <option value="14">14 weeks</option>
                <option value="15">15 weeks</option>
                <option value="16">16 weeks</option>
                <option value="17">17 weeks</option>
                <option value="18">18 weeks</option>
                <option value="19">19 weeks</option>
                <option value="20">20 weeks</option>
                <option value="21">21 weeks</option>
                <option value="22">22 weeks</option>
                <option value="23">23 weeks</option>
                <option value="24">24 weeks</option>
                <option value="25">25 weeks</option>
                <option value="26">26 weeks</option>
                <option value="27">27 weeks</option>
                <option value="28">28 weeks</option>
                <option value="29">29 weeks</option>
                <option value="30">30 weeks</option>
                <option value="31">31 weeks</option>
                <option value="32">32 weeks</option>
                <option value="33">33 weeks</option>
                <option value="34">34 weeks</option>
                <option value="35">35 weeks</option>
                <option value="36">36 weeks</option>
                <option value="37">37 weeks</option>
                <option value="38">38 weeks</option>
                <option value="39">39 weeks</option>
                <option value="40">40 weeks</option>
                <option value="41">41 weeks</option>
                <option value="42">42 weeks</option>
                <option value="43">43 weeks</option>
                <option value="44">44 weeks</option>
                <option value="45">45 weeks</option>
                <option value="46">46 weeks</option>
                <option value="47">47 weeks</option>
                <option value="48">48 weeks</option>
                <option value="49">49 weeks</option>
                <option value="50">50 weeks</option>
                <option value="51">51 weeks</option>
                <option value="52">52 weeks</option>

                {/* Add more options as needed */}
              </select>
            </div>
          </div>
        </div>

        <div className="field">
          <label className="label has-text-left">
            Social Media Platform For Influencer Marketing *
          </label>
          <div className="control has-text-left">
            <div className="select is-rounded" style={{ borderRadius: "20px" }}>
              <select
                value={SocialmediaPlatform}
                onChange={(e) => setSocialmediaPlatform(e.target.value)}
                style={{ width: "900px" }}
                required
              >
                <option value="">Select </option>
                <option value="YouTube">YouTube</option>
                <option value="Moj">Moj</option>
                <option value="Chingari">Chingari</option>
                <option value="Facebook"> Facebook</option>
                <option value=" TikTok"> TikTok</option>
                <option value="MxTakaTak">MxTakaTak</option>
                <option value="Linkedin">Linkedin</option>
                <option value="Quora">Quora</option>
                <option value="Snapchat">Snapchat</option>
                <option value="Koo">Koo</option>
                <option value="Tumblr">Tumblr</option>
                <option value="Trell">Trell</option>
                <option value="Rizzle">Rizzle</option>
                <option value="Lumi">Lumi</option>
                <option value="Kutumb">Kutumb</option>
                <option value="Roposo">Roposo</option>
                <option value=" 9GAG"> 9GAG</option>
                <option value="Whatsapp">Whatsapp</option>
                <option value="Offline">Offline</option>
                <option value="X">X</option>
                <option value=" Own"> Own</option>
              </select>
            </div>
          </div>
        </div>
        <div className="field ">
          <label className="label has-text-left "> Campaign Name *</label>
          <div className="field ">
            <div className="control">
              <div className="colomn">
                <input
                  className="input is-rounded"
                  type="text"
                  placeholder="Your Answer"
                  value={campaignName}
                  onChange={(e) => setCampaignName(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div className="field">
          <label className="label has-text-left">
            Upload Cover Image For The Campaign *{" "}
          </label>
          <div className="file ">
            <label className="file-label width-100-pct">
              <input
                style={{ borderRadius: "20px" }}
                className="file-input mr-2 "
                type="file"
                name="uploadBrandLogo"
                onChange={handleFileChange}
                required
              />

              <span
                className="file-cta"
                style={{
                  borderRadius: "20px",
                  // paddingRight: "550px"
                }}
              >
                <span className="file-icon">
                  <i className="fas fa-upload"></i>
                </span>
                <span className="file-label">
                  {uploadFileName || "Choose a file..."}
                </span>
              </span>
            </label>
          </div>
        </div>
        <div className="field">
          <label className="label has-text-left">Upload Campaign Brief *</label>
          <div className="file">
            <label className="file-label width-100-pct">
              <input
                className="file-input mr-2"
                type="file"
                name="uploadBrief"
                onChange={handlePdfChange}
                accept=".pdf, .doc, .docx"
                required
              />
              <span
                className="file-cta"
                style={{
                  borderRadius: "20px",
                  // paddingRight: "550px"
                }}
              >
                <span className="file-icon">
                  <i className="fas fa-upload"></i>
                </span>
                <span className="file-label">
                  {pdfFileName || "Choose a file…"}
                </span>
              </span>
            </label>
          </div>
          <div className="label has-text-left ">
            {/* <p>Example PDF</p> */}
            <a href={examplePdfUrl} target="_blank" rel="noopener noreferrer">
              Example PDF
            </a>
          </div>
          {pdfUrl && (
            <div className="label has-text-left ">
              <p>
                PDF Uploaded Successfully!
                <a href={pdfUrl} download="brief.pdf">
                  {" "}
                  Download PDF
                </a>
              </p>
            </div>
          )}
        </div>
        <div className="field">
          <label className="label has-text-left">
            Is this campaign for a Specific Location? *
          </label>
          {/* <div className='field'> */}
          <div className="control has-text-left">
            {/* <div className='column'> */}
            <div className="select is-rounded">
              <select
                value={isSpecificLocation}
                onChange={handleSpecificLocationChange}
                style={{ width: "900px" }}
                required
              >
                <option value="">Select</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
          </div>
          {/* </div> */}
          {/* </div> */}
        </div>
        {isSpecificLocation === "yes" && (
          <div className="field">
            <label className="label has-text-left ">
              Write Location/city/state *
            </label>
            {/* <div className='field'> */}
            <div className="control">
              {/* <div className='column'> */}
              <input
                className="input is-rounded"
                type="text"
                placeholder="Location"
                value={location1}
                onChange={handleLocationChange}
                required
              />
            </div>
          </div>
          // </div>
          // </div>
        )}
        <div className="field">
          <label className="label has-text-left ">
            Any Other Eligibility Criteria to Select Influencers? *
          </label>
          <div className="field ">
            <div className="control">
              <div className="colomn">
                <textarea
                  className="textarea "
                  style={{ borderRadius: "20px" }}
                  type="text"
                  placeholder="Your Answer"
                  value={selectionCriteria}
                  onChange={(e) => setSelectionCriteria(e.target.value)}
                  required
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="field ">
          <label className="label has-text-left">Any Message for Us? *</label>
          <div className="field ">
            <div className="control">
              <div className="colomn">
                <input
                  className="input is-rounded"
                  type="text"
                  placeholder="Your Answer"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
        </div>

        <label className="label has-text-left">
          {" "}
          Important Notice: I hereby declare that the above particulars of facts
          and information stated are
          <br /> correct to the best of my belief and knowledge. All the facts
          in this submission are correct and truthful. <br /> I takefull
          responsibility for their accuracy. I am the authorized person from the
          brand to submit the above
          <br /> campaign information and will not be canceling the campaign in
          the future.
        </label>
        <div className="field">
          <div className="control">
            <label className="checkbox ">
              <input type="checkbox" className="mr-1" required />I agree to the{" "}
              <a href="https://hobo.video/brand-terms-and-conditions">
                terms and conditions *
              </a>
            </label>
          </div>
        </div>

        {/* Other form fields */}
        <button
          className="button is-primary is-rounded is-focused is-medium is-fullwidth my-5"
          type="submit"
        >
          SUBMIT
        </button>
      </form>
    </div>
  );
};

export default BrandPage;
