import React from "react";
import { Link } from "react-router-dom";
import logo from "../logo192.png";

const NotFoundPage = () => {
  return (
    <div className="imgfull">
      <div className="container1">
        <div className="logo-card">
          <figure className="image is-64x64">
            <img src={logo} alt="Logo" />
          </figure>
        </div>
        <h1 className="is-size-1 has-text-white">404</h1>
        <h2 className="label has-text-white">Page Not Found</h2>
        <p className="label has-text-white">
          The page you are looking for does not exist.
        </p>
        <Link className="mt-5 has-text-link has-test-blue " to="/">
          Go Back to Home
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
