import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import AppicationPage from "../pages/AppicationPage";
// import BrandDashboard2 from '../pages/brandTable';
import BrandDashboard1 from "../pages/influencerTable";
// import BrandPage from '../pages/BrandForm';
import BrandFormInternal from "../pages/BrandFormInternal";
import BrandTableLead from "../pages/brandTableLead";
import InfluencerTableAdmin from "../pages/InfluencerTableAdmin";
import InfluencerDeliverblesTable from "../pages/infDeliverables";
import InfluencerDeliverablesTableAdmin from "../pages/infDeliverablesAdmin";
import InfluencerConformationTable from "../pages/infConfTable";
import InfluencerConformationTableAdmin from "../pages/infConfTableAdmin";
import BrandTable1 from "../pages/brandTable1";
import ApproveCampaignPageInternal from "../pages/ApprovecampaignInternal";
import ApproveCampaignAdmin from "../pages/ApproveCampaignAdmin";
import BrandCampaignForm from "../pages/BrandCampaignFormBrand";
import Modal from "../pages/inviteForm";
import UserTable from "../pages/userTable";
// import logo from "../logo192.png";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Logout from "../components/Logout";
import { useLocation } from "react-router-dom";
import { Config } from "../.env.js"; // Make sure the config is properly set up
import BrandUsersModal from "../pages/inviteForm";
import UserTableBrand from "../pages/userTableBrand";
import { useUser } from "../components/UserContext";

library.add(fas);
const AdminDashboard = () => {
  const logo = "/images/logo.png";
  const newCampaign = "/images/new campaign.png";
  const allCampaign = "/images/all campaign.png";
  const userRole = "/images/user role.png";
  const dashboard = "/images/dashboard.png";

  const [users, setUsers] = useState([]); // Store user list
  const [brandUsers, setBrandUsers] = useState([]); // Store user list
  const location = useLocation();

  const brandName = location.state?.brandName || "";
  const path = location.pathname;
  const { from } = location.state || {};
  const { email } = location.state || {};
  const fromInternalSignIn = from === "/internal-signin" || false;
  const userContext = useUser();
  const role = userContext.userRole;
  console.log("User Context in Admin Dashboard:", userContext);
  console.log("User Role from User Context:", role);
  console.log("Email:", email);
  console.log("From:", from);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        // console.log("BrandName:", brandName);
        const response = await fetch(
          `${Config.API_URL}/api/users?brandName=${brandName}`,
          {
            method: "GET", // Specify GET method
            headers: {
              "Content-Type": "application/json", // Set content type for potential JSON data
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch users");
        }

        const usersData = await response.json();
        const usersDataFiltered = usersData.filter(
          (userData) =>
            Array.isArray(userData.brandName) && userData.brandName.length === 0
        );
        setUsers(usersDataFiltered);
        const brandUsersDataFiltered = usersData.filter(
          (userData) =>
            Array.isArray(userData.brandName) && userData.brandName.length !== 0
        );
        setBrandUsers(brandUsersDataFiltered);
        // console.log("Filtered Admin Users:", usersDataFiltered);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [brandName]);

  // console.log("Users:", users);
  // Function to handle adding a user to the list
  const handleInviteSubmit = (newUser) => {
    // console.log("Admin handleInviteSubmit working");
    setUsers([...users, newUser.user]);
    // console.log("Admin Users:", users);
  };
  const handleBrandUsersInviteSubmit = (newBrandUser) => {
    // console.log("Admin handleInviteSubmit working");
    setBrandUsers([...brandUsers, newBrandUser.user]);
    // console.log("Admin Users:", users);
  };
  // Function to handle deleting a user from the list
  const handleDeleteSubmit = (userId) => {
    setUsers(users.filter((user) => user._id !== userId)); // Remove user by ID
    // console.log("Delete ID:", userId);
    // console.log("Users after deletion:", users);
  };
  const handleDeleteBrandUsersSubmit = (brandUserId) => {
    setBrandUsers(brandUsers.filter((user) => user._id !== brandUserId)); // Remove user by ID
    // console.log("Delete ID:", userId);
    // console.log("Users after deletion:", users);
  };

  const handleUpdateSubmit = (userDetails) => {
    // Check if the user already exists by comparing _id
    const existingUserIndex = users.findIndex(
      (user) => user._id === userDetails._id
    );

    if (existingUserIndex !== -1) {
      // If user exists, update their details
      const updatedUsers = [...users];
      updatedUsers[existingUserIndex] = {
        ...updatedUsers[existingUserIndex],
        ...userDetails,
      };
      // console.log("Updated Users:", updatedUsers);
      setUsers(updatedUsers); // Set the updated users array
    } else {
      // If user doesn't exist, add the new user
      setUsers([...users, userDetails]);
    }
  };
  const handleUpdateBrandUsersSubmit = (brandUserDetails) => {
    // Check if the user already exists by comparing _id
    const existingUserIndex = brandUsers.findIndex(
      (user) => user._id === brandUserDetails._id
    );

    if (existingUserIndex !== -1) {
      // If user exists, update their details
      const updatedUsers = [...brandUsers];
      updatedUsers[existingUserIndex] = {
        ...updatedUsers[existingUserIndex],
        ...brandUserDetails,
      };
      // console.log("Updated Users:", updatedUsers);
      setBrandUsers(updatedUsers); // Set the updated users array
    } else {
      // If user doesn't exist, add the new user
      setBrandUsers([...brandUsers, brandUserDetails]);
    }
  };

  // menu visiblity set

  const [menuVisible, setMenuVisible] = useState(false);

  const handleToggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  //create new campaign

  const [PhoneNumber, setPhoneNumber] = useState("");
  // const [WhatsappNumber, setWhatsappNumber] = useState("");
  // const [UserName, setUserName] = useState("")

  //mobile number validation regx

  //const [MobileNumber, setmobilenumber] = useState("");
  const [Email, setemail] = useState();
  const [MobileNumber, setmobilenumber] = useState("");
  //mobile number validation regx
  let hasValNumber = /^(\+?\d{1,3})? ?\d{10}$/.test(
    PhoneNumber || MobileNumber
  );

  //email validatin regx
  let hasValEmail = /^[a.-zA.-Z0.-9]+@[a.-zA.-Z0.-9]+\.[A-Za-z]+$/.test(Email);

  // create new campaignForm
  const [showCreateCampaignForm, setShowCreateCampaignForm] = useState(false);
  // const [selectedCampaign, setSelectedCampaign] = useState(null);
  const handleCreateNewCampaignClick = () => {
    setShowCreateCampaignForm(true);
    setShowUserRoles(false);
    setShowBrandUsers(false);
    setShowInfluencerApplications(false);
    setshowShortlistedInfluencers(false);
  };

  // Influencer Applications
  const [showInfluencerApplications, setShowInfluencerApplications] =
    useState(false);
  const handleInfluencerApplicationsClick = () => {
    setShowInfluencerApplications(true);
    setShowCreateCampaignForm(false);
    setshowShortlistedInfluencers(false);
    setShowUserRoles(false);
    setShowBrandUsers(false);
  };
  const [showBrandApplications, setShowBrandApplications] = useState(false);
  const handleBrandApplicationsClick = () => {
    setShowBrandApplications(true);
    setShowCreateCampaignForm(false);
    setshowShortlistedInfluencers(false);
    setShowUserRoles(false);
    setShowBrandUsers(false);
    setShowAllCampaign(false);
  };

  // Shortlisted Influencers
  const [showShortlistedInfluencers, setshowShortlistedInfluencers] =
    useState(false);
  const handleShortlistedInfluencersClick = () => {
    setshowShortlistedInfluencers(true);
    setShowCreateCampaignForm(false);
    setShowInfluencerApplications(false);
    setShowUserRoles(false);
    setShowBrandUsers(false);
  };

  const [selectedOption, setSelectedOption] = useState("");
  const [showC1Table, setShowC1Table] = useState(false);
  const [showC2Table, setShowC2Table] = useState(false);
  const [showC3Table, setShowC3Table] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);

    switch (option) {
      case "option1":
        setShowC1Table(true);
        setShowC2Table(false);
        setShowC3Table(false);
        break;
      case "option2":
        setShowC1Table(false);
        setShowC2Table(true);
        setShowC3Table(false);
        break;
      case "option3":
        setShowC1Table(false);
        setShowC2Table(false);
        setShowC3Table(true);
        break;
      default:
        setShowC1Table(false);
        setShowC2Table(false);
        setShowC3Table(false);
    }
  };

  // const handleC1Click = () => {
  //   console.log("C1 clicked");
  //   handleOptionSelect("option1");
  // };

  // const handleC2Click = () => {
  //   console.log("C2 clicked");
  //   handleOptionSelect("option2");
  // };

  // const handleC3Click = () => {
  //   console.log("C3 clicked");
  //   handleOptionSelect("option3");
  // };

  // table
  // Sample table data
  const tableData = [
    ["Data 1", "Data 2", "data3"],

    // Add more rows and data
  ];

  // Function to generate CSV data from the tableData
  const generateCSVData = () => {
    const headers = Array.from(document.querySelectorAll(".table th")).map(
      (th) => th.innerText
    ); // Add more headers if needed
    const csvData = [headers, ...tableData];
    return csvData;
  };
  // status set
  const statusOptions = ["Selected", "Rejected", "On Hold"];
  // add shipment
  // const [selectedRows, setSelectedRows] = useState([]);
  const [shipmentStatus, setShipmentStatus] = useState({});

  const handleCheckboxChange = (index, status) => {
    setShipmentStatus((prevShipmentStatus) => ({
      ...prevShipmentStatus,
      [index]: status,
    }));
  };

  // Add Tracking ID
  const [trackingIds, setTrackingIds] = useState({});
  const handleTrackingIdChange = (index, trackingId) => {
    setTrackingIds((prevTrackingIds) => ({
      ...prevTrackingIds,
      [index]: trackingId,
    }));
  };

  // Handler for status change
  // const handleStatusChange = (index, selectedStatus) => {
  //   // Add logic to handle the status change (e.g., update the data or make an API call)
  //   console.log(`Status changed for row ${index + 1}: ${selectedStatus}`);
  // };

  // // Option to approve deliverables
  // const handleApproval = (index) => {
  //   // Add logic to handle the approval ( update the data or make an API call)
  //   console.log(`Deliverables approved for row ${index + 1}`);
  // };
  // filters

  const [filterStatus, setFilterStatus] = useState("all");
  const data = generateCSVData();
  const filteredData = data.filter((item) => {
    if (filterStatus === "all") return true;
    return item.status === filterStatus; // Replace 'status' with the actual property that indicates the status
  });

  const [showUserRoles, setShowUserRoles] = useState(false);
  // Handle User Roles link click
  const handleUserRolesClick = () => {
    setShowUserRoles(true);
    setShowBrandUsers(false);
    setShowAllCampaign(false);
    setShowCreateCampaignForm(false);
    setShowBrandApplications(false);
  };
  const [showBrandUsers, setShowBrandUsers] = useState(false);
  // Handle Brand Users link click
  const handleBrandUsersClick = () => {
    setShowBrandUsers(true);
    setShowUserRoles(false);
    setShowAllCampaign(false);
    setShowCreateCampaignForm(false);
    setShowBrandApplications(false);
  };
  //invite form
  const [showInviteForm, setShowInviteForm] = useState(false);
  const handleInviteTeamMembersClick = () => {
    setShowInviteForm(true);
    setShowUserRoles(false);
    setShowBrandUsers(false);
    setShowCreateCampaignForm(false);
    setShowInfluencerApplications(false);
    setshowShortlistedInfluencers(false);
  };

  const [showDeliverables, setshowDeliverables] = useState(false);
  const handleDeliverbralesClick = () => {
    setshowDeliverables(true);
    setShowCreateCampaignForm(false);
    setShowInfluencerApplications(false);
    setShowUserRoles(false);
    setShowBrandUsers(false);
  };
  const [showConformation, setshowConformation] = useState(false);
  const handleConformationClick = () => {
    setshowConformation(true);
    setShowCreateCampaignForm(false);
    setShowInfluencerApplications(false);
  };

  const [showAllCampaign, setShowAllCampaign] = useState(false);
  const handleShowAllCampignClick = () => {
    setShowAllCampaign(true); // Set the state to show the "Approved Campaigns" page
    setShowCreateCampaignForm(false);
    setShowInfluencerApplications(false);
    setshowShortlistedInfluencers(false);
  };

  const [hoveredSymbol, setHoveredSymbol] = useState(null);

  const handleMouseEnterSymbol = (symbol) => {
    setHoveredSymbol(symbol);
  };

  const handleMouseLeaveSymbol = () => {
    setHoveredSymbol(null);
  };

  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const handleMouseEnter = () => {
    setIsActive(true);
  };

  const handleMouseLeave = () => {
    setIsActive(false);
  };

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        {/* Sidebar */}
        <a className="navbar-item">
          {/* <span className="icon mr-3">
                        <img className="ml-5" src="./images/hobo.jpeg" alt="Menu Icon" />
                    </span> */}
        </a>
      </div>
      <div>
        {/* <div className= has-background-info' style={{marginRight:"10rem"}}> */}
        <div
          className={`navbar-menu ${isActive ? "is-active" : ""}`}
          style={{ backgroundColor: "dark-blue" }}
        >
          <aside
            className={` menu  ${isActive ? "" : "is-hidden"} overflow-hidden`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <ul
              className="menu-list d-flex flex-row-reverse"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100vh",
                justifyContent: "space-between",
              }}
            >
              <div className=" has-text-left ml-3">
                <div
                  className={`hamburger ${isActive ? "is-active" : ""} `}
                  onClick={toggleMenu}
                >
                  <i className="fa-solid fa-bars"></i>
                  {/* <i className="fas fa-bars is-32x32" style={{ marginLeft: "-15px" }}></i> */}
                  {/* <figure className="image is-32x32" style={{ marginLeft: "-29px" }}>
                        <img src={logo} alt="Logo" />
                    </figure> */}
                  {/* <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span> */}
                </div>
                <figure
                  className={`image is-32x32 logo ${
                    isActive ? "is-active" : ""
                  } `}
                >
                  {/* <img src={logo} alt="Logo" /> */}
                  <a href="/" style={{ all: "unset", cursor: "pointer" }}>
                    <img src={logo} alt="Logo" />
                  </a>
                </figure>
                {/* <li className="menu-label " style={{ marginTop: "1rem", marginLeft: "4px" }}>
                                    Admin Dashboard
                                </li> */}
                <li
                  className="my-5 has-text-white ml-4"
                  onClick={handleCreateNewCampaignClick}
                >
                  {/* <MenuItem title="Create New Campaign" icon="fas fa-plus" /> */}
                  <MenuItem
                    title="Create New Campaign"
                    // icon="fas fa-plus"
                    icon={
                      <img
                        src={newCampaign}
                        alt="icon"
                        style={{ marginBottom: "40px" }}
                      />
                    }
                    clickHandler={handleCreateNewCampaignClick}
                  />
                </li>
                <li
                  className="my-5 has-text-white ml-4"
                  onClick={handleShowAllCampignClick}
                >
                  {/* <MenuItem title="All Campaigns" icon="fas fa-check-circle" /> */}
                  <MenuItem
                    // icon="fas fa-check-circle"
                    title="All Campaigns"
                    icon={
                      <img
                        src={allCampaign}
                        alt="icon"
                        style={{ marginBottom: "40px" }}
                      />
                    }
                    clickHandler={handleShowAllCampignClick}
                  />
                </li>

                <li
                  className="my-5 has-text-white ml-4"
                  onClick={handleBrandApplicationsClick}
                >
                  {/* <MenuItem title="Brand Applications" icon="fas fa-users" /> */}
                  <MenuItem
                    // icon="fas fa-users"
                    title="New Campaign Applications"
                    icon={
                      <img
                        src={dashboard}
                        alt="icon"
                        style={{ marginBottom: "40px" }}
                      />
                    }
                    clickHandler={handleBrandApplicationsClick}
                  />
                </li>
                <li
                  className="my-5 has-text-white ml-4"
                  onClick={handleUserRolesClick}
                >
                  {/* <MenuItem title="User Roles " icon="fas fa-users" /> */}

                  <MenuItem
                    // icon="fas fa-users"
                    title="Hobo Users"
                    icon={
                      <img
                        src={userRole}
                        alt="icon"
                        style={{ marginBottom: "40px" }}
                        // style={{ marginLeft: "-50px" }}
                      />
                    }
                    clickHandler={handleUserRolesClick}
                  />
                </li>
                <li
                  className="my-5 has-text-white ml-4"
                  onClick={handleBrandUsersClick}
                >
                  {/* <MenuItem title="User Roles " icon="fas fa-users" /> */}

                  <MenuItem
                    // icon="fas fa-users"
                    title="Brand Users"
                    icon={
                      <img
                        src={userRole}
                        alt="icon"
                        // style={{ marginLeft: "-50px" }}
                      />
                    }
                    clickHandler={handleBrandUsersClick}
                  />
                </li>
                {/* <MenuItem title="Settings" icon="fas fa-cog" /> */}
              </div>
              <div className="has-text-left ml-3" style={{ marginTop: "auto" }}>
                <Logout />
              </div>
            </ul>
          </aside>
          {/* </div> */}
        </div>

        <ul
          className="menu1 d-flex flex-row-reverse"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            justifyContent: "space-between",
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {!isActive && (
            <div className="has-text-centered">
              <div
                className={`hamburger ${isActive ? "is-active" : ""} `}
                onClick={toggleMenu}
              >
                <i className="fa-solid fa-bars"></i>
                {/* <i className="fas fa-bars is-32x32" style={{ marginLeft: "-15px" }}></i> */}
                {/* <figure className="image is-32x32" style={{ marginLeft: "-29px" }}>
                        <img src={logo} alt="Logo" />
                    </figure> */}
                {/* <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span> */}
              </div>
              <figure
                className={`image is-32x32 logo ${
                  isActive ? "is-active" : ""
                } `}
              >
                <img src={logo} alt="Logo" />
              </figure>

              {!isActive && (
                <div className="my-5 has-text-white ml-4 ">
                  <MenuItem
                    // icon="fas fa-plus"
                    icon={
                      <img
                        src={newCampaign}
                        alt="icon"
                        style={{ marginBottom: "40px" }}
                      />
                    }
                    clickHandler={handleCreateNewCampaignClick}
                    // style={{ marginLeft: "-90px", marginTop: "700px" }}
                  />
                </div>
              )}
              {!isActive && (
                <div className="my-5 has-text-white ml-4">
                  <MenuItem
                    // icon="fas fa-check-circle"
                    icon={
                      <img
                        src={allCampaign}
                        alt="icon"
                        style={{ marginBottom: "40px" }}
                      />
                    }
                    clickHandler={handleShowAllCampignClick}
                    // style={{ merginButton: "20vw" }}
                  />
                </div>
              )}
              {!isActive && (
                <div className="my-5 has-text-white ml-4">
                  <MenuItem
                    // icon="fas fa-users"
                    icon={
                      <img
                        src={dashboard}
                        alt="icon"
                        style={{ marginBottom: "40px" }}
                      />
                    }
                    clickHandler={handleBrandApplicationsClick}
                  />
                </div>
              )}
              {!isActive && (
                <div className="my-5 has-text-white ml-4">
                  <MenuItem
                    // icon="fas fa-users"
                    icon={
                      <img
                        src={userRole}
                        alt="icon"
                        style={{ marginBottom: "40px" }}
                        // style={{ marginLeft: "-50px" }}
                      />
                    }
                    clickHandler={handleUserRolesClick}
                  />
                </div>
              )}
              {!isActive && (
                <div className="my-5 has-text-white ml-4">
                  <MenuItem
                    // icon="fas fa-users"
                    icon={
                      <img
                        src={userRole}
                        alt="icon"
                        // style={{ marginLeft: "-50px" }}
                      />
                    }
                    clickHandler={handleUserRolesClick}
                  />
                </div>
              )}
            </div>
          )}
          {!isActive && (
            <div className="has-text-centered">
              <li
                className="my-5 has-text-white ml-4"
                // style={
                //   {
                //     marginLeft: "-50px !important",
                //     color: "#FFF623 !important",
                //   }
                // }
              >
                <MenuItem icon="fas fa-sign-out-alt" />
              </li>
            </div>
          )}
          {/* <div >
                            <MenuItem title="Settings" icon="fas fa-cog" />
                            </div> */}
        </ul>

        {/* <ul onMouseEnter={handleMouseEnter}
                >
                    {!isActive && (
                        <li className="ml-5 my-5" style={{ cursor: "pointer" }} >
                            <span className="icon is-small has-text-success mr-1" >
                                <i className="fas fa-plus" style={{ marginTop: "30px", marginRight: "90px" }}></i>
                            </span>
                        </li>
                    )}
                    {!isActive && (
                        <li className="ml-5 my-5" style={{ cursor: "pointer" }}>
                            <span className="icon is-small has-text-success mr-1" >
                                <i className="fas fa-check-circle" style={{ marginTop: "30px", marginRight: "90px" }}></i>
                            </span>
                        </li>
                    )}
                    {!isActive && (
                        <li className="ml-5 my-2" style={{ cursor: "pointer" }}>
                            <span className="icon is-small has-text-success mr-1">
                                <i className="fas fa-users" style={{ marginRight: "90px" }}></i>
                            </span>
                        </li>
                    )}
                    {!isActive && (
                        <li className="ml-5 my-2" style={{ cursor: "pointer" }}>
                            <span className="icon is-small has-text-success" >
                                <i className="fas fa-users" style={{ marginRight: "90px" }} ></i>
                            </span>
                        </li>
                    )}
                    {/* {!isActive && (
                        <li className="ml-5 my-2" style={{ cursor: "pointer", }}>

                            <span className="icon is-small has-text-success">
                                <i className="fas fa-cog" style={{ marginRight: "90px" }} ></i>
                            </span>

                        </li>
                    )} 
                </ul> */}
      </div>

      {/* Main content */}
      <div className="column">
        <div className="is-max-desktop">
          <div className="column my-5">
            {showCreateCampaignForm ? (
              // Render the Create Campaign form
              <div
                className="is-child  has-text-centered campaign-form-align"
                style={{
                  height: "100%",
                  width: "50vw",
                  // marginLeft: "360px",
                  // margin: "auto",
                }}
              >
                {/* <BrandFormInternal /> */}
                <BrandCampaignForm />
              </div>
            ) : showAllCampaign ? (
              <div
                className="subtitle all-campaigns-align"
                style={{
                  fontSize: "15px",
                  // marginLeft: "55px"
                }}
              >
                {/* {console.log(
                  "User Role from User Context inside showAllCampaign:",
                  userContext.userRole
                )} */}
                <ApproveCampaignAdmin />
              </div>
            ) : //1o651InfluencerApplications
            showInfluencerApplications ? (
              <div
                className="is-child box"
                style={{
                  overflowX: "auto",
                  overflowY: "auto",
                  width: "1060px",
                }}
              >
                {/* filters */}
                {/* <div className="filter-buttons my-4 mr-3">
                                    <button className="mr-2" onClick={() => setFilterStatus('all')}>All</button>
                                    <button className='mr-2' onClick={() => setFilterStatus('Pending Deliverables')}>Pending Deliverables</button>
                                </div> */}
                <div className="subtitle" style={{ fontSize: "15px" }}>
                  {/* <h1 className='underline'>Influencer Applications</h1> */}
                  {/* <BrandDashboard1 /> */}
                  <InfluencerTableAdmin />
                </div>
              </div>
            ) : showBrandApplications ? (
              <div
                className="full-page-container brand-applications-align"
                style={{
                  display: "flex",
                  // height: "100vh",
                  // marginLeft: "100px",
                  marginTop: "-30px",
                }}
              >
                <div
                  className="is-child"
                  style={{
                    // height: "150vh",
                    // width: "83vw",
                    alignItems: "center",
                  }}
                >
                  {/* <div className='subtitle' style={{ fontSize: '15px' }}> */}
                  <h1
                    className="container underline subtitle"
                    style={{ fontSize: "15px" }}
                  >
                    {/* Brand Application */}
                    New Campaign Applications
                  </h1>
                  {/* <BrandDashboard2 /> */}
                  {/* <BrandTableLead /> */}
                  <div style={{ overflowY: "auto", flexGrow: 1 }}>
                    <BrandTable1 role={role} />
                    {/* </div> */}
                  </div>
                </div>
              </div>
            ) : showShortlistedInfluencers ? (
              <div
                className="is-child box has-text-centered"
                style={{ maxHeight: "550px", overflowX: "auto" }}
              >
                <h1 className="subtitle mt-5 underline">
                  Shortlisted Influencers
                </h1>
                <div></div>
              </div>
            ) : showDeliverables ? (
              <div
                className="is-child box has-text-centered"
                style={{ maxHeight: "550px", overflowX: "auto" }}
              >
                <h1 className="subtitle mt-5 underline"></h1>
                {/* <InfluencerDeliverblesTable /> */}
                <InfluencerDeliverablesTableAdmin />
                <div></div>
              </div>
            ) : showConformation ? (
              <div
                className="is-child box has-text-centered"
                style={{
                  overflowX: "auto",
                  overflowY: "auto",
                  width: "1060px",
                }}
              >
                <h1 className="subtitle mt-5 underline"></h1>
                {/* <InfluencerConformationTable /> */}
                <InfluencerConformationTableAdmin />
                {/* <div>Influencer Conformation</div> */}
              </div>
            ) : // showuserRoles
            showUserRoles ? (
              <div
                className="has-text-right user-roles-align"
                style={{
                  // marginLeft: "60px",
                  // margin: "auto",
                  marginBottom: "200px",
                }}
              >
                <div className="field">
                  <div className="control my-3 ">
                    {Array.isArray(users) && users.length !== 0 ? (
                      <>
                        <Modal role={role} onSubmitAdmin={handleInviteSubmit} />
                        <UserTable
                          users={users}
                          onDeleteUserAdmin={handleDeleteSubmit}
                          onUpdateUserAdmin={handleUpdateSubmit}
                        />
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "80vh",
                        }}
                      >
                        <h1 className="subtitle">No users found.</h1>
                        <Modal role={role} onSubmitAdmin={handleInviteSubmit} />
                      </div>
                    )}
                  </div>
                </div>
                {/* <h1 className='subtitle mt-5'>Choose Campaigns
                                </h1> */}
                {/* Add your User Roles content here */}

                {/* <div className='form'>
                                    <div className='field'>
                                        <label className="C1 mr-5">
                                            <input type="radio" name="campaigns" className='is-rounded mr-2'
                                            /> C-1
                                        </label>
                                        <label className="C2 ml-5">
                                            <input type="radio" name="campaigns" className='is-rounded mr-2'
                                            /> C-2
                                        </label>
                                    </div>
                                    <div className='field'>
                                        <label className="C-3 mr-5">
                                            <input type="radio" name="campaigns" className='is-rounded mr-2'
                                            /> C-3
                                        </label>
                                        <label className="C4 ml-5">
                                            <input type="radio" name="campaigns" className='is-rounded mr-2'
                                            /> C-4
                                        </label>

                                    </div>
                                </div> */}
              </div>
            ) : // showBrandusers
            showBrandUsers ? (
              <div
                className="has-text-right user-roles-align"
                style={{
                  // marginLeft: "60px",
                  // margin: "auto",
                  marginBottom: "200px",
                }}
              >
                <div className="field">
                  <div className="control my-3 ">
                    {Array.isArray(users) && users.length !== 0 ? (
                      <>
                        <BrandUsersModal
                          path={path}
                          showBrandUsers={showBrandUsers}
                          onSubmitBrand={handleBrandUsersInviteSubmit}
                          fromInternalSignIn={fromInternalSignIn}
                          role={role}
                        />
                        <UserTableBrand
                          brandName={brandName}
                          users={brandUsers}
                          onDeleteUserBrand={handleDeleteBrandUsersSubmit}
                          onUpdateUserBrand={handleUpdateBrandUsersSubmit}
                          fromInternalSignIn={fromInternalSignIn}
                          showBrandUsers={showBrandUsers}
                          role={role}
                        />
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "80vh",
                        }}
                      >
                        <h1 className="subtitle">No users found.</h1>
                        <BrandUsersModal
                          path={path}
                          onSubmitBrand={handleBrandUsersInviteSubmit}
                          fromInternalSignIn={fromInternalSignIn}
                          showBrandUsers={showBrandUsers}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : showInviteForm ? (
              // Display the invite form
              <div className="is-child box has-text-centered">
                <h1 className="subtitle mt-5">Invite form campaign</h1>
                <div className="form">
                  <div className="field ">
                    <div className="control has-icons-left">
                      <div className="colomn">
                        <input
                          className="input is-rounded "
                          type="text"
                          placeholder="Email ID"
                          value={Email}
                          onChange={(e) => setemail(e.target.value)}
                        />
                        {Email && !hasValEmail && (
                          <small
                            className={`ml-1 mt-1  ${
                              !hasValEmail && Email !== ""
                                ? "has-text-danger"
                                : "has-text-success"
                            }`}
                          >
                            {!hasValEmail && Email !== ""
                              ? "Enter a valid email address"
                              : ""}
                          </small>
                        )}
                        <span className="icon is-small is-left">
                          <i className="fas fa-envelope"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* mobile number */}
                  <div className="field ">
                    <div className="control has-icons-left ">
                      <div className="colomn">
                        <input
                          className="input  is-rounded "
                          type="text"
                          placeholder="Mobile Number"
                          value={MobileNumber}
                          onChange={(e) => setmobilenumber(e.target.value)}
                        />

                        {/* mobile number validation */}
                        {MobileNumber && !hasValNumber && (
                          <div>
                            <small
                              className={`ml-1 mt-1  ${
                                !hasValNumber && MobileNumber !== ""
                                  ? "has-text-danger"
                                  : "has-text-success"
                              }`}
                            >
                              {!hasValNumber && MobileNumber !== ""
                                ? "Please Enter valid Mobile number"
                                : ""}
                            </small>
                          </div>
                        )}

                        <span className="icon is-small is-left">
                          <i className="fas fa-phone"></i>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <button
                        className="button is-primary is-rounded  is-focused is-medium is-fullwidth "
                        disabled={
                          !MobileNumber ||
                          !Email ||
                          !hasValNumber ||
                          !hasValEmail
                        }
                      >
                        SIGN UP
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="subtitle campaigns-section"
                // style={{ fontSize: "15px", marginLeft: "42px" }}
              >
                <ApproveCampaignAdmin />
              </div>
              // ) : isActive ? (
              //     <div className="is-child mr-5" style={{ height: '920vh', width: '10vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              //         <ApproveCampaignAdmin />

              //     </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};
// function MenuItem({ title, icon, clickHandler }) {
//   return (
//     <li
//       className="ml-2 my-2"
//       style={{ cursor: "pointer" }}
//       onClick={clickHandler}
//     >
//       <span className="icon is-small has-text-success mr-1">
//         <i className={icon} style={{ marginRight: "50px" }}></i>
//       </span>
//       <span style={{ marginLeft: "-25px" }}>{title}</span>
//     </li>
//   );
// }

function MenuItem({ title, icon, clickHandler }) {
  return (
    <div className="my-2" style={{ cursor: "pointer" }} onClick={clickHandler}>
      <span
        className="icon is-small mr-1"
        style={{
          marginLeft: "-15px",
          color: "#FFF623",
          width: "20px",
        }}
      >
        {typeof icon === "string" ? (
          <i className={icon} style={{ fontSize: "20px" }}></i>
        ) : (
          icon // Render JSX element directly if provided
        )}
      </span>
      <span style={{ fontSize: "18px" }}>{title}</span>
    </div>
  );
}

export default AdminDashboard;
