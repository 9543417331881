import React, { useState, useEffect } from "react";
import { Config } from "../.env.js";
import { toast } from "react-toastify";
import axios from "axios";
import { useUser } from "../components/UserContext";

const EditFormBrand = ({
  closeModal,
  selectedUser,
  updateUserTable,
  users,
  onDeleteUserBrand,
  onUpdateUserBrand,
  fromInternalSignIn,
}) => {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [userId, setUserId] = useState("");
  const [assignBrandName, setAssignBrandName] = useState("");
  const [brandNames, setBrandNames] = useState([]); // Store all brand names
  const userContext = useUser(); // Get the full context object

  useEffect(() => {
    if (selectedUser) {
      setUserName(selectedUser.userName);
      setEmail(selectedUser.email);
      setAssignBrandName(selectedUser.brandName);
      setRole(selectedUser.role);
      setUserId(selectedUser._id); // Ensure the correct ID field is used
    }
  }, [selectedUser]);
  // const handleDelete = async () => {
  //   if (!window.confirm("Are you sure you want to delete this user?")) {
  //     return;
  //   }
  // };

  // Fetch all brand names when the component mounts
  useEffect(() => {
    const fetchAllBrandNames = async () => {
      try {
        const response = await axios.get(`${Config.API_URL}/brand/all-brands`);
        if (response.data && Array.isArray(response.data)) {
          setBrandNames(response.data);
        } else {
          console.error("Unexpected response for brand names:", response.data);
        }
      } catch (error) {
        console.error("Error fetching brand names:", error);
        toast.error("Failed to load brand names. Please try again.");
      }
    };

    fetchAllBrandNames();
  });

  const handleDelete = async () => {
    if (!window.confirm("Are you sure you want to delete this user?")) {
      return;
    }
    try {
      const response = await fetch(
        `${Config.API_URL}/api/users/delete/${userId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete user");
      }
      onDeleteUserBrand(userId);
      toast.success("User deleted successfully");
      // updateUserTable(userId);
      closeModal(); // Close the modal after successful deletion
    } catch (error) {
      console.error("Error deleting user:", error.message);
      toast.error("An error occurred while trying to delete the user");
    }
  };
  const handleUpdate = async () => {
    try {
      const response = await fetch(`${Config.API_URL}/api/users/${userId}`, {
        // Use userId here
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userName,
          email,
          role,
          brandName: assignBrandName,
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to update user");
      }
      const { updatedUser } = await response.json();
      onUpdateUserBrand(updatedUser);
      // console.log("Updated User:", updatedUser);
      toast.success("User updated successfully");
      //   const updatedUser = await response.json();
      //   console.log("UpdatedUser1:", updatedUser);
      //   updateUserTable(updatedUser); // Update parent component state
      closeModal();
    } catch (error) {
      console.error("Error updating user:", error.message);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Selected User ID:", userId);
  };

  const handleCancelEdit = () => {
    closeModal();
  };
  // console.log("Selected Brand Name:", assignBrandName);
  return (
    <div
      className="modal is-active"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <div
        className="modal-content"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "8px",
          width: "80%",
          maxWidth: "800px",
        }}
      >
        <button
          className="delete"
          aria-label="close"
          onClick={handleCancelEdit}
        ></button>
        <div className="card">
          <header className="card-header">
            <p className="card-header-title">Edit User</p>
          </header>
          <div className="edit-form">
            <form onSubmit={handleSubmit}>
              <div className="field">
                <label className="label has-text-left">Name</label>
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="text"
                    placeholder="User Name"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label has-text-left">Email Id</label>
                <div className="control">
                  <input
                    className="input is-rounded"
                    type="text"
                    placeholder="Email ID"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              {/* Brand selection dropdown */}
              {fromInternalSignIn ? (
                <div className="field">
                  <label className="label has-text-left">Brand</label>
                  <div className="control">
                    <div className="select">
                      <select
                        style={{ borderRadius: "20px", width: "900px" }}
                        value={assignBrandName}
                        onChange={(e) => setAssignBrandName(e.target.value)}
                      >
                        <option value="">Select Brand Name</option>
                        {brandNames.map((name, index) => (
                          <option key={index} value={name}>
                            {name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="field">
                  <label className="label has-text-left">Brand</label>
                  <div className="control">
                    <div className="select">
                      <select
                        style={{ borderRadius: "20px", width: "900px" }}
                        value={assignBrandName}
                        onChange={(e) => setAssignBrandName(e.target.value)}
                      >
                        <option value="">Select Brand Name</option>
                        {userContext.brandName
                          .filter(Boolean) // Removes falsy values (null, undefined, "", false, 0, NaN)
                          .map((name, index) => (
                            <option key={index} value={name}>
                              {name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              )}
              <div className="field">
                <label className="label has-text-left">Role</label>
                <div className="control">
                  <div className="select">
                    <select
                      style={{ borderRadius: "20px", width: "900px" }}
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                    >
                      <option value="">Select Role</option>
                      <option value="brand-admin">Admin</option>
                      <option value="brand-staff">Staff</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button
                    className="button is-primary is-rounded is-fullwidth"
                    disabled={!userName || !email || !role}
                    onClick={handleUpdate}
                  >
                    Update
                  </button>
                </div>
              </div>
              <div className="field" style={{ marginTop: "10px" }}>
                <div className="control">
                  <button
                    className="button is-danger is-rounded is-fullwidth"
                    onClick={handleDelete}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditFormBrand;
