import React, { useState } from "react";
import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import OTP from "./pages/mobilevalidation";
import ForgetPassword from "./pages/ForgetPassword";
import UpdatePassword from "./pages/UpdatePassword";
import ApplicationPage from "./pages/AppicationPage";
import Home from "./pages/Home";
import SignupDashboard from "./Dashboard/SignupDashboard";
import BrandDashboard from "./Dashboard/Brand_Dashboard";
import InfluencerProfile from "./pages/Influencer_Profile";
import InternalTeamDashboard from "./Dashboard/internal_team_Dashboard";
import InfluencerconfirmationPage from "./pages/confirmationPage";
import BrandPage from "./pages/BrandForm";
import InviteForm from "./components/campaignInviteForm";
import BrandDashboard2 from "./pages/brandTable";
import BrandDashboard1 from "./pages/influencerTable";
import AdminSignup from "./pages/AdminSignup";
import AdminSignin from "./pages/AdminSignin";

import Signinlead from "./pages/Signinlead";
import LeadSignup from "./pages/LeadSignup";
import SigninExecutive from "./pages/SigninExecutive";
import SignupExecutive from "./pages/SignupExecutive";
import CampaignLive from "./pages/CampaignLive";
import OtpPage from "./pages/OtpPage";
import ApproveCampaignPage from "./pages/Approvecampaign";
import SuccessPage from "./components/sucessPage";
import BrandInfDashboard from "./pages/BrandInfDashboard";
import SignInDashboard from "./Dashboard/iInternalTeamSignIn";
import BrandFormInternal from "./pages/BrandFormInternal";
import ExecutiveDashboard from "./Dashboard/ExecutiveDashboard";
import LeadDashboard from "./Dashboard/LeadDashboard";
import BrandTable1 from "./pages/brandTable1";
import AdminDashboard from "./Dashboard/AdminDashboard";
import BrandTableLead from "./pages/brandTableLead";
import InfluencerTableLead from "./pages/influencerTableLead";
import InfluencerTableAdmin from "./pages/InfluencerTableAdmin";
import InfluencerTableBrand from "./pages/InfluencerTableBrand";
import ShortlistedInfluencer from "./pages/ShortlistedInfluencer";
import InfDelverablesForm from "./pages/influencrDeliverables";
import InfluencerDeliverblesTable from "./pages/infDeliverables";
import InfluencerConformationTable from "./pages/infConfTable";
import InfluencerDeliverablesTableLead from "./pages/infDeliverablesLead";
import InfluencerDeliverablesTableAdmin from "./pages/infDeliverablesAdmin";
import InfluencerDeliverablesTableBrand from "./pages/infDeliverablesBrand";
import InfluencerConformationTableExecutive from "./pages/infConfTableExecutive";
import InfluencerConformationTableLead from "./pages/infConfTableLead";
import InfluencerConformationTableAdmin from "./pages/infConfTableAdmin";
import InfluencerConformationTableBrand from "./pages/infConfTableBrand";
import ApproveCampaignPageInternal from "./pages/ApprovecampaignInternal";
import ApplicationPage3 from "./pages/brandGst";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewMore from "./pages/ViewMore";
import ViewMoreLead from "./pages/ViewMoreLead";
import CampaignLiveLead from "./pages/CampaignLiveLead";
import ApproveCampaignLead from "./pages/ApproveCampaignLead";
import ViewMoreAdmin from "./pages/ViewMoreAdmin";
import CampaignLiveAdmin from "./pages/CampaignLiveAdmin";
import ApproveCampaignAdmin from "./pages/ApproveCampaignAdmin";
import BrandCampaignForm from "./pages/BrandCampaignFormBrand";
import UserManagement from "./pages/userRole";
import Modal from "./pages/inviteForm";
import UserTable from "./pages/userTable";
import SignInBrand from "./pages/SignInBrand";
import ViewInfluencer from "./pages/ViewInfluencer";
import ViewCampaign from "./pages/viewCampaign";
import ViewCampaignBrand from "./pages/ViewCampaignBrand";
import UserSignup from "./pages/userSignup";
import UserSignIn from "./pages/userSign";
import Administration from "./pages/adminbDashboard";
import StaffDashboard from "./pages/staffbDashboard";
import Live from "./pages/live";
import InfluencerTableBrand1 from "./pages/InfluencerTableBrand1";
import InfluencerTableBrand11 from "./pages/InfluencerTableBrand11";
import InfluencersTablesAdmin from "./pages/InfluencersTablesAdmin";
import InfluencersTablesLead from "./pages/InfluencersTablesLead";
import InfluencersTablesExecutive from "./pages/InfluencersTablesExecutive";
import InviteForm1 from "./pages/EditInvit";
import ForgetInternal from "./pages/ForgetInternal";
import OtpInternal from "./pages/OtpInternal";
import OtpInternal1 from "./pages/OtpInternal1";
import UpdatePasswordInternal from "./pages/UpdatePasswordInternal";
import OtpAdmin from "./pages/OTPAdmin";
import ForgetPasswordLead from "./pages/ForgetPasswordLead";
import UpdatePasswordLead from "./pages/UpdatePasswordLead";
import Otp1Lead from "./pages/Otp1Lead";
import Otp2Lead from "./pages/Otp2Lead";
import Otp1Executive from "./pages/Otp1Executive";
import ForgetPasswordExecutive from "./pages/ForgetPasswordExecutive";
import Otp2Executive from "./pages/Otp2Executive";
import UpdatePasswordExecutive from "./pages/UpdatePasswordExecutive";
import EditFormBrand from "./pages/EditFormBrand";
import UserManagementBrand from "./pages/userTableBrand";
import NotFoundPage from "./pages/NotFoundPage";
import { UserProvider } from "./components/UserContext";
import InternalSignup from "./pages/InternalSignup";
import InternalSignin from "./pages/InternalSignin";
import UserPendingApproval from "./pages/UserPendingApproval";
import AllCampaigns from "./pages/AllCampaigns";
import FacebookLogin from "./pages/FacebookLogin";
import LoginSuccessInsta from "./pages/LoginSuccessInsta";
import DataDisplayInsta from "./pages/DataDisplayInsta";
import UserListInsta from "./pages/UsersListInsta";
import UserDetailPageInsta from "./pages/UserDetailPageInsta";
import UserPageListInsta from "./pages/UserPageInsta";
import UserProfileInsta from "./pages/UserProfileInsta";
import LiveLinks from "./pages/LiveLinks";
import InfluencerLogin from "./pages/InfluencerLogin";
import InfluencerSignup from "./pages/InfluencerSignup";

//import DashboardHeader from './components/DashboardHeader';
// import BrandForm2 from './pages/BrandForm2';

const App = () => {
  const [showHeader, setShowHeader] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  return (
    <div className="hero">
      <BrowserRouter>
        <UserProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/otp/:Email" element={<OTP />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route
              path="/update-password/:email"
              element={<UpdatePassword />}
            />
            <Route path="/application-form" element={<ApplicationPage />} />
            <Route path="/signinbrand" element={<SignInBrand />} />
            {/* hide header */}
            <Route
              path="/signup-dashboard"
              element={<SignupDashboard setShowHeader={setShowHeader} />}
            />

            <Route path="/brand-dashboard" element={<BrandDashboard />} />

            <Route path="/profile-edit" element={<InfluencerProfile />} />
            {/* <Route
              path="/internal-dashboard"
              element={<InternalTeamDashboard />}
            /> */}
            <Route
              path="/confirmation-page"
              element={<InfluencerconfirmationPage />}
            />
            <Route path="/brand-application" element={<BrandPage />} />
            <Route path="/gst" element={<ApplicationPage3 />} />
            {/* <Route path= "/brand-form" element={<BrandForm2/>}/> */}
            <Route path="/Invite-form" element={<InviteForm />} />
            <Route path="/table" element={<BrandDashboard2 />} />
            <Route path="/infTable" element={<BrandDashboard1 />} />
            <Route path="/admin-signup" element={<AdminSignup />} />
            <Route path="/approve-campaign" element={<ApproveCampaignPage />} />
            <Route path="/admin-signin" element={<AdminSignin />} />
            {/* lead signup */}
            <Route path="/lead-signup" element={<LeadSignup />} />
            <Route path="/lead-signin" element={<Signinlead />} />
            {/* executive signup */}
            <Route path="/executive-signin" element={<SigninExecutive />} />
            <Route path="/executive-signup" element={<SignupExecutive />} />
            <Route path="/campaignLive" element={<CampaignLive />} />
            <Route path="/otp1/:email" element={<OtpPage />} />
            <Route path="/success-page" element={<SuccessPage />} />
            <Route path="/BrandInf-table" element={<BrandInfDashboard />} />
            <Route path="/signin-dashboard" element={<SignInDashboard />} />
            <Route path="/brand-application1" element={<BrandFormInternal />} />

            <Route
              path="/executive-dashboard"
              element={<ExecutiveDashboard />}
            />

            <Route path="/lead-dashboard" element={<LeadDashboard />} />

            <Route path="/brand-tabel1" element={<BrandTable1 />} />

            <Route path="/admin-dashboard" element={<AdminDashboard />} />

            <Route path="/table2" element={<BrandTableLead />} />
            <Route path="/influencerLead" element={<InfluencerTableLead />} />
            <Route
              path="/influencerAdmin/:brandName"
              element={<InfluencerTableAdmin />}
            />
            <Route path="/influencerbrand" element={<InfluencerTableBrand />} />
            <Route
              path="/shortlistedinfluencer/:brandName"
              element={<ShortlistedInfluencer />}
            />
            <Route path="/inf-deliverbles" element={<InfDelverablesForm />} />
            <Route
              path="/inf-deliverblesTable"
              element={<InfluencerDeliverblesTable />}
            />
            <Route
              path="/inf-confirmation"
              element={<InfluencerConformationTable />}
            />
            <Route
              path="/inf-deliverblesTableLead"
              element={<InfluencerDeliverablesTableLead />}
            />
            <Route
              path="/inf-deliverblesTableAdmin"
              element={<InfluencerDeliverablesTableAdmin />}
            />
            <Route
              path="/inf-deliverblesTableBrand"
              element={<InfluencerDeliverablesTableBrand />}
            />
            <Route
              path="/inf-conformationExecutive"
              element={<InfluencerConformationTableExecutive />}
            />
            <Route
              path="/inf-conformationLead"
              element={<InfluencerConformationTableLead />}
            />
            <Route
              path="/inf-conformationAdmin"
              element={<InfluencerConformationTableAdmin />}
            />
            <Route
              path="/inf-conformationBrand"
              element={<InfluencerConformationTableBrand />}
            />
            <Route
              path="/approveCampaignInternal"
              element={<ApproveCampaignPageInternal />}
            />
            <Route path="/viewButton/:brandName" element={<ViewMore />} />
            <Route
              path="/viewButtonLead/:brandName"
              element={<ViewMoreLead />}
            />
            <Route path="/campaignLiveLead" element={<CampaignLiveLead />} />
            <Route path="/approveLead" element={<ApproveCampaignLead />} />
            <Route
              path="/viewButtonAdmin/:brandName"
              element={<ViewMoreAdmin />}
            />
            <Route path="/campaignLiveAdmin" element={<CampaignLiveAdmin />} />
            <Route path="/brandCampaignform" element={<BrandCampaignForm />} />
            <Route path="/approveAdmin" element={<ApproveCampaignAdmin />} />
            <Route path="/brand-new" element={<BrandCampaignForm />} />
            <Route path="/userRole" element={<UserManagement />} />
            <Route path="/modal" element={<Modal />} />
            <Route path="/user-table" element={<UserTable />} />
            <Route
              path="/view-influencer/:brandName"
              element={<ViewInfluencer />}
            />
            <Route
              path="/view-campaign/:campaignName"
              element={<ViewCampaign />}
            />
            <Route
              path="/view-campaignBrand/:campaignName"
              element={<ViewCampaignBrand />}
            />
            <Route path="/userSignUp/:brandName" element={<UserSignup />} />
            <Route path="/administration" element={<Administration />} />
            <Route path="/userSignIn" element={<UserSignIn />} />
            <Route path="/staffdashboard" element={<StaffDashboard />} />
            <Route path="/live" element={<Live />} />
            <Route
              path="/tbl1/:campaignName"
              element={<InfluencerTableBrand1 />}
            />
            {/* <Route path = "tbl1/:brandName" element= {<InfluencerTableBrand11/>}/> */}
            <Route
              path="/tbladmin/:campaignName"
              element={<InfluencersTablesAdmin />}
            />
            <Route
              path="/tbllead/:campaignName"
              element={<InfluencersTablesLead />}
            />
            <Route
              path="/tblexecutive/:campaignName"
              element={<InfluencersTablesExecutive />}
            />
            <Route path="/editInvite" element={<InviteForm1 />} />
            <Route path="/forgetinternal" element={<ForgetInternal />} />
            <Route path="/otpinternal/:email" element={<OtpInternal />} />
            <Route path="/otpinternal1/:email" element={<OtpInternal1 />} />
            <Route path="/otpAdmin/:email" element={<OtpAdmin />} />
            <Route
              path="/update-internal/:email"
              element={<UpdatePasswordInternal />}
            />
            <Route path="/forgrtLead" element={<ForgetPasswordLead />} />
            <Route path="/otp1Lead/:email" element={<Otp1Lead />} />
            <Route
              path="/updatepassLead/:email"
              element={<UpdatePasswordLead />}
            />
            <Route path="/otpLeadsignup/:email" element={<Otp2Lead />} />
            <Route
              path="/otpexecutivesignup/:email"
              element={<Otp1Executive />}
            />
            <Route
              path="/forgetExecutive"
              element={<ForgetPasswordExecutive />}
            />
            <Route path="/otp2Executive/:email" element={<Otp2Executive />} />
            <Route
              path="/updateExecutive/:email"
              element={<UpdatePasswordExecutive />}
            />
            <Route path="/editbrand" element={<EditFormBrand />} />
            <Route path="/user" element={<UserManagementBrand />} />
            {/* <Route path="/updateExecutive/:email" element={<UpdatePasswordExecutive />} /> */}
            {/* <Route path="/live" element={<LiveCampaigns />} /> */}
            <Route path="/internal-signup" element={<InternalSignup />} />
            <Route path="/internal-signin" element={<InternalSignin />} />
            <Route path="/pending-approval" element={<UserPendingApproval />} />
            <Route path="/all-campaigns" element={<AllCampaigns />} />
            <Route path="/facebook-login" element={<FacebookLogin />} />

            <Route path="/login-success" element={<LoginSuccessInsta />} />
            <Route path="/data" element={<DataDisplayInsta />} />
            <Route path="/influencers" element={<UserListInsta />} />
            <Route
              path="/influencer/:instagramAccountId"
              element={<UserDetailPageInsta />}
            />
            <Route path="/my-user" element={<UserPageListInsta />} />
            <Route
              path="/userProfile/:instagramAccountId"
              element={<UserProfileInsta />}
            />
            <Route path="/live-links" element={<LiveLinks />} />
            <Route path="/influencer-login" element={<InfluencerLogin />} />
            <Route path="/influencer-signup" element={<InfluencerSignup />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          <ToastContainer />
        </UserProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
