// components/UserTable.js
import React, { useState, useEffect } from "react";
import "bulma/css/bulma.min.css";
import InviteForm from "../pages/inviteForm"; // Import the InviteForm component
import EditForm from "../pages/EditInvit";
import { Config } from "../.env.js"; // Make sure the config is properly set up
import { useLocation } from "react-router-dom";

const UserTable = ({ users, onDeleteUserAdmin, onUpdateUserAdmin }) => {
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  // const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const location = useLocation();
  const brandName = location.state?.brandName || "";

  // useEffect(() => {
  //   const fetchUsers = async () => {
  //     try {
  //       const response = await fetch(
  //         `${Config.API_URL}/api/users?brandName=${brandName}`,
  //         {
  //           method: "GET", // Specify GET method
  //           headers: {
  //             "Content-Type": "application/json", // Set content type for potential JSON data
  //           },
  //         }
  //       );

  //       if (!response.ok) {
  //         throw new Error("Failed to fetch users");
  //       }

  //       const usersData = await response.json();
  //       const usersDataFiltered = usersData.filter(
  //         (userData) =>
  //           Array.isArray(userData.brandName) && userData.brandName.length === 0
  //       );
  //       setUsers(usersDataFiltered);
  //       console.log("Filtered Admin Users:", usersDataFiltered);
  //     } catch (error) {
  //       console.error("Error fetching users:", error);
  //     }
  //   };

  //   fetchUsers();
  // }, [brandName]);

  const handleInviteClick = () => {
    setIsInviteModalOpen(true);
  };

  const handleEditClick = (user) => {
    setSelectedUser(user);
    setIsEditModalOpen(true);
  };

  // const updateUserTable = (updatedUser) => {
  //   setUsers(
  //     users.map((user) => (user._id === updatedUser._id ? updatedUser : user))
  //   );
  // };

  return (
    <div>
      <table className="table is-bordered is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>User</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user._id}>
              <td>
                <div className="columns is-vcentered">
                  <div className="column is-narrow">
                    <span className="icon is-small">
                      <i
                        className="fas fa-user"
                        style={{ marginLeft: "30px" }}
                      ></i>
                    </span>
                  </div>
                  <div className="column" style={{ marginLeft: "30px" }}>
                    {user.userName}
                    <div
                      className="has-text-grey-light"
                      style={{ fontSize: "0.9em" }}
                    >
                      {user.email}
                    </div>
                  </div>
                </div>
              </td>
              <td>{user.role}</td>
              <td>
                <button
                  className="button is-primary is-small"
                  onClick={() => handleEditClick(user)}
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Invite Form Modal */}
      {isInviteModalOpen && (
        <InviteForm
          closeModal={() => setIsInviteModalOpen(false)}
          // updateUserTable={updateUserTable}
        />
      )}

      {/* Edit Form Modal */}
      {isEditModalOpen && (
        <EditForm
          closeModal={() => setIsEditModalOpen(false)}
          selectedUser={selectedUser}
          users={users}
          onDeleteUserAdmin={onDeleteUserAdmin}
          onUpdateUserAdmin={onUpdateUserAdmin}
        />
      )}
    </div>
  );
};

export default UserTable;
