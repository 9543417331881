import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getAllCampaigns } from "../components/campaignApi";
import CampaignLiveLead from "./CampaignLiveLead";
import CampaignLiveAdmin from "./CampaignLiveAdmin";
import { sortByIdDescending } from "../utils/sortByIdDescending";
import { useUser } from "../components/UserContext";

const ITEMS_PER_PAGE = 10; // Changed to 10
const PAGES_DISPLAYED = 5; // Show 5 page numbers at a time

const ApproveCampaignAdmin = () => {
  const location = useLocation();
  const [campaigns, setCampaigns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("all");
  const [loading, setLoading] = useState(true);
  const approvedCampaign = location.state
    ? location.state.approvedCampaign
    : null;
  const prevApprovedCampaigns = location.state
    ? location.state.prevApprovedCampaigns
    : [];
  const userContext = useUser(); // Get the full context object
  // console.log("Role:", userContext.userRole);
  useEffect(() => {
    const fetchCampaigns = async () => {
      setLoading(true);
      try {
        const allCampaigns = await getAllCampaigns();
        // console.log("Campaigns:", allCampaigns);
        const sortedCampaigns = sortByIdDescending(allCampaigns); // Use the utility function

        // console.log("Sorted Campaigns:", allCampaigns); // Verify the sorted array

        // Filter campaigns based on the logged-in brand name
        // setCampaigns(allCampaigns.reverse());
        setCampaigns(sortedCampaigns);
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCampaigns();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleFilterChange = (filterValue) => {
    setFilter(filterValue);
    setCurrentPage(1); // Reset page when filter changes
  };

  // Filter campaigns based on the selected filter
  const filteredCampaigns = campaigns.filter((campaign) => {
    if (filter === "all") return true;
    if (filter === "approved") return campaign.approve;
    if (filter === "pending") return !campaign.approve && !campaign.rejected;
    if (filter === "rejected") return campaign.rejected;
    // if (filter === 'onHold') return !campaign.approved && !campaign.rejected;
    return true;
  });

  // Calculate total number of pages
  // const totalPages = Math.ceil(campaigns.length / ITEMS_PER_PAGE);
  const totalPages = Math.ceil(filteredCampaigns.length / ITEMS_PER_PAGE);

  // Calculate the range of visible pages, handling edge cases to ensure valid range
  // const startPage = Math.max(
  //   1,
  //   Math.min(
  //     currentPage - Math.floor(PAGES_DISPLAYED / 1),
  //     totalPages - PAGES_DISPLAYED + 1
  //   )
  // );
  // const endPage = Math.min(totalPages, startPage + PAGES_DISPLAYED - 1);
  const startPage = Math.max(
    1,
    Math.min(
      currentPage - Math.floor(PAGES_DISPLAYED / 2),
      totalPages - PAGES_DISPLAYED + 1
    )
  );
  const endPage = Math.min(totalPages, startPage + PAGES_DISPLAYED - 1);

  // Slice the campaigns array to display only the relevant portion for the current page
  const paginatedCampaigns = filteredCampaigns.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <div className="dual-campaign-container">
      <div
        className="filter-buttons"
        style={{
          display: "flex",
          justifyContent: "space-around",
          border: "1px solid #4a4a4a",
          padding: "7px",
          borderRadius: "8px",
        }}
      >
        {["all", "approved", "pending", "rejected"].map((value) => (
          <button
            key={value}
            className={filter === value ? "active" : ""}
            style={{
              cursor: "pointer",
              border: "none",
              background: "none",
              color: "#4a4a4a",
              fontWeight: filter === value ? "bold" : "normal",
              transition: "color 0.3s",
            }}
            onClick={() => handleFilterChange(value)}
          >
            {value.charAt(0).toUpperCase() + value.slice(1)}
          </button>
        ))}
      </div>

      {loading ? (
        <div className="loading has-text-centered mt-5">Loading...</div>
      ) : (
        <div className="campaigns-container">
          {paginatedCampaigns.length === 0 ? (
            <div
              className="is-child has-text-centered"
              style={{ width: "80vw", marginTop: "15vw" }}
            >
              <p>No {filter} campaigns available.</p>
            </div>
          ) : (
            paginatedCampaigns.map((campaign, index) => (
              <div key={index} className="campaign-item is-half">
                <CampaignLiveAdmin
                  campaign={campaign}
                  alignLeft={index % 2 === 0}
                  role={userContext.userRole}
                />
              </div>
            ))
          )}
        </div>
      )}

      {paginatedCampaigns.length > 0 && (
        <div
          className="pagination-container"
          role="navigation"
          aria-label="pagination"
          style={
            {
              // display: "flex",
              // flexDirection: "row",
              // justifyContent: "center",
              // marginLeft: "30px",
            }
          }
        >
          {currentPage > 1 && (
            <button
              className="pagination-previous"
              style={{ cursor: "pointer" }}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </button>
          )}

          {Array.from(
            { length: endPage - startPage + 1 },
            (_, i) => startPage + i
          ).map((page) => (
            <button
              key={page}
              className={`pagination-link ${
                page === currentPage ? "is-current" : ""
              }`}
              aria-label={`Goto page ${page}`}
              style={{ cursor: "pointer" }}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </button>
          ))}

          {currentPage < totalPages && (
            <button
              className="pagination-next"
              style={{ cursor: "pointer" }}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ApproveCampaignAdmin;
