import React from "react";
import AgeRangeLineChart from "./AgeRangeLineChartInsta";
import CountryCodeBarChart from "./CountryCodeBarChartInsta";
import DemographicPieChart from "./DemographicPieChartInsta";

const AudienceDataCharts = ({
  ageRangeData,
  countryCodeData,
  demographicData,
}) => {
  return (
    <div className="card" style={{ borderRadius: "0.75rem" }}>
      <div style={{ padding: "1.5rem" }}>
        <div className="columns is-multiline is-mobile">
          {/* Demographic Pie Chart */}
          <div className="column is-one-third">
            <div className="chart-container">
              <h4 className="title is-6 font-weight-800">
                Followers Demographic
              </h4>
              {demographicData.length > 0 ? (
                <DemographicPieChart data={demographicData} />
              ) : (
                <p>Not enough data available for demographic analytics</p>
              )}
            </div>
          </div>
          {/* Age Range Line Chart */}
          <div className="column is-one-third">
            <div className="chart-container">
              <h4 className="title is-6 font-weight-800">
                Followers Age Range
              </h4>
              {ageRangeData.length > 0 ? (
                <AgeRangeLineChart data={ageRangeData} />
              ) : (
                <p>Not enough data available for age range analytics</p>
              )}
            </div>
          </div>
          {/* Country Code Bar Chart */}
          <div className="column is-one-third">
            <div className="chart-container">
              <h4 className="title is-6 font-weight-800">
                Country Code Distribution
              </h4>
              {countryCodeData?.length > 0 ? (
                <CountryCodeBarChart data={countryCodeData} />
              ) : (
                <p>Not enough data available for country code analytics</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudienceDataCharts;
