import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Config } from "../.env.js";

const FacebookLogin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // const handleLogin = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await fetch(`${Config.API_URL}/auth/facebook/`);
  //     const data = await response.json();

  //     if (data.accessToken && data.instagramAccountId) {
  //       localStorage.setItem("accessToken", data.accessToken);
  //       localStorage.setItem("instagramAccountId", data.instagramAccountId);

  //       console.log("Tokens stored successfully!");

  //       // Redirect to dashboard
  //       navigate(`/influencer/${data.instagramAccountId}`);
  //     } else {
  //       console.error("Error: Missing tokens in response");
  //     }
  //   } catch (error) {
  //     console.error("Login failed:", error);
  //   }

  //   // If no valid token, start Facebook login
  //   window.location.href = `${Config.API_URL}/auth/facebook`;
  // };

  const handleLogin = () => {
    window.location.href = `${Config.API_URL}/auth/facebook`;
  };

  return (
    <div className="container1 imgfull" style={{ justifyContent: "start" }}>
      <div className="section">
        <div className="columns is-centered">
          <div className="column">
            <button
              className="button is-primary is-fullwidth"
              style={{ padding: "30px", fontSize: "20px", fontWeight: "bold" }}
              onClick={handleLogin}
            >
              {/* Influencer Login with Facebook */}
              {loading ? "Checking Login..." : "Influencer Login with Facebook"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacebookLogin;
