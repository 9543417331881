import React, { useState, useEffect } from "react";
import { Config } from "../.env.js";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const InviteForm = ({ onSubmitAdmin, onClose, role: userRole }) => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [emailExists, setEmailExists] = useState(false);
  const [role, setRole] = useState("");
  const [assignBrandName, setAssignBrandName] = useState("");
  const location = useLocation();
  // const { brandNames } = location.state || [];

  // useEffect(() => {
  //     console.log('Location State:', location.state);
  //     console.log('Brand Name:', brandName);
  // }, [location.state, brandName]);
  // const checkEmail = async (email) => {
  //   try {
  //     const response = await axios.post(`${Config.API_URL}/check-email`, {
  //       email,
  //     });
  //     if (response.data.exists) {
  //       setEmailExists(true);
  //       toast.warning(
  //         "This email is already registered. Please use a different email."
  //       );
  //     } else {
  //       setEmailExists(false);
  //     }
  //   } catch (error) {
  //     console.error("Error checking email:", error);
  //     toast.error("An error occurred while checking the email.");
  //   }
  // };
  // const handleEmailChange = (e) => {
  //   const newEmail = e.target.value;
  //   setEmail(newEmail);
  //   if (newEmail.trim()) {
  //     checkEmail(newEmail);
  //   } else {
  //     setEmailExists(false);
  //   }
  // };
  const invitePath = location.pathname;
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const isValidEmail =
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/u.test(email);
      if (!isValidEmail) {
        toast.warning("Please enter a valid email address.");
        return; // Stop submission if email is invalid
      }

      const emailCheckResponse = await fetch(`${Config.API_URL}/check-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (!emailCheckResponse.ok) {
        throw new Error("Failed to check email");
      }

      const emailCheckData = await emailCheckResponse.json();

      if (emailCheckData.exists) {
        toast.warning(
          "This email is already registered. Please use a different email."
        );
        return; // Prevent submission if email exists
      }

      // Make a POST request to the backend API
      const response = await fetch(`${Config.API_URL}/api/invite`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userName,
          email,
          role,
          invitePath,
          // brandName,
        }),
      });
      // console.log("brandName", brandName);
      if (!response.ok) {
        throw new Error("Failed to invite user");
      }
      const newUser = await response.json(); // Get the new user data from the response
      // console.log("New User:", newUser);
      // Update the parent component's user table with the new user
      // setUsers(updateUserTable(users, newUser));
      onSubmitAdmin(newUser); // Reset form fields after successful invitation
      setUserName("");
      setEmail("");
      setRole("");

      // Optionally, you can show a success message to the user
      // console.log("User invited successfully");
      toast.success("User invited successfully");
      //navigate("/userSignUp/:brandName", { replace: true, state: { brandName: brandName } });
      onClose();
    } catch (error) {
      console.error("Error inviting user:", error.message);
      // Optionally, you can show an error message to the user
    }
  };
  return (
    <div className="has-text-centered">
      <form onSubmit={handleSubmit}>
        <div className="field">
          <label className="label" style={{ marginRight: "246px" }}>
            Name
          </label>
          <div className="control">
            <input
              className="input is-rounded"
              type="text "
              placeholder="User Name"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              style={{ width: "300px" }}
            />
          </div>
        </div>
        <div className="field">
          <label className="label " style={{ marginRight: "246px" }}>
            Email Id
          </label>
          <div className="control has-icons-right">
            <input
              className="input is-rounded"
              type="text"
              placeholder="Email ID"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              // onChange={handleEmailChange}
              style={{ width: "300px" }}
            />
          </div>
          {/* {email && !hasValEmail && (
            <small
              className={`ml-1 mt-1  ${
                !hasValEmail && email !== ""
                  ? "has-text-danger"
                  : "has-text-success"
              }`}
            >
              {!hasValEmail && email !== ""
                ? "Enter a valid email address"
                : ""}
            </small>
          )} */}
        </div>
        {/* Role selection dropdown */}
        <div className="field">
          <label className="label" style={{ marginRight: "271px" }}>
            Role
          </label>
          <div className="control">
            <div className="select">
              <select
                style={{ borderRadius: "20px", width: "300px" }}
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="">Select Role</option>
                <option value="admin">Admin</option>
                <option value="lead">Lead</option>
                <option value="executive">Executive</option>
                {/* <option value="brand">Brand</option> */}
              </select>
            </div>
          </div>
        </div>
        <div className="field">
          <div className="control">
            <button
              className="button is-primary is-rounded "
              style={{ width: "300px" }}
              disabled={!userName || !email || !role}
            >
              Invite
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default InviteForm;
