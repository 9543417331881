import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const FakeFollowersPieChartInsta = ({ fakeFollowers, realFollowers }) => {
  const totalFollowers = fakeFollowers + realFollowers;
  const fakePercentage = ((fakeFollowers / totalFollowers) * 100).toFixed(1);
  const realPercentage = ((realFollowers / totalFollowers) * 100).toFixed(1);

  const data = {
    labels: ["Fake Followers", "Real Followers"],
    datasets: [
      {
        data: [fakeFollowers, realFollowers],
        backgroundColor: ["#f14668", "#00d1b2"], // Soft red & green
        hoverOffset: -2,
      },
    ],
  };

  const options = {
    plugins: {
      legend: { display: false }, // Remove Chart.js legend
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            let value = tooltipItem.raw;
            return `  ${value}`; // Adds space before number
          },
        },
        displayColors: true, // Show color box
        boxPadding: 2,
        // padding: 10,
      },
    },
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        padding: "20px 50px",
        background: "#fff",
        borderRadius: "15px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div style={{ flex: 1 }}>
        <h4 className="title is-6 font-weight-800">Real Followers</h4>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <span
            style={{
              width: "12px",
              height: "12px",
              backgroundColor: "#00d1b2",
              borderRadius: "50%",
              display: "inline-block",
              marginRight: "8px",
            }}
          ></span>
          <span style={{ fontWeight: "bold", fontSize: "18px" }}>
            {realFollowers}
          </span>
          <span style={{ marginLeft: "5px", color: "#777" }}>
            Real Followers
          </span>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{
              width: "12px",
              height: "12px",
              backgroundColor: "#00d1b2",
              borderRadius: "50%",
              display: "inline-block",
              marginRight: "8px",
            }}
          ></span>
          <span style={{ fontWeight: "bold", fontSize: "18px" }}>
            {realPercentage}%
          </span>
          <span style={{ marginLeft: "5px", color: "#777" }}>
            {realFollowers > 1 ? "Real Followers" : "Real Follower"}
          </span>
        </div>
      </div>

      <div style={{ flex: 1 }}>
        <h4 className="title is-6 font-weight-800">Fake Followers</h4>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <span
            style={{
              width: "12px",
              height: "12px",
              backgroundColor: "#f14668",
              borderRadius: "50%",
              display: "inline-block",
              marginRight: "8px",
            }}
          ></span>
          <span style={{ fontWeight: "bold", fontSize: "18px" }}>
            {fakeFollowers}
          </span>
          <span style={{ marginLeft: "5px", color: "#777" }}>
            {fakeFollowers > 1 ? "Fake Followers" : "Fake Follower"}
          </span>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{
              width: "12px",
              height: "12px",
              backgroundColor: "#f14668",
              borderRadius: "50%",
              display: "inline-block",
              marginRight: "8px",
            }}
          ></span>
          <span style={{ fontWeight: "bold", fontSize: "18px" }}>
            {fakePercentage}%
          </span>
          <span style={{ marginLeft: "5px", color: "#777" }}>
            Fake Followers
          </span>
        </div>
      </div>

      <div style={{ width: "200px" }}>
        <Pie data={data} options={options} />
      </div>
    </div>
  );
};

export default FakeFollowersPieChartInsta;
