import React, { useState, useEffect } from "react";
import { Config } from "../.env.js";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { updateUserTable } from "../utils/updateUserTable";

const InviteFormBrand = ({
  users,
  setUsers,
  onSubmitBrand,
  onClose,
  fromInternalSignIn,
  showBrandUsers,
  role: userRole,
  brandName: userContextBrandName,
}) => {
  // console.log("Received updateUserTable:", updateUserTable);

  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [assignBrandName, setAssignBrandName] = useState("");
  const [brandNames, setBrandNames] = useState([]); // Store all brand names

  const location = useLocation();
  const brandName = location.state?.brandName || userContextBrandName || "";
  // useEffect(() => {
  //   console.log("Location State:", location.state);
  console.log("Brand Name:", brandName);
  // }, [location.state, brandName]);
  const invitePath = location.pathname;

  // Fetch all brand names when the component mounts
  useEffect(() => {
    const fetchAllBrandNames = async () => {
      try {
        const response = await axios.get(`${Config.API_URL}/brand/all-brands`);
        if (response.data && Array.isArray(response.data)) {
          setBrandNames(response.data);
        } else {
          console.error("Unexpected response for brand names:", response.data);
        }
      } catch (error) {
        console.error("Error fetching brand names:", error);
        toast.error("Failed to load brand names. Please try again.");
      }
    };

    fetchAllBrandNames();
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Assigned Brand Name:", assignBrandName);
    try {
      const isValidEmail =
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/u.test(email);
      if (!isValidEmail) {
        toast.warning("Please enter a valid email address.");
        return; // Stop submission if email is invalid
      }

      const emailCheckResponse = await fetch(`${Config.API_URL}/check-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (!emailCheckResponse.ok) {
        throw new Error("Failed to check email");
      }

      const emailCheckData = await emailCheckResponse.json();

      if (emailCheckData.exists) {
        toast.warning(
          "This email is already registered. Please use a different email."
        );
        return; // Prevent submission if email exists
      }

      // Make a POST request to the backend API
      const response = await fetch(`${Config.API_URL}/api/invite`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userName,
          email,
          role,
          brandName,
          invitePath,
          assignBrandName,
        }),
      });
      // console.log("brandName", brandName);
      if (!response.ok) {
        throw new Error("Failed to invite user");
      }

      const newUser = await response.json(); // Get the new user data from the response
      // console.log("New User:", newUser);
      // Update the parent component's user table with the new user
      // setUsers(updateUserTable(users, newUser));
      onSubmitBrand(newUser);
      // Reset form fields after successful invitation
      setUserName("");
      setEmail("");
      setRole("");
      setAssignBrandName("");

      // Optionally, you can show a success message to the user
      // console.log("User invited successfully");
      // console.log("Invite Path:", invitePath);
      toast.success("User invited successfully");
      //   const updatedUser = await response.json();
      //   console.log("UpdatedUser2:", updatedUser);
      //   updateUserTable(updatedUser); // Update parent component state
      //navigate("/userSignUp/:brandName", { replace: true, state: { brandName: brandName } });
      onClose();
    } catch (error) {
      console.error("Error inviting user:", error.message);
      // Optionally, you can show an error message to the user
    }
  };
  return (
    <div className="has-text-centered">
      <form onSubmit={handleSubmit}>
        <div className="field">
          <label className="label" style={{ marginRight: "246px" }}>
            Name
          </label>
          <div className="control">
            <input
              className="input is-rounded"
              type="text "
              placeholder="User Name"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              style={{ width: "300px" }}
            />
          </div>
        </div>
        <div className="field">
          <label className="label " style={{ marginRight: "246px" }}>
            Email Id
          </label>
          <div className="control has-icons-right">
            <input
              className="input is-rounded"
              type="text"
              placeholder="Email ID"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ width: "300px" }}
            />
          </div>
        </div>
        {/* Brand selection dropdown */}
        {showBrandUsers && (
          <div className="field">
            <label className="label" style={{ marginRight: "271px" }}>
              Brand
            </label>
            <div className="control">
              <div className="select">
                <select
                  style={{ borderRadius: "20px", width: "300px" }}
                  value={assignBrandName}
                  onChange={(e) => setAssignBrandName(e.target.value)}
                >
                  <option value="">Select Brand Name</option>
                  {brandNames.map((name, index) => (
                    <option key={index} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}
        {userRole !== "admin" && (
          <div className="field">
            <label className="label" style={{ marginRight: "271px" }}>
              Brand
            </label>
            <div className="control">
              <div className="select">
                <select
                  style={{ borderRadius: "20px", width: "300px" }}
                  value={assignBrandName}
                  onChange={(e) => setAssignBrandName(e.target.value)}
                >
                  <option value="">Select Brand Name</option>
                  {(brandName?.filter(Boolean) || []).map((name, index) => (
                    <option key={index} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}
        {/* Role selection dropdown */}
        <div className="field">
          <label className="label" style={{ marginRight: "271px" }}>
            Role
          </label>
          <div className="control">
            <div className="select">
              <select
                style={{ borderRadius: "20px", width: "300px" }}
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="">Select Role</option>
                <option value="brand-admin">Admin</option>
                <option value="brand-staff">Staff</option>
              </select>
            </div>
          </div>
        </div>
        <div className="field">
          <div className="control">
            <button
              className="button is-primary is-rounded "
              style={{ width: "300px" }}
              disabled={!userName || !email || !role}
            >
              Invite
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default InviteFormBrand;
