import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CampaignLive from "./CampaignLive";
import { getAllCampaigns } from "../components/campaignApi";
import BrandPage from "./BrandForm";
import { useUser } from "../components/UserContext";

const ITEMS_PER_PAGE = 10;
const PAGES_DISPLAYED = 5;

const ApproveCampaignPage = ({}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("all");
  const [loading, setLoading] = useState(true); // Loading state
  const [showBrandPage, setShowBrandPage] = useState(false);
  // const brandName = location.state ? location.state.brandName : [];
  // const { brandName } = location?.state || "";
  // Fetch updated brand name & display content based on brand name
  const {
    userRole,
    brandName,
    // loading,
    error,
  } = useUser();

  // console.log("Brand Names in GST Page:", brandName);
  console.log("User Role:", userRole);
  console.log("Brand Name:", brandName);

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const allCampaigns = await getAllCampaigns();
        console.log("All Campaigns:", allCampaigns);

        // Ensure brandName is an array and not empty
        if (Array.isArray(brandName)) {
          const validBrandNames = brandName
            .filter((bn) => typeof bn === "string" && bn.trim() !== "")
            .map((bn) => bn.trim().toLowerCase());
          // console.log("Valid Brand Names:", validBrandNames);

          const filteredCampaigns = allCampaigns.filter((campaign) => {
            if (
              Array.isArray(campaign.brandName) &&
              campaign.brandName.length > 0
            ) {
              return campaign.brandName.some((bn) => {
                const cleanedBrandName = (bn || "")
                  .replace(/^,/, "")
                  .trim()
                  .toLowerCase();

                return validBrandNames.includes(cleanedBrandName);
              });
            } else {
              // console.error(
              //   "Campaign brandName is not a valid array:",
              //   campaign
              // );
              return false;
            }
          });
          filteredCampaigns.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

          // console.log("Filtered and Sorted Campaigns:", filteredCampaigns);
          setCampaigns(filteredCampaigns.reverse());
          setLoading(false); // Data loaded, update loading state
        } else {
          console.error("brandName is not an array:", brandName);
        }
      } catch (error) {
        console.error("Error fetching campaigns:", error);
        setLoading(false); // Error occurred, update loading state
      }
    };

    if (brandName) {
      fetchCampaigns();
    }
  }, [brandName]);

  // useEffect(() => {
  //   if (loading) {
  //     console.log("Loading user role...");
  //   }
  // }, [loading]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleFilterChange = (filterValue) => {
    setFilter(filterValue);
    setCurrentPage(1); // Reset page when filter changes
  };

  const filteredCampaigns = campaigns.filter((campaign) => {
    if (filter === "all") return true;
    if (filter === "approved") return campaign.approve;
    if (filter === "pending") return !campaign.approve && !campaign.rejected;
    if (filter === "rejected") return campaign.rejected;
    return true;
  });

  const totalPages = Math.ceil(filteredCampaigns.length / ITEMS_PER_PAGE);

  const startPage = Math.max(
    1,
    Math.min(
      currentPage - Math.floor(PAGES_DISPLAYED / 2),
      totalPages - PAGES_DISPLAYED + 1
    )
  );
  const endPage = Math.min(totalPages, startPage + PAGES_DISPLAYED - 1);

  // Slice the campaigns array to display only the relevant portion for the current page
  const paginatedCampaigns = filteredCampaigns.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  console.log("Filtered Campaigns:", filteredCampaigns);

  const renderCampaigns = () => {
    if (filteredCampaigns.length === 0) {
      if (filter === "all") {
        return (
          <div className="columns is-ancestor">
            {showBrandPage ? (
              <div
                className="is-child has-text-centered mb-5"
                style={{
                  height: "auto",
                  width: "50vw",
                  // marginLeft: "250px"
                }}
              >
                <BrandPage brandName={brandName} />
              </div>
            ) : (
              <div
                className="is-child has-text-centered"
                style={{ width: "80vw", marginTop: "15vw" }}
              >
                <h1 className="subtitle mt-5"> Create New Campaign</h1>
                <button
                  className="button is-large is-rounded is-primary"
                  onClick={() => setShowBrandPage(true)}
                >
                  {" "}
                  Create New Campaign
                </button>
              </div>
            )}
          </div>
        );
      } else {
        return (
          // <div>No {filter} campaigns available.</div>
          <>
            <div
              className="filter-buttons"
              style={{
                display: "flex",
                justifyContent: "space-around",
                border: "1px solid #4a4a4a",
                padding: "7px",
                borderRadius: "8px",
              }}
            >
              {["all", "approved", "pending", "rejected"].map((value) => (
                <button
                  key={value}
                  className={filter === value ? "active" : ""}
                  style={{
                    cursor: "pointer",
                    border: "none",
                    background: "none",
                    color: "#4a4a4a",
                    fontWeight: filter === value ? "bold" : "normal",
                    transition: "color 0.3s",
                  }}
                  onClick={() => handleFilterChange(value)}
                >
                  {value.charAt(0).toUpperCase() + value.slice(1)}
                </button>
              ))}
            </div>
            {loading ? (
              <div className="loading has-text-centered mt-6">Loading...</div>
            ) : (
              <div className="campaigns-container">
                {/* {paginatedCampaigns.length === 0 && ( */}
                <div
                  className="is-child has-text-centered"
                  style={{ width: "80vw", marginTop: "15vw" }}
                >
                  <p>No {filter} campaigns available.</p>
                  {/* <h1 className="subtitle mt-5"> Create New Campaign</h1> */}
                  {campaigns.length === 0 && userRole !== "brand-staff" && (
                    <button
                      className="button is-large is-rounded is-primary mt-5"
                      onClick={() => setShowBrandPage(true)}
                    >
                      {" "}
                      Create New Campaign
                    </button>
                  )}
                </div>
                {/* ) : (
                  paginatedCampaigns.map((campaign, index) => (
                    <div key={index} className="campaign-item is-half">
                      <CampaignLive
                        campaign={campaign}
                        alignLeft={index % 2 === 0}
                      />
                    </div>
                  ))
                )} */}
              </div>
            )}
          </>
        );
      }
    } else {
      return (
        <>
          <div
            className="filter-buttons"
            style={{
              display: "flex",
              justifyContent: "space-around",
              border: "1px solid #4a4a4a",
              padding: "7px",
              borderRadius: "8px",
            }}
          >
            {["all", "approved", "pending", "rejected"].map((value) => (
              <button
                key={value}
                className={filter === value ? "active" : ""}
                style={{
                  cursor: "pointer",
                  border: "none",
                  background: "none",
                  color: "#4a4a4a",
                  fontWeight: filter === value ? "bold" : "normal",
                  transition: "color 0.3s",
                }}
                onClick={() => handleFilterChange(value)}
              >
                {value.charAt(0).toUpperCase() + value.slice(1)}
              </button>
            ))}
          </div>
          {loading ? (
            <div className="loading has-text-centered mt-6">Loading...</div>
          ) : (
            <div className="campaigns-container">
              {paginatedCampaigns.map((campaign, index) => (
                <div key={index} className="campaign-item is-half">
                  <CampaignLive
                    campaign={campaign}
                    alignLeft={index % 2 === 0}
                    role={userRole}
                  />
                </div>
              ))}
              {/* {paginatedCampaigns.length === 0 ? (
                <div
                  className="is-child has-text-centered"
                  style={{ width: "80vw", marginTop: "15vw" }}
                >
                  <p>No {filter} campaigns available.</p>
                  <h1 className="subtitle mt-5"> Create New Campaign</h1>
                  {campaigns.length === 0 && userRole !== "staff" && (
                    <button
                      className="button is-large is-rounded is-primary mt-5"
                      onClick={() => setShowBrandPage(true)}
                    >
                      {" "}
                      Create New Campaign
                    </button>
                  )}
                </div>
              ) 
              : (
                paginatedCampaigns.map((campaign, index) => (
                  <div key={index} className="campaign-item is-half">
                    <CampaignLive
                      campaign={campaign}
                      alignLeft={index % 2 === 0}
                    />
                  </div>
                ))
              )} */}
            </div>
          )}

          {paginatedCampaigns.length > 0 && (
            <div
              className="pagination-container"
              role="navigation"
              aria-label="pagination"
              style={
                {
                  // display: "flex",
                  // flexDirection: "row",
                  // justifyContent: "center",
                  // marginLeft: "30px",
                }
              }
            >
              {currentPage > 1 && (
                <button
                  className="pagination-previous"
                  style={{ cursor: "pointer" }}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Previous
                </button>
              )}

              {Array.from(
                { length: endPage - startPage + 1 },
                (_, i) => startPage + i
              ).map((page) => (
                <button
                  key={page}
                  className={`pagination-link ${
                    page === currentPage ? "is-current" : ""
                  }`}
                  aria-label={`Goto page ${page}`}
                  style={{ cursor: "pointer" }}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              ))}

              {currentPage < totalPages && (
                <button
                  className="pagination-next"
                  style={{ cursor: "pointer" }}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Next
                </button>
              )}
            </div>
          )}
          {/* <div className="dual-campaign-container">
            <div
              className="filter-buttons"
              style={{
                display: "flex",
                justifyContent: "space-around",
                border: "1px solid #4a4a4a",
                padding: "7px",
                borderRadius: "8px",
              }}
            >
              {["all", "approved", "pending", "rejected"].map((value) => (
                <button
                  key={value}
                  className={filter === value ? "active" : ""}
                  style={{
                    cursor: "pointer",
                    border: "none",
                    background: "none",
                    color: "#4a4a4a",
                    fontWeight: filter === value ? "bold" : "normal",
                    transition: "color 0.3s",
                  }}
                  onClick={() => handleFilterChange(value)}
                >
                  {value.charAt(0).toUpperCase() + value.slice(1)}
                </button>
              ))}
            </div>
          </div>
          <div className="campaigns-container">
            {filteredCampaigns
              .slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                currentPage * ITEMS_PER_PAGE
              )
              .map((campaign, index) => (
                <div key={index} className="campaign-item is-half ml-2">
                  <CampaignLive
                    key={index}
                    campaign={campaign}
                    alignLeft={index % 2 === 0}
                  />
                </div>
              ))}
          </div>
          <div
            className="pagination-container"
            role="navigation"
            aria-label="pagination"
          >
            {currentPage > 1 && (
              <button
                className="pagination-previous"
                style={{ cursor: "pointer" }}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </button>
            )}
            {Array.from(
              { length: endPage - startPage + 1 },
              (_, i) => startPage + i
            ).map((page) => (
              <button
                key={page}
                style={{ cursor: "pointer" }}
                className={`pagination-link ${
                  page === currentPage ? "is-current" : ""
                }`}
                aria-label={`Goto page ${page}`}
                onClick={() => handlePageChange(page)}
              >
                {page}
              </button>
            ))}
            {currentPage < totalPages && (
              <button
                className="pagination-next"
                style={{ cursor: "pointer" }}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </button>
            )}
          </div> */}
        </>
      );
    }
  };

  return (
    <div className="dual-campaign-container">
      {loading ? (
        <div classname="loading has-text-centered mt-6">Loading...</div>
      ) : (
        renderCampaigns()
      )}
    </div>

    // <div className="dual-campaign-container">
    //   {showBrandPage ? (
    //     // Render the Create Campaign form
    //     <div
    //       className="is-child  has-text-centered "
    //       style={{ height: "100vh", width: "50vw" }}
    //     >
    //       <h1 className="subtitle mt-5 ">Create New Campaign</h1>
    //       <BrandPage brandName={brandName} />
    //     </div>
    //   ) : (
    //     <div
    //       className="filter-buttons"
    //       style={{
    //         display: "flex",
    //         justifyContent: "space-around",
    //         border: "1px solid #4a4a4a",
    //         padding: "7px",
    //         borderRadius: "8px",
    //       }}
    //     >
    //       {["all", "approved", "pending", "rejected"].map((value) => (
    //         <button
    //           key={value}
    //           className={filter === value ? "active" : ""}
    //           style={{
    //             cursor: "pointer",
    //             border: "none",
    //             background: "none",
    //             color: "#4a4a4a",
    //             fontWeight: filter === value ? "bold" : "normal",
    //             transition: "color 0.3s",
    //           }}
    //           onClick={() => handleFilterChange(value)}
    //         >
    //           {value.charAt(0).toUpperCase() + value.slice(1)}
    //         </button>
    //       ))}
    //     </div>
    //   )}

    //   {loading ? (
    //     <div className="loading has-text-centered mt-6">Loading...</div>
    //   ) : (
    //     <div className="campaigns-container">
    //       {paginatedCampaigns.length === 0 ? (
    //         <div
    //           className="is-child has-text-centered"
    //           style={{ width: "80vw", marginTop: "15vw" }}
    //         >
    //           <p>No {filter} campaigns available.</p>
    //           {/* <h1 className="subtitle mt-5"> Create New Campaign</h1> */}
    //           {campaigns.length === 0 && userRole !== "staff" && (
    //             <button
    //               className="button is-large is-rounded is-primary mt-5"
    //               onClick={() => setShowBrandPage(true)}
    //             >
    //               {" "}
    //               Create New Campaign
    //             </button>
    //           )}
    //         </div>
    //       ) : (
    //         paginatedCampaigns.map((campaign, index) => (
    //           <div key={index} className="campaign-item is-half">
    //             <CampaignLive campaign={campaign} alignLeft={index % 2 === 0} />
    //           </div>
    //         ))
    //       )}
    //     </div>
    //   )}

    //   {paginatedCampaigns.length > 0 && (
    //     <div
    //       className="pagination-container"
    //       role="navigation"
    //       aria-label="pagination"
    //       style={{
    //         // display: "flex",
    //         // flexDirection: "row",
    //         // justifyContent: "center",
    //         marginLeft: "30px",
    //       }}
    //     >
    //       {currentPage > 1 && (
    //         <button
    //           className="pagination-previous"
    //           style={{ cursor: "pointer" }}
    //           onClick={() => handlePageChange(currentPage - 1)}
    //         >
    //           Previous
    //         </button>
    //       )}

    //       {Array.from(
    //         { length: endPage - startPage + 1 },
    //         (_, i) => startPage + i
    //       ).map((page) => (
    //         <button
    //           key={page}
    //           className={`pagination-link ${
    //             page === currentPage ? "is-current" : ""
    //           }`}
    //           aria-label={`Goto page ${page}`}
    //           style={{ cursor: "pointer" }}
    //           onClick={() => handlePageChange(page)}
    //         >
    //           {page}
    //         </button>
    //       ))}

    //       {currentPage < totalPages && (
    //         <button
    //           className="pagination-next"
    //           style={{ cursor: "pointer" }}
    //           onClick={() => handlePageChange(currentPage + 1)}
    //         >
    //           Next
    //         </button>
    //       )}
    //     </div>
    //   )}
    // </div>
  );
};

export default ApproveCampaignPage;
