import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  approveCampaignLead,
  getInfluencerCampaigns,
  rejectCampaignLead,
  onHoldCampaignLead,
  rejectDeliverableLead,
  onHoldDeliverableLead,
  rejectConfirmationLead,
  onHoldConfirmationLead,
} from "../components/infApi";
import { useParams } from "react-router-dom";

import { getInfluencerDeliverables } from "../components/api"; // Import the API function for approving influencers
import { approvedLead } from "../components/infApi";
import { Config } from "../.env.js";

import { getInfluencerConformation } from "../components/api"; // Import the API function for approving influencers
import { approvelLead } from "../components/infApi";
import { toast } from "react-toastify";
import { mergeInfluencerData } from "../utils/mergeInfluencerData";
// import {
//   handleRejectCampaign,
//   handleRejectDeliverables,
//   handleRejectConfirmation,
// } from "../utils/rejectHandlers";

const InfluencersTablesLead = () => {
  const [loading, setLoading] = useState(true);

  const [influencerData, setInfluencerData] = useState({
    campaigns: [],
    deliverables: [],
    confirmations: [],
  });
  const [error, setError] = useState(null);
  const { campaignName } = useParams();
  const location = useLocation();
  const role = location.state?.role;
  console.log("Role:", role);
  const API_URL = `${Config.API_URL}`;
  useEffect(() => {
    fetchData();
  }, [campaignName]);

  const fetchData = async () => {
    try {
      const campaignsResponse = await getInfluencerCampaigns();
      const deliverablesResponse = await getInfluencerDeliverables();
      const confirmationsResponse = await getInfluencerConformation();

      if (
        campaignsResponse.ok &&
        deliverablesResponse.ok &&
        confirmationsResponse.ok
      ) {
        const campaignsData = await campaignsResponse.json();
        const deliverablesData = await deliverablesResponse.json();
        const confirmationsData = await confirmationsResponse.json();

        // let filteredCampaigns = campaignsData.filter(
        //   (campaign) =>
        //     (campaign.approvedbyExecutive && campaign.isLead) ||
        //     (!campaign.isExecutive &&
        //       campaign.isLead &&
        //       campaign.campaignName === campaignName)
        // );

        const filteredCampaigns = campaignsData.filter(
          (campaign) =>
            campaign.isLead && campaign.campaignName === campaignName
        );

        // filteredCampaigns = filteredCampaigns.filter(campaign => {
        //   if (campaign.isExecutive && !campaign.approvedbyExecutive) {
        //     return false;
        //   }
        //   if (campaign.isLead && !campaign.approvedbyLead) {
        //     return false;
        //   }

        //   return true;
        // });

        // const filteredDeliverables = deliverablesData.filter(
        //   (deliverables) =>
        //     (deliverables.approvedbyExecutive && deliverables.isleadContent) ||
        //     (!deliverables.isexecutiveContent &&
        //       deliverables.isleadContent &&
        //       deliverables.campaignName === campaignName)
        // );

        const filteredDeliverables = deliverablesData.filter(
          (deliverables) =>
            deliverables.isleadContent &&
            deliverables.campaignName === campaignName
        );

        // filteredDeliverables = filteredDeliverables.filter(deliverables => {
        //   if (deliverables.isexecutiveContent) {
        //     if (!deliverables.approvedbyExecutive) {
        //       return false;
        //     }
        //   }
        //   if (deliverables.isleadContent) {
        //     if (!deliverables.approvedbyLead) {
        //       return false;
        //     }
        //   }
        // if (deliverables.isadminContent) {
        //   if (!deliverables.approvedbyAdmin) {
        //     return false;
        //   }
        // }
        //   return true;
        // });
        // Merge deliverables data into campaigns data if platform and platform username match

        // const filteredConfirmations = confirmationsData.filter(
        //   (conformation) =>
        //     (conformation.approvedbyExecutive && conformation.isleadContent) ||
        //     (!conformation.isexecutiveContent &&
        //       conformation.isleadContent &&
        //       conformation.campaignName === campaignName)
        // );

        const filteredConfirmations = confirmationsData.filter(
          (conformation) =>
            (conformation.isleadContent || conformation.isLead) &&
            conformation.campaignName === campaignName
        );

        // filteredConfirmations = confirmationsData.filter(confirmation => {
        //   if (confirmation.isexecutiveContent) {
        //     if (!confirmation.approvedbyExecutive) {
        //       return true;
        //     }
        //   }
        //   if (confirmation.isleadContent) {
        //     if (!confirmation.approvedbyLead) {
        //       return true;
        //     }
        //   }
        //   return true;
        // });
        const mergedData = filteredCampaigns.map((campaign) => {
          const deliverable = filteredDeliverables.find(
            (d) => d.FullName === campaign.FullName
          );
          const confirmation = filteredConfirmations.find(
            (c) => c.FullName === campaign.FullName
          );

          return {
            ...campaign,
            ...(deliverable ? { deliverable } : {}),
            ...(confirmation ? { confirmation } : {}),
          };
        });

        setInfluencerData({
          campaigns: mergedData,
          deliverables: filteredDeliverables,
          confirmations: filteredConfirmations,
        });
      } else {
        setError("Failed to fetch influencer data");
      }
    } catch (error) {
      setError("Error fetching influencer data: " + error.message);
    } finally {
      setLoading(false); // Stop loading after fetch
    }
  };

  if (loading) {
    return (
      <div className="container">
        <h2 style={{ marginTop: "30px" }}>Loading...</h2>
      </div>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  // const handleSelect = async (campaignId) => {
  //   try {
  //     const response = await approveCampaignBrand(campaignId);
  //     if (response.ok) {
  //       const updatedCampaigns = influencerData.campaigns.map(campaign => {
  //         if (campaign._id === campaignId) {
  //           return { ...campaign, approvedbybrand: true };
  //         }
  //         return campaign;
  //       });
  //       setInfluencerData({ ...influencerData, campaigns: updatedCampaigns });
  //     } else {
  //       console.error('Failed to approve campaign:', response.statusText);
  //     }
  //   } catch (error) {
  //     console.error('Error approving campaign:', error.message);
  //   }
  // };

  const handleApprove = async (campaignId) => {
    try {
      // console.log("Campaign approved:", campaignId);
      // Call the API function to approve the campaign
      const response = await approveCampaignLead(campaignId);
      const influencerData = await response.json();

      if (response.ok) {
        // Display the message "Campaign approved"
        // toast.success("Campaign selected successfully");
        toast.success("Approved successfully");
        // Update the campaigns state to reflect the change
        // const updatedCampaigns = influencerData.campaigns.map((campaign) => {
        //   if (campaign._id === campaignId) {
        //     return { ...campaign, approvedbyLead: true };
        //   }
        //   return campaign;
        // });
        // setInfluencerData({ ...influencerData, campaigns: updatedCampaigns });
        setInfluencerData((prevData) => ({
          ...prevData,
          campaigns: prevData.campaigns.map((campaign) =>
            campaign._id === campaignId
              ? {
                  ...campaign,
                  approvedbyLead: true,
                  rejectedbyLead: false,
                  rejectionRemarksLead: null,
                  onHoldByLead: false,
                }
              : campaign
          ),
        }));
      } else {
        console.error("Failed to approve campaign:", response.statusText);
      }
    } catch (error) {
      console.error("Error approving campaign:", error.message);
    }
  };

  const handleReject = async (campaignId) => {
    try {
      // console.log("Campaign approved:", campaignId);
      // Call the API function to approve the campaign
      const promptMessage =
        "Influencer selection rejected. Please provide remarks:";
      const remarks = prompt(promptMessage);
      if (remarks === null) {
        return;
      }
      const response = await rejectCampaignLead(campaignId, remarks);
      const influencerData = await response.json();

      if (response.ok) {
        // Display the message "Campaign approved"
        // toast.success("Campaign selected successfully");
        toast.success("Rejected successfully");
        // Update the campaigns state to reflect the change
        // const updatedCampaigns = influencerData.campaigns.map((campaign) => {
        //   if (campaign._id === campaignId) {
        //     return { ...campaign, approvedbyExecutive: true };
        //   }
        //   return campaign;
        // });
        // setInfluencerData({ ...influencerData, campaigns: updatedCampaigns });
        setInfluencerData((prevData) => ({
          ...prevData,
          campaigns: prevData.campaigns.map((campaign) =>
            campaign._id === campaignId
              ? {
                  ...campaign,
                  rejectedbyLead: true,
                  rejectionRemarksLead: remarks,
                  approvedbyLead: false,
                  onHoldByLead: false,
                }
              : campaign
          ),
        }));
      } else {
        console.error("Failed to reject influencer:", response.statusText);
      }
    } catch (error) {
      console.error("Error rejecting influencer:", error.message);
    }
  };

  const handleOnHold = async (campaignId) => {
    try {
      console.log("Handle executed", campaignId);

      // Check if function exists
      if (!onHoldCampaignLead) {
        console.error("API function not defined");
        return;
      }

      console.log("Calling API...");
      const response = await onHoldCampaignLead(campaignId);
      console.log("API called, awaiting response...");

      console.log("Response received:", response);
      if (!response) {
        console.error("No response received");
        return;
      }

      const influencerData = await response.json();
      console.log("Data:", influencerData);

      console.log("Response Status:", response.ok);
      if (response.ok) {
        toast.success("Put On Hold successfully");
        setInfluencerData((prevData) => ({
          ...prevData,
          campaigns: prevData.campaigns.map((campaign) =>
            campaign._id === campaignId
              ? {
                  ...campaign,
                  onHoldByLead: true,
                  approvedbyLead: false,
                  rejectedbyLead: false,
                  rejectionRemarksLead: null,
                }
              : campaign
          ),
        }));
      } else {
        console.error("Failed to update influencer:", response.statusText);
      }
    } catch (error) {
      console.error("Error in handleOnHold:", error);
    }
  };

  // const handleReject = async (campaignId) => {
  //   try {
  //     const remarks = prompt('Campaign rejected. Please provide remarks:');
  //     if (remarks === null) {
  //       return;
  //     }
  //     const response = await rejectCampaignBrand(campaignId, remarks);
  //     if (response.ok) {
  //       console.log('Campaign rejected successfully');
  //     } else {
  //       console.error('Failed to reject campaign:', response.statusText);
  //     }
  //   } catch (error) {
  //     console.error('Error rejecting campaign:', error.message);
  //   }
  // };

  const handleApproveDeliverable = async (influencerId) => {
    try {
      const response = await approvedLead(influencerId);
      if (response.ok) {
        // const updatedDeliverables = influencerData.deliverables.map(
        //   (deliverable) => {
        //     if (deliverable._id === influencerId) {
        //       return { ...deliverable, approvedbyBrand: true };
        //     }
        //     return deliverable;
        //   }
        // );
        // setInfluencerData({
        //   ...influencerData,
        //   deliverables: updatedDeliverables,
        // });
        setInfluencerData((prevData) => {
          console.log("Updating state");

          if (prevData.campaigns.length > 0) {
            console.log("Campaigns array is not empty");

            return {
              ...prevData,
              campaigns: prevData.campaigns.map((campaign) => {
                console.log("Checking campaign:", campaign);

                if (
                  campaign.deliverable &&
                  campaign.deliverable._id === influencerId
                ) {
                  console.log(
                    "Updating campaign confirmation for:",
                    campaign.deliverable._id
                  );

                  return {
                    ...campaign,
                    deliverable: {
                      ...campaign.deliverable,
                      approvedbyLead: true,
                      rejectedbyLead: false,
                      rejectionRemarksLead: null,
                      onHoldByLead: false,
                    },
                  };
                }

                return campaign;
              }),
            };
          } else {
            console.log(
              "Campaigns array is empty, updating confirmations instead"
            );

            return {
              ...prevData,
              deliverables: prevData.deliverables.map((deliverable) => {
                console.log("Checking confirmation:", deliverable);

                if (deliverable._id === influencerId) {
                  console.log("Updating confirmation for:", deliverable._id);

                  return {
                    ...deliverable,
                    approvedbyLead: true,
                    rejectedbyLead: false,
                    rejectionRemarksLead: null,
                    onHoldByLead: false,
                  };
                }

                return deliverable;
              }),
            };
          }
        });
        // setInfluencerData((prevData) => {
        //   return {
        //     ...prevData,
        //     campaigns: prevData.campaigns.map((campaign) => {
        //       if (
        //         campaign.infDeliverables &&
        //         campaign.infDeliverables._id === influencerId
        //       ) {
        //         return {
        //           ...campaign,
        //           infDeliverables: {
        //             ...campaign.infDeliverables, // Spread existing deliverable properties
        //             approvedbyLead: true,
        //             rejectedbyLead: false,
        //             rejectionRemarksLead: null,
        //             onHoldByLead: false,
        //           },
        //         };
        //       }
        //       return campaign;
        //     }),
        //   };
        // });
        // window.alert('Approved successfully');
        toast.success("Approved successfully");
      } else {
        console.error("Failed to approve deliverable:", response.statusText);
      }
    } catch (error) {
      console.error("Error approving deliverable:", error.message);
    }
  };

  const handleRejectDeliverable = async (influencerId) => {
    try {
      // console.log("Campaign approved:", campaignId);
      // Call the API function to approve the campaign
      const promptMessage =
        "Influencer selection rejected. Please provide remarks:";
      const remarks = prompt(promptMessage);
      if (remarks === null) {
        return;
      }
      const response = await rejectDeliverableLead(influencerId, remarks);
      const influencerData = await response.json();

      if (response.ok) {
        // Display the message "Campaign approved"
        // toast.success("Campaign selected successfully");
        toast.success("Rejected successfully");
        // Update the campaigns state to reflect the change
        // const updatedCampaigns = influencerData.campaigns.map((campaign) => {
        //   if (campaign._id === campaignId) {
        //     return { ...campaign, approvedbyExecutive: true };
        //   }
        //   return campaign;
        // });
        // setInfluencerData({ ...influencerData, campaigns: updatedCampaigns });
        setInfluencerData((prevData) => {
          console.log("Updating state");

          if (prevData.campaigns.length > 0) {
            console.log("Campaigns array is not empty");

            return {
              ...prevData,
              campaigns: prevData.campaigns.map((campaign) => {
                console.log("Checking campaign:", campaign);

                if (
                  campaign.deliverable &&
                  campaign.deliverable._id === influencerId
                ) {
                  console.log(
                    "Updating campaign confirmation for:",
                    campaign.deliverable._id
                  );

                  return {
                    ...campaign,
                    deliverable: {
                      ...campaign.deliverable,
                      rejectedbyLead: true,
                      rejectionRemarksLead: remarks,
                      approvedbyLead: false,
                      onHoldByLead: false,
                    },
                  };
                }

                return campaign;
              }),
            };
          } else {
            console.log(
              "Campaigns array is empty, updating confirmations instead"
            );

            return {
              ...prevData,
              deliverables: prevData.deliverables.map((deliverable) => {
                console.log("Checking confirmation:", deliverable);

                if (deliverable._id === influencerId) {
                  console.log("Updating confirmation for:", deliverable._id);

                  return {
                    ...deliverable,
                    rejectedbyLead: true,
                    rejectionRemarksLead: remarks,
                    approvedbyLead: false,
                    onHoldByLead: false,
                  };
                }

                return deliverable;
              }),
            };
          }
        });
        // setInfluencerData((prevData) => {
        //   if (!prevData || !prevData.campaigns) return prevData;
        //   return {
        //     ...prevData,
        //     campaigns: prevData.campaigns.map((campaign) => {
        //       if (
        //         campaign.infDeliverables &&
        //         campaign.infDeliverables._id === influencerId
        //       ) {
        //         return {
        //           ...campaign,
        //           infDeliverables: {
        //             ...campaign.infDeliverables, // Spread existing deliverable properties
        //             rejectedbyLead: true,
        //             rejectionRemarksLead: remarks,
        //             approvedbyLead: false,
        //             onHoldByLead: false,
        //           },
        //         };
        //       }
        //       return campaign;
        //     }),
        //   };
        // });
      } else {
        console.error("Failed to reject influencer:", response.statusText);
      }
    } catch (error) {
      console.error("Error rejecting influencer:", error.message);
    }
  };

  const handleOnHoldDeliverable = async (influencerId) => {
    try {
      console.log("Handle executed", influencerId);

      // Check if function exists
      if (!onHoldDeliverableLead) {
        console.error("API function not defined");
        return;
      }

      console.log("Calling API...");
      const response = await onHoldDeliverableLead(influencerId);
      console.log("API called, awaiting response...");

      console.log("Response received:", response);
      if (!response) {
        console.error("No response received");
        return;
      }

      const influencerData = await response.json();
      console.log("Data:", influencerData);

      console.log("Response Status:", response.ok);
      if (response.ok) {
        toast.success("Put On Hold successfully");
        setInfluencerData((prevData) => {
          console.log("Updating state");

          if (prevData.campaigns.length > 0) {
            console.log("Campaigns array is not empty");

            return {
              ...prevData,
              campaigns: prevData.campaigns.map((campaign) => {
                console.log("Checking campaign:", campaign);

                if (
                  campaign.deliverable &&
                  campaign.deliverable._id === influencerId
                ) {
                  console.log(
                    "Updating campaign confirmation for:",
                    campaign.deliverable._id
                  );

                  return {
                    ...campaign,
                    deliverable: {
                      ...campaign.deliverable,
                      onHoldByLead: true,
                      approvedbyLead: false,
                      rejectedbyLead: false,
                      rejectionRemarksLead: null,
                    },
                  };
                }

                return campaign;
              }),
            };
          } else {
            console.log(
              "Campaigns array is empty, updating confirmations instead"
            );

            return {
              ...prevData,
              deliverables: prevData.deliverables.map((deliverable) => {
                console.log("Checking confirmation:", deliverable);

                if (deliverable._id === influencerId) {
                  console.log("Updating confirmation for:", deliverable._id);

                  return {
                    ...deliverable,
                    onHoldByLead: true,
                    approvedbyLead: false,
                    rejectedbyLead: false,
                    rejectionRemarksLead: null,
                  };
                }

                return deliverable;
              }),
            };
          }
        });
        // setInfluencerData((prevData) => {
        //   if (!prevData || !prevData.campaigns) return prevData;
        //   return {
        //     ...prevData,
        //     campaigns: prevData.campaigns.map((campaign) => {
        //       if (
        //         campaign.infDeliverables &&
        //         campaign.infDeliverables._id === influencerId
        //       ) {
        //         return {
        //           ...campaign,
        //           infDeliverables: {
        //             ...campaign.infDeliverables, // Spread existing deliverable properties
        //             onHoldByLead: true,
        //             approvedbyLead: false,
        //             rejectedbyLead: false,
        //             rejectionRemarksLead: null,
        //           },
        //         };
        //       }
        //       return campaign;
        //     }),
        //   };
        // });
      } else {
        console.error("Failed to update influencer:", response.statusText);
      }
    } catch (error) {
      console.error("Error in handleOnHold:", error);
    }
  };

  const handleApproveConformation = async (influencerId) => {
    try {
      const response = await approvelLead(influencerId);
      if (response.ok) {
        // const updatedConfirmations = influencerData.confirmations.map(
        //   (confirmation) => {
        //     if (confirmation._id === influencerId) {
        //       return { ...confirmation, approvedbyBrand: true };
        //     }
        //     return confirmation;
        //   }
        // );
        // setInfluencerData({
        //   ...influencerData,
        //   confirmations: updatedConfirmations,
        // });
        setInfluencerData((prevData) => {
          console.log("Updating state");

          if (prevData.campaigns.length > 0) {
            console.log("Campaigns array is not empty");

            return {
              ...prevData,
              campaigns: prevData.campaigns.map((campaign) => {
                console.log("Checking campaign:", campaign);

                if (
                  campaign.confirmation &&
                  campaign.confirmation._id === influencerId
                ) {
                  console.log(
                    "Updating campaign confirmation for:",
                    campaign.confirmation._id
                  );

                  return {
                    ...campaign,
                    confirmation: {
                      ...campaign.confirmation,
                      approvedbyLead: true,
                      rejectedbyLead: false,
                      rejectionRemarksLead: null,
                      onHoldByLead: false,
                    },
                  };
                }

                return campaign;
              }),
            };
          } else {
            console.log(
              "Campaigns array is empty, updating confirmations instead"
            );

            return {
              ...prevData,
              confirmations: prevData.confirmations.map((confirmation) => {
                console.log("Checking confirmation:", confirmation);

                if (confirmation._id === influencerId) {
                  console.log("Updating confirmation for:", confirmation._id);

                  return {
                    ...confirmation,
                    approvedbyLead: true,
                    rejectedbyLead: false,
                    rejectionRemarksLead: null,
                    onHoldByLead: false,
                  };
                }

                return confirmation;
              }),
            };
          }
        });
        // setInfluencerData((prevData) => {
        //   return {
        //     ...prevData,
        //     campaigns: prevData.campaigns.map((campaign) => {
        //       if (
        //         campaign.confirmation &&
        //         campaign.confirmation._id === influencerId
        //       ) {
        //         return {
        //           ...campaign,
        //           confirmation: {
        //             ...campaign.confirmation, // Spread existing deliverable properties
        //             approvedbyLead: true,
        //             rejectedbyLead: false,
        //             rejectionRemarksLead: null,
        //             onHoldByLead: false,
        //           },
        //         };
        //       }
        //       return campaign;
        //     }),
        //   };
        // });
        // window.alert('Approved successfully');
        toast.success("Updated successfully");
      } else {
        console.error("Failed to approve confirmation:", response.statusText);
      }
    } catch (error) {
      console.error("Error approving confirmation:", error.message);
    }
  };

  const handleRejectConfirmation = async (influencerId) => {
    try {
      // console.log("Campaign approved:", campaignId);
      // Call the API function to approve the campaign
      const promptMessage =
        "Influencer selection rejected. Please provide remarks:";
      const remarks = prompt(promptMessage);
      if (remarks === null) {
        return;
      }
      const response = await rejectConfirmationLead(influencerId, remarks);
      const influencerData = await response.json();

      if (response.ok) {
        // Display the message "Campaign approved"
        // toast.success("Campaign selected successfully");
        toast.success("Updated successfully");
        // Update the campaigns state to reflect the change
        // const updatedCampaigns = influencerData.campaigns.map((campaign) => {
        //   if (campaign._id === campaignId) {
        //     return { ...campaign, approvedbyExecutive: true };
        //   }
        //   return campaign;
        // });
        // setInfluencerData({ ...influencerData, campaigns: updatedCampaigns });
        setInfluencerData((prevData) => {
          if (prevData.campaigns.length > 0) {
            // Update campaigns if it's not empty
            return {
              ...prevData,
              campaigns: prevData.campaigns.map((campaign) =>
                campaign.confirmation &&
                campaign.confirmation._id === influencerId
                  ? {
                      ...campaign,
                      confirmation: {
                        ...campaign.confirmation,
                        rejectedbyLead: true,
                        rejectionRemarksLead: remarks,
                        approvedbyLead: false,
                        onHoldByLead: false,
                      },
                    }
                  : campaign
              ),
            };
          } else {
            // If campaigns array is empty, update confirmations instead
            return {
              ...prevData,
              confirmations: prevData.confirmations.map((confirmation) =>
                confirmation._id === influencerId
                  ? {
                      ...confirmation,
                      rejectedbyLead: true,
                      rejectionRemarksLead: remarks,
                      approvedbyLead: false,
                      onHoldByLead: false,
                    }
                  : confirmation
              ),
            };
          }
        });
        // setInfluencerData((prevData) => {
        //   return {
        //     ...prevData,
        //     campaigns: prevData.campaigns.map((campaign) => {
        //       if (
        //         campaign.confirmation &&
        //         campaign.confirmation._id === influencerId
        //       ) {
        //         return {
        //           ...campaign,
        //           confirmation: {
        //             ...campaign.confirmation, // Spread existing deliverable properties
        //             rejectedbyLead: true,
        //             rejectionRemarksLead: remarks,
        //             approvedbyLead: false,
        //             onHoldByLead: false,
        //           },
        //         };
        //       }
        //       return campaign;
        //     }),
        //   };
        // });
      } else {
        console.error("Failed to reject influencer:", response.statusText);
      }
    } catch (error) {
      console.error("Error rejecting influencer:", error.message);
    }
  };

  const handleOnHoldConfirmation = async (influencerId) => {
    try {
      console.log("Handle executed", influencerId);

      // Check if function exists
      if (!onHoldConfirmationLead) {
        console.error("API function not defined");
        return;
      }

      console.log("Calling API...");
      const response = await onHoldConfirmationLead(influencerId);
      console.log("API called, awaiting response...");

      console.log("Response received:", response);
      if (!response) {
        console.error("No response received");
        return;
      }

      const influencerData = await response.json();
      console.log("Data:", influencerData);

      console.log("Response Status:", response.ok);
      if (response.ok) {
        toast.success("Put On Hold successfully");
        setInfluencerData((prevData) => {
          if (prevData.campaigns.length > 0) {
            // Update campaigns if it's not empty
            return {
              ...prevData,
              campaigns: prevData.campaigns.map((campaign) =>
                campaign.confirmation &&
                campaign.confirmation._id === influencerId
                  ? {
                      ...campaign,
                      confirmation: {
                        ...campaign.confirmation,
                        onHoldByLead: true,
                        approvedbyLead: false,
                        rejectedbyLead: false,
                        rejectionRemarksLead: null,
                      },
                    }
                  : campaign
              ),
            };
          } else {
            // If campaigns array is empty, update confirmations instead
            return {
              ...prevData,
              confirmations: prevData.confirmations.map((confirmation) =>
                confirmation._id === influencerId
                  ? {
                      ...confirmation,
                      onHoldByLead: true,
                      approvedbyLead: false,
                      rejectedbyLead: false,
                      rejectionRemarksLead: null,
                    }
                  : confirmation
              ),
            };
          }
        });
        // setInfluencerData((prevData) => {
        //   return {
        //     ...prevData,
        //     campaigns: prevData.campaigns.map((campaign) => {
        //       if (
        //         campaign.confirmation &&
        //         campaign.confirmation._id === influencerId
        //       ) {
        //         console.log("Updating campaign:", campaign);
        //         return {
        //           ...campaign, // Create a new campaign object
        //           confirmation: {
        //             ...campaign.confirmation, // Create a new confirmation object
        //             onHoldByLead: true,
        //             approvedbyLead: false,
        //             rejectedbyLead: false,
        //             rejectionRemarksLead: null,
        //           },
        //         };
        //       }
        //       return { ...campaign }; // Ensure a new object reference
        //     }),
        //   };
        // });
      } else {
        console.error("Failed to update influencer:", response.statusText);
      }
    } catch (error) {
      console.error("Error in handleOnHold:", error);
    }
  };

  // console.log("Influencer Data:", influencerData);

  const mergedInfluencerData = mergeInfluencerData(influencerData);
  console.log("Full Merge Data:", mergedInfluencerData);

  return (
    <div className="container" style={{ overflowY: "auto" }}>
      <h2 style={{ margin: "10px 0 10px 0" }}>Influencer Data</h2>
      {/* {error && <p>Error: {error}</p>} */}
      {influencerData.campaigns.length !== 0 ||
      influencerData.confirmations.length !== 0 ||
      influencerData.deliverables.length !== 0 ? (
        <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable is-responsive">
          <thead>
            <tr>
              <th className="customStyle">Full Name </th>
              <th className="customStyle">Phone Number</th>
              <th className="customStyle">WhatsappNumber</th>
              <th className="customStyle">Email</th>
              <th className="customStyle">Gender</th>
              <th className="customStyle">Age</th>
              <th className="customStyle">Selected Category</th>
              <th className="customStyle">Languages</th>
              <th className="customStyle">Celebrity Type</th>
              <th className="customStyle">Deal Type</th>
              <th className="customStyle">Campaign Type</th>
              <th className="customStyle">Social Media Platform</th>
              <th className="customStyle">Instagram Insights</th>
              <th className="customStyle">Executive's Action</th>
              <th className="customStyle">Lead's Action</th>
              <th className="customStyle">Action</th>
              {/* <th className="customStyle">Full Name</th> */}
              {/* <th className="customStyle">Uploaded Videos</th>
              <th className="customStyle">Uploaded Raw Videos</th>
              <th className="customStyle">Action</th> */}
              {/* <th className="customStyle">Full Name</th> */}
              <th className="customStyle">Pincode</th>
              <th className="customStyle">Full Address</th>
              <th className="customStyle">Landmark</th>
              <th className="customStyle">City</th>
              <th className="customStyle">State</th>
              <th className="customStyle">Revert or payment </th>
              <th className="customStyle">Executive's Action</th>
              <th className="customStyle">Lead's Action</th>
              <th className="customStyle">Action</th>
              <th className="customStyle">Uploaded Videos</th>
              <th className="customStyle">Uploaded Raw Videos</th>
              <th className="customStyle">Executive's Action</th>
              <th className="customStyle">Lead's Action</th>
              <th className="customStyle">Action</th>
              <th className="customStyle">Live Links</th>
            </tr>
          </thead>
          <tbody>
            {mergedInfluencerData.map((influencer, index) => (
              <tr key={index}>
                <th className="customStyle">
                  {influencer.FullName || "Not Available"}
                </th>
                {influencer.confirmations.length > 0 ? (
                  <>
                    <td className="customStyle">
                      {influencer.PhoneNumber || "Not Available"}
                    </td>
                    <td className="customStyle">
                      {influencer.WhatsappNumber || "Not Available"}
                    </td>
                    <td className="customStyle">
                      {influencer.Email || "Not Available"}
                    </td>
                  </>
                ) : (
                  <>
                    <td className="customStyle">Hidden</td>
                    <td className="customStyle">Hidden</td>
                    <td className="customStyle">Hidden</td>
                  </>
                )}
                {/* <td className="customStyle">
                  {influencer.PhoneNumber || "Not Available"}
                </td>
                <td className="customStyle">
                  {influencer.WhatsappNumber || "Not Available"}
                </td>
                <td className="customStyle">
                  {influencer.Email || "Not Available"}
                </td> */}
                <td className="customStyle">
                  {influencer.Gender || "Not Available"}
                </td>
                <td className="customStyle">
                  {influencer.Age || "Not Available"}
                </td>
                <td className="customStyle">
                  {influencer.selectedCategory || "Not Available"}
                </td>
                <td className="customStyle">
                  {influencer.Languages || "Not Available"}
                </td>
                <td className="customStyle">
                  {influencer.CelebrityType || "Not Available"}
                </td>
                <td className="customStyle">
                  {influencer.DealType || "Not Available"}
                </td>
                <td className="customStyle">
                  {influencer.CampaignType || "Not Available"}
                </td>
                <td className="customStyle">
                  {influencer.platforms?.map((platform, idx) => (
                    <div key={idx}>
                      <strong>{platform.platformName}: </strong>
                      (username: {platform.username}) (Followers:{" "}
                      {platform.followerRange})
                    </div>
                  )) || "No platforms selected"}
                </td>
                <td className="customStyle">
                  {influencer?.instagramAccountId?.trim() ? (
                    <button
                      // onClick={() =>
                      //   (window.location.href = `/influencer/${influencer.instagramAccountId}`)
                      // }
                      onClick={() =>
                        window.open(
                          `/influencer/${influencer.instagramAccountId}`,
                          "_blank"
                        )
                      }
                      style={{
                        background:
                          "linear-gradient(45deg, #f09433, #e6683c, #dc2743, #cc2366, #bc1888)", // Instagram gradient
                        color: "white",
                        fontWeight: "bold",
                        border: "none",
                        padding: "10px 20px",
                        borderRadius: "30px",
                        fontSize: "16px",
                        cursor: "pointer",
                        transition: "transform 0.2s ease-in-out",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "8px",
                      }}
                      onMouseOver={(e) =>
                        (e.currentTarget.style.transform = "scale(1.05)")
                      } // Hover effect
                      onMouseOut={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    >
                      <i className="fab fa-instagram"></i>{" "}
                      <span style={{ marginTop: "-2px" }}>View Insights</span>
                    </button>
                  ) : (
                    <span
                    // style={{ color: "gray", fontStyle: "italic" }}
                    >
                      Not Available
                    </span>
                  )}
                </td>
                <td>
                  {influencer?.isExecutive
                    ? influencer.approvedbyExecutive
                      ? "Approved"
                      : influencer.rejectedbyExecutive
                      ? `Rejected - ${influencer.rejectionRemarksExecutive}`
                      : influencer.onHoldByExecutive
                      ? "On hold"
                      : "No Action Taken"
                    : "Not Required"}
                </td>
                <td>
                  {influencer?.isLead
                    ? influencer.approvedbyLead
                      ? "Approved"
                      : influencer.rejectedbyLead
                      ? `Rejected - ${influencer.rejectionRemarksLead}`
                      : influencer.onHoldByLead
                      ? "On hold"
                      : "No Action Taken"
                    : "Not Required"}
                </td>
                <td className="customStyle">
                  <button
                    onClick={() => handleApprove(influencer._id)}
                    className="button is-primary is-rounded"
                    disabled={
                      !influencer?.isLead ||
                      influencer?.approvedbyAdmin ||
                      influencer?.rejectedbyAdmin ||
                      influencer?.onHoldByAdmin ||
                      (influencer?.isBrand &&
                        (influencer?.approvedbyBrand ||
                          influencer?.rejectedbyBrand ||
                          influencer?.onHoldByBrand))
                    }
                  >
                    Approve
                  </button>
                  <button
                    onClick={() =>
                      handleReject(
                        influencer._id
                        // setInfluencerData
                      )
                    }
                    className="button is-danger is-rounded ml-3"
                    disabled={
                      !influencer?.isLead ||
                      influencer?.approvedbyAdmin ||
                      influencer?.rejectedbyAdmin ||
                      influencer?.onHoldByAdmin ||
                      (influencer?.isBrand &&
                        (influencer?.approvedbyBrand ||
                          influencer?.rejectedbyBrand ||
                          influencer?.onHoldByBrand))
                    }
                  >
                    Reject
                  </button>
                  {/* <button onClick={() => handleReject(influencer._id)} className="button is-primary">Reject</button> */}
                  <button
                    onClick={() => handleOnHold(influencer._id)}
                    className="button is-warning is-rounded ml-3"
                    disabled={
                      !influencer?.isLead ||
                      influencer?.approvedbyAdmin ||
                      influencer?.rejectedbyAdmin ||
                      influencer?.onHoldByAdmin ||
                      (influencer?.isBrand &&
                        (influencer?.approvedbyBrand ||
                          influencer?.rejectedbyBrand ||
                          influencer?.onHoldByBrand))
                    }
                  >
                    On Hold
                  </button>
                </td>

                <td className="customStyle">
                  {influencer.confirmations?.[0]?.PinCode || "Not Available"}
                </td>
                <td className="customStyle">
                  {influencer.confirmations?.[0]?.FullAddress ||
                    "Not Available"}
                </td>
                <td className="customStyle">
                  {influencer.confirmations?.[0]?.Landmark || "Not Available"}
                </td>
                <td className="customStyle">
                  {influencer.confirmations?.[0]?.City || "Not Available"}
                </td>
                <td className="customStyle">
                  {influencer.confirmations?.[0]?.State || "Not Available"}
                </td>
                <td className="customStyle">
                  {influencer.confirmations?.[0]?.InstagramUsername ||
                    "Not Available"}
                </td>
                <td>
                  {influencer?.confirmations?.[0]?.isexecutiveContent
                    ? influencer?.confirmations?.[0]?.approvedbyExecutive
                      ? "Shipped"
                      : influencer?.confirmations?.[0]?.rejectedbyExecutive
                      ? `Not Shipped - ${influencer?.confirmations?.[0]?.rejectionRemarksExecutive}`
                      : influencer?.confirmations?.[0]?.onHoldByExecutive
                      ? "On hold"
                      : "No Action Taken"
                    : "Not Required"}
                </td>
                {/* <td>
                  {influencer?.confirmations?.[0]?.isleadContent
                    ? influencer?.confirmations?.[0]?.approvedbyLead
                      ? "Shipped"
                      : influencer?.confirmations?.[0]?.rejectedbyLead
                      ? `Not Shipped - ${influencer?.confirmations?.[0]?.rejectionRemarksLead}`
                      : influencer?.confirmations?.[0]?.onHoldByLead
                      ? "On hold"
                      : "No Action Taken"
                    : "Not Required"}
                </td> */}
                {/* <td>
                  {influencerData.campaigns.length > 0
                    ? influencer?.confirmation?.approvedbyExecutive
                      ? "Shipped"
                      : influencer?.confirmation?.rejectedbyExecutive
                      ? `Not Shipped - ${influencer?.confirmation?.rejectionRemarksExecutive}`
                      : influencer?.confirmation?.onHoldByExecutive
                      ? "On hold"
                      : influencer?.confirmation?.isexecutiveContent
                      ? "No Action Taken"
                      : "Not Required"
                    : influencer?.confirmations?.[0]?.approvedbyExecutive
                    ? "Shipped"
                    : influencer?.confirmations?.[0]?.rejectedbyExecutive
                    ? `Not Shipped - ${influencer?.confirmations?.[0]?.rejectionRemarksExecutive}`
                    : influencer?.confirmations?.[0]?.onHoldByExecutive
                    ? "On hold"
                    : influencer?.confirmations?.[0]?.isexecutiveContent
                    ? "No Action Taken"
                    : "Not Required"}
                </td> */}
                <td>
                  {influencerData.campaigns.length > 0
                    ? influencer?.confirmation?.approvedbyLead
                      ? "Shipped"
                      : influencer?.confirmation?.rejectedbyLead
                      ? `Not Shipped - ${influencer?.confirmation?.rejectionRemarksLead}`
                      : influencer?.confirmation?.onHoldByLead
                      ? "On hold"
                      : influencer?.confirmation?.isleadContent
                      ? "No Action Taken"
                      : "Not Required"
                    : influencer?.confirmations?.[0]?.approvedbyLead
                    ? "Shipped"
                    : influencer?.confirmations?.[0]?.rejectedbyLead
                    ? `Not Shipped - ${influencer?.confirmations?.[0]?.rejectionRemarksLead}`
                    : influencer?.confirmations?.[0]?.onHoldByLead
                    ? "On hold"
                    : influencer?.confirmations?.[0]?.isleadContent
                    ? "No Action Taken"
                    : "Not Required"}
                </td>
                <td className="customStyle">
                  <button
                    onClick={() =>
                      handleApproveConformation(
                        influencer?.confirmations?.[0]?._id
                      )
                    }
                    className="button is-primary is-rounded"
                    disabled={
                      !influencer?.confirmations?.[0]?.isleadContent ||
                      influencer?.confirmations?.[0]?.approvedbyAdmin ||
                      influencer?.confirmations?.[0]?.rejectedbyAdmin ||
                      influencer?.confirmations?.[0]?.onHoldByAdmin ||
                      (influencer?.confirmations?.[0]?.isBrand &&
                        (influencer?.confirmations?.[0]?.approvedbyBrand ||
                          influencer?.confirmations?.[0]?.rejectedbyBrand ||
                          influencer?.confirmations?.[0]?.onHoldByBrand))
                    }
                  >
                    Shipped
                  </button>
                  <button
                    onClick={() =>
                      handleRejectConfirmation(
                        influencer.confirmations?.[0]?._id
                        // setInfluencerData
                      )
                    }
                    className="button is-danger is-rounded ml-3"
                    disabled={
                      !influencer?.confirmations?.[0]?.isleadContent ||
                      influencer?.confirmations?.[0]?.approvedbyAdmin ||
                      influencer?.confirmations?.[0]?.rejectedbyAdmin ||
                      influencer?.confirmations?.[0]?.onHoldByAdmin ||
                      (influencer?.confirmations?.[0]?.isBrand &&
                        (influencer?.confirmations?.[0]?.approvedbyBrand ||
                          influencer?.confirmations?.[0]?.rejectedbyBrand ||
                          influencer?.confirmations?.[0]?.onHoldByBrand))
                    }
                  >
                    Not Shipped
                  </button>
                  <button
                    onClick={() =>
                      handleOnHoldConfirmation(
                        influencer.confirmations?.[0]?._id
                      )
                    }
                    className="button is-warning is-rounded ml-3"
                    disabled={
                      !influencer?.confirmations?.[0]?.isleadContent ||
                      influencer?.confirmations?.[0]?.approvedbyAdmin ||
                      influencer?.confirmations?.[0]?.rejectedbyAdmin ||
                      influencer?.confirmations?.[0]?.onHoldByAdmin ||
                      (influencer?.confirmations?.[0]?.isBrand &&
                        (influencer?.confirmations?.[0]?.approvedbyBrand ||
                          influencer?.confirmations?.[0]?.rejectedbyBrand ||
                          influencer?.confirmations?.[0]?.onHoldByBrand))
                    }
                  >
                    On Hold
                  </button>
                </td>

                <td className="customStyle">
                  {influencer.deliverables && influencer.deliverables.length > 0
                    ? influencer.deliverables
                        .flatMap((d) => d.uploadVideoPath || [])
                        .map((video, idx) => (
                          <span key={idx}>
                            <a
                              href={`${API_URL}/${video}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Video {idx + 1}
                            </a>
                            {idx !== influencer.deliverables.length - 1 && ", "}
                          </span>
                        ))
                    : "Not Available"}
                </td>
                <td className="customStyle">
                  {influencer.deliverables && influencer.deliverables.length > 0
                    ? influencer.deliverables
                        .flatMap((d) => d.uploadRawVideoPath || [])
                        .map((video, idx) => (
                          <span key={idx}>
                            <a
                              href={`${API_URL}/${video}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Video {idx + 1}
                            </a>
                            {idx !== influencer.deliverables.length - 1 && ", "}
                          </span>
                        ))
                    : "Not Available"}
                </td>
                <td>
                  {influencer?.deliverables?.[0]?.isexecutiveContent
                    ? influencer?.deliverables?.[0]?.approvedbyExecutive
                      ? "Approved"
                      : influencer?.deliverables?.[0]?.rejectedbyExecutive
                      ? `Rejected - ${influencer?.deliverables?.[0]?.rejectionRemarksExecutive}`
                      : influencer?.deliverables?.[0]?.onHoldByExecutive
                      ? "On hold"
                      : "No Action Taken"
                    : "Not Required"}
                </td>
                {/* <td>
                  {influencer?.infDeliverables?.isleadContent
                    ? influencer?.infDeliverables?.approvedbyLead
                      ? "Approved"
                      : influencer?.infDeliverables?.rejectedbyLead
                      ? `Rejected - ${influencer?.infDeliverables?.rejectionRemarksLead}`
                      : influencer?.infDeliverables?.onHoldByLead
                      ? "On hold"
                      : "No Action Taken"
                    : "Not Required"}
                </td> */}
                {/* <td>
                  {influencerData.campaigns.length > 0
                    ? influencer?.deliverable?.approvedbyExecutive
                      ? "Approved"
                      : influencer?.deliverable?.rejectedbyExecutive
                      ? `Rejected - ${influencer?.deliverable?.rejectionRemarksExecutive}`
                      : influencer?.deliverable?.onHoldByExecutive
                      ? "On hold"
                      : influencer?.deliverable?.isexecutiveContent
                      ? "No Action Taken"
                      : "Not Required"
                    : influencer?.deliverables?.[0]?.isexecutiveContent
                    ? influencer?.deliverables?.[0]?.approvedbyExecutive
                      ? "Approved"
                      : influencer?.deliverables?.[0]?.rejectedbyExecutive
                      ? `Rejected - ${influencer?.deliverables?.[0]?.rejectionRemarksExecutive}`
                      : influencer?.deliverables?.[0]?.onHoldByExecutive
                      ? "On hold"
                      : "No Action Taken"
                    : "Not Required"}
                </td> */}
                <td>
                  {influencerData.campaigns.length > 0
                    ? influencer?.deliverable?.approvedbyLead
                      ? "Approved"
                      : influencer?.deliverable?.rejectedbyLead
                      ? `Rejected - ${influencer?.deliverable?.rejectionRemarksLead}`
                      : influencer?.deliverable?.onHoldByLead
                      ? "On hold"
                      : influencer?.deliverable?.isleadContent
                      ? "No Action Taken"
                      : "Not Required"
                    : influencer?.deliverables?.[0]?.isleadContent
                    ? influencer?.deliverables?.[0]?.approvedbyLead
                      ? "Approved"
                      : influencer?.deliverables?.[0]?.rejectedbyLead
                      ? `Rejected - ${influencer?.deliverables?.[0]?.rejectionRemarksLead}`
                      : influencer?.deliverables?.[0]?.onHoldByLead
                      ? "On hold"
                      : "No Action Taken"
                    : "Not Required"}
                </td>

                <td className="customStyle">
                  <button
                    onClick={() =>
                      handleApproveDeliverable(
                        influencer.deliverables?.[0]?._id
                      )
                    }
                    className="button is-primary is-rounded"
                    disabled={
                      !influencer?.deliverables?.[0]?.isleadContent ||
                      influencer?.deliverables?.[0]?.approvedbyAdmin ||
                      influencer?.deliverables?.[0]?.rejectedbyAdmin ||
                      influencer?.deliverables?.[0]?.onHoldByAdmin ||
                      (influencer?.deliverables?.[0]?.isBrand &&
                        (influencer?.deliverables?.[0]?.approvedbyBrand ||
                          influencer?.deliverables?.[0]?.rejectedbyBrand ||
                          influencer?.deliverables?.[0]?.onHoldByBrand))
                    }
                  >
                    {/* {console.log(
                      "isLead",
                      !influencer?.infDeliverables?.isleadContent
                    )}
                    {console.log(
                      "isExecutive & not approvedbyExecutive",
                      influencer?.infDeliverables?.isexecutiveContent &&
                        !influencer?.infDeliverables?.approvedbyExecutive
                    )}
                    {console.log(
                      "isRejected",
                      influencer?.infDeliverables?.rejected
                    )} */}
                    Approve
                  </button>
                  <button
                    onClick={() =>
                      handleRejectDeliverable(
                        influencer.deliverables?.[0]?._id
                        // setInfluencerData
                      )
                    }
                    className="button is-danger is-rounded ml-3"
                    disabled={
                      !influencer?.deliverables?.[0]?.isleadContent ||
                      influencer?.deliverables?.[0]?.approvedbyAdmin ||
                      influencer?.deliverables?.[0]?.rejectedbyAdmin ||
                      influencer?.deliverables?.[0]?.onHoldByAdmin ||
                      (influencer?.deliverables?.[0]?.isBrand &&
                        (influencer?.deliverables?.[0]?.approvedbyBrand ||
                          influencer?.deliverables?.[0]?.rejectedbyBrand ||
                          influencer?.deliverables?.[0]?.onHoldByBrand))
                    }
                  >
                    Reject
                  </button>
                  <button
                    onClick={() =>
                      handleOnHoldDeliverable(influencer.deliverables?.[0]?._id)
                    }
                    className="button is-warning is-rounded ml-3"
                    disabled={
                      !influencer?.deliverables?.[0]?.isleadContent ||
                      influencer?.deliverables?.[0]?.approvedbyAdmin ||
                      influencer?.deliverables?.[0]?.rejectedbyAdmin ||
                      influencer?.deliverables?.[0]?.onHoldByAdmin ||
                      (influencer?.deliverables?.[0]?.isBrand &&
                        (influencer?.deliverables?.[0]?.approvedbyBrand ||
                          influencer?.deliverables?.[0]?.rejectedbyBrand ||
                          influencer?.deliverables?.[0]?.onHoldByBrand))
                    }
                  >
                    On Hold
                  </button>
                </td>
                <td className="customStyle">
                  {Array.isArray(influencer.deliverables) &&
                  influencer.deliverables.length > 0
                    ? influencer.deliverables.map((deliverable, dIdx) =>
                        deliverable.links && deliverable.links.length > 0
                          ? deliverable.links.map((link, lIdx) => (
                              <span key={`${dIdx}-${lIdx}`}>
                                <a
                                  href={link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Link {lIdx + 1}
                                </a>
                                {lIdx !== deliverable.links.length - 1 && ", "}
                              </span>
                            ))
                          : "No Links"
                      )
                    : "Not Available"}
                </td>
              </tr>
            ))}
          </tbody>
          {/* <tbody>
            {influencerData.campaigns.map((influencer) => (
              <tr key={influencer._id}>
                <th className="customStyle">{influencer.FullName}</th>
                {influencer.confirmation ? (
                  <>
                    <td className="customStyle">{influencer.PhoneNumber}</td>
                    <td className="customStyle">{influencer.WhatsappNumber}</td>
                    <td className="customStyle">{influencer.Email}</td>
                  </>
                ) : (
                  <>
                    <td className="customStyle">Hidden</td>
                    <td className="customStyle">Hidden</td>
                    <td className="customStyle">Hidden</td>
                  </>
                )} */}
          {/* <td className="customStyle">{influencer.PhoneNumber}</td>
              <td className="customStyle">{influencer.WhatsappNumber}</td> 
              <td className="customStyle">{influencer.Email}</td> */}
          {/* <td className="customStyle">{influencer.Gender}</td>
                <td className="customStyle">{influencer.Age}</td>
                <td className="customStyle">{influencer.selectedCategory}</td>
                <td className="customStyle">{influencer.Languages}</td>
                <td className="customStyle">{influencer.CelebrityType}</td>
                <td className="customStyle">{influencer.DealType}</td>
                <td className="customStyle">{influencer.CampaignType}</td>
                <td className="customStyle">
                  {influencer.platforms && influencer.platforms.length > 0 ? (
                    influencer.platforms.map((platform, index) => (
                      <div key={index}>
                        <strong>{platform.platformName}: </strong>
                        (username: {platform.username}) (Followers:{" "}
                        {platform.followerRange})
                      </div>
                    ))
                  ) : (
                    <span>No platforms selected</span>
                  )}
                </td>
                <td className="customStyle">
                  <button
                    onClick={() => handleApprove(influencer._id)}
                    className="button is-primary is-rounded"
                  >
                    Approve
                  </button> */}
          {/* <button onClick={() => handleReject(influencer._id)} className="button is-primary">Reject</button> */}
          {/* </td> */}
          {/* <td className="customStyle">{influencer.FullName}</td> */}
          {/* <td className="customStyle">
                  {influencer.infDeliverables &&
                    influencer.infDeliverables.uploadVideoPath.map(
                      (video, index) => (
                        <span key={index}>
                          <a
                            href={`${API_URL}/${video}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Video {index + 1}
                          </a>
                          {index !==
                            influencer.infDeliverables.uploadVideoPath.length -
                              1 && ", "}
                        </span>
                      )
                    )}
                </td>
                <td className="customStyle">
                  {influencer.infDeliverables &&
                    influencer.infDeliverables.uploadRawVideoPath.map(
                      (video, index) => (
                        <span key={index}>
                          <a
                            href={`${API_URL}/${video}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Video {index + 1}
                          </a>
                          {index !==
                            influencer.infDeliverables.uploadRawVideoPath
                              .length -
                              1 && ", "}
                        </span>
                      )
                    )}
                </td>
                <td className="customStyle">
                  <button
                    onClick={() =>
                      handleApproveDeliverable(influencer.infDeliverables._id)
                    }
                    className="button is-primary is-rounded"
                  >
                    Approve
                  </button>
                </td> */}
          {/* <td className="customStyle">{influencer.confirmation?.FullName}</td> */}
          {/* <td className="customStyle">
                  {influencer.confirmation?.PinCode}
                </td>
                <td className="customStyle">
                  {influencer.confirmation?.FullAddress}
                </td>
                <td className="customStyle">
                  {influencer.confirmation?.Landmark}
                </td>
                <td className="customStyle">
                  {influencer.confirmation?.State}
                </td>
                <td className="customStyle">{influencer.confirmation?.City}</td>
                <td className="customStyle">
                  {influencer.confirmation?.InstagramUsername}
                </td>

                <td className="customStyle">
                  <button
                    onClick={() =>
                      handleApproveConformation(influencer.confirmation._id)
                    }
                    className="button is-primary is-rounded"
                  >
                    Approve
                  </button> */}

          {/* // ) : (
                //   <span>No confirmation</span>
                )} */}
          {/* </td>
              </tr>
            ))}
          </tbody> */}
        </table>
      ) : (
        "No Influencer Data Available."
      )}
    </div>
  );
};

export default InfluencersTablesLead;
