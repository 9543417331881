import React, { useState, useEffect } from "react";
import BrandPage from "../pages/BrandForm";
import InfluencerTableBrand from "../pages/InfluencerTableBrand";
import ShortlistedInfluencer from "../pages/ShortlistedInfluencer";
import InfluencerDeliverablesTableBrand from "../pages/infDeliverablesBrand";
import InfluencerConformationTableBrand from "../pages/infConfTableBrand";
import ApproveCampaignPage from "../pages/Approvecampaign";
import { getBrandCampaigns } from "../components/api";
import { useLocation } from "react-router-dom";
import BrandCampaignForm from "../pages/campaignForm";
import InviteForm from "../pages/userRole";
import Modal from "../pages/inviteForm";
import UserTable from "../pages/userTable";
import logo from "../logo192.png";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import ApproveCampaignAdmin from "../pages/ApproveCampaignAdmin";
library.add(fas);
const Administration = () => {
  // menu visiblity set
  const [menuVisible, setMenuVisible] = useState(false);

  const handleToggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  //create new campaign

  const [PhoneNumber, setPhoneNumber] = useState("");
  const [WhatsappNumber, setWhatsappNumber] = useState("");
  const [UserName, setUserName] = useState("");

  //mobile number validation regx

  //const [MobileNumber, setmobilenumber] = useState("");
  const [Email, setemail] = useState();
  const [MobileNumber, setmobilenumber] = useState("");
  //mobile number validation regx
  let hasValNumber = /^(\+?\d{1,3})? ?\d{10}$/.test(
    PhoneNumber || MobileNumber
  );

  //email validatin regx
  let hasValEmail = /^[a.-zA.-Z0.-9]+@[a.-zA.-Z0.-9]+\.[A-Za-z]+$/.test(Email);

  // create new campaignForm
  const [showCreateCampaignForm, setShowCreateCampaignForm] = useState(false);
  // const [selectedCampaign, setSelectedCampaign] = useState(null);
  const handleCreateNewCampaignClick = () => {
    setShowCreateCampaignForm(true);
    setShowUserRoles(false);
    setShowInfluencerApplications(false);
    setshowShortlistedInfluencers(false);
  };

  // Influencer Applications
  const [showInfluencerApplications, setShowInfluencerApplications] =
    useState(false);
  const handleInfluencerApplicationsClick = () => {
    setShowInfluencerApplications(true);
    setShowCreateCampaignForm(false);
    setshowShortlistedInfluencers(false);
    setShowUserRoles(false);
  };
  const [showBrandApplications, setShowBrandApplications] = useState(false);
  const handleBrandApplicationsClick = () => {
    setShowBrandApplications(true);
    setShowCreateCampaignForm(false);
    setshowShortlistedInfluencers(false);
    setShowUserRoles(false);
  };

  // Shortlisted Influencers
  const [showShortlistedInfluencers, setshowShortlistedInfluencers] =
    useState(false);
  const handleShortlistedInfluencersClick = () => {
    setshowShortlistedInfluencers(true);
    setShowCreateCampaignForm(false);
    setShowInfluencerApplications(false);
    setShowUserRoles(false);
  };

  const [selectedOption, setSelectedOption] = useState("");
  const [showC1Table, setShowC1Table] = useState(false);
  const [showC2Table, setShowC2Table] = useState(false);
  const [showC3Table, setShowC3Table] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);

    switch (option) {
      case "option1":
        setShowC1Table(true);
        setShowC2Table(false);
        setShowC3Table(false);
        break;
      case "option2":
        setShowC1Table(false);
        setShowC2Table(true);
        setShowC3Table(false);
        break;
      case "option3":
        setShowC1Table(false);
        setShowC2Table(false);
        setShowC3Table(true);
        break;
      default:
        setShowC1Table(false);
        setShowC2Table(false);
        setShowC3Table(false);
    }
  };

  const handleC1Click = () => {
    console.log("C1 clicked");
    handleOptionSelect("option1");
  };

  const handleC2Click = () => {
    console.log("C2 clicked");
    handleOptionSelect("option2");
  };

  const handleC3Click = () => {
    console.log("C3 clicked");
    handleOptionSelect("option3");
  };

  // table
  // Sample table data
  const tableData = [
    ["Data 1", "Data 2", "data3"],

    // Add more rows and data
  ];

  // Function to generate CSV data from the tableData
  const generateCSVData = () => {
    const headers = Array.from(document.querySelectorAll(".table th")).map(
      (th) => th.innerText
    ); // Add more headers if needed
    const csvData = [headers, ...tableData];
    return csvData;
  };
  // status set
  const statusOptions = ["Selected", "Rejected", "On Hold"];
  // add shipment
  // const [selectedRows, setSelectedRows] = useState([]);
  const [shipmentStatus, setShipmentStatus] = useState({});

  const handleCheckboxChange = (index, status) => {
    setShipmentStatus((prevShipmentStatus) => ({
      ...prevShipmentStatus,
      [index]: status,
    }));
  };

  // Add Tracking ID
  const [trackingIds, setTrackingIds] = useState({});
  const handleTrackingIdChange = (index, trackingId) => {
    setTrackingIds((prevTrackingIds) => ({
      ...prevTrackingIds,
      [index]: trackingId,
    }));
  };

  // Handler for status change
  const handleStatusChange = (index, selectedStatus) => {
    // Add logic to handle the status change (e.g., update the data or make an API call)
    console.log(`Status changed for row ${index + 1}: ${selectedStatus}`);
  };

  // Option to approve deliverables
  const handleApproval = (index) => {
    // Add logic to handle the approval ( update the data or make an API call)
    console.log(`Deliverables approved for row ${index + 1}`);
  };
  // filters

  const [filterStatus, setFilterStatus] = useState("all");
  const data = generateCSVData();
  const filteredData = data.filter((item) => {
    if (filterStatus === "all") return true;
    return item.status === filterStatus; // Replace 'status' with the actual property that indicates the status
  });

  const [showUserRoles, setShowUserRoles] = useState(false);

  // Handle User Roles link click
  const handleUserRolesClick = () => {
    setShowUserRoles(true);
    setShowAllCampaign(false);
    setShowCreateCampaignForm(false);
    setShowBrandApplications(false);
  };
  //invite form
  const [showInviteForm, setShowInviteForm] = useState(false);
  const handleInviteTeamMembersClick = () => {
    setShowInviteForm(true);
    setShowUserRoles(false);
    setShowCreateCampaignForm(false);
    setShowInfluencerApplications(false);
    setshowShortlistedInfluencers(false);
  };
  const [showAllCampaign, setShowAllCampaign] = useState(false);
  const handleShowAllCampignClick = () => {
    setShowAllCampaign(true); // Set the state to show the "Approved Campaigns" page
    setShowCreateCampaignForm(false);
    setShowInfluencerApplications(false);
    setshowShortlistedInfluencers(false);
  };

  const [showDeliverables, setshowDeliverables] = useState(false);
  const handleDeliverbralesClick = () => {
    setshowDeliverables(true);
    setShowCreateCampaignForm(false);
    setShowInfluencerApplications(false);
    setShowUserRoles(false);
  };
  const [showConformation, setshowConformation] = useState(false);
  const handleConformationClick = () => {
    setshowConformation(true);
    setShowCreateCampaignForm(false);
    setShowInfluencerApplications(false);
  };
  const location = useLocation();
  const brandName = location.state ? location.state.brandName : null;

  const [selectedBrand, setSelectedBrand] = useState(null);

  const handleBrandClick = (brand) => {
    setSelectedBrand(brand);
  };

  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const handleMouseEnter = () => {
    setIsActive(true);
  };

  const handleMouseLeave = () => {
    setIsActive(false);
  };

  // const [selectedOption, setSelectedOption] = useState('');
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  return (
    // <div className='imgfull'>
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        {/* Sidebar */}
        <a className="navbar-item">
          {/* <span className="icon mr-3">
                        <img className="ml-5" src="./images/hobo.jpeg" alt="Menu Icon" />
                    </span> */}
        </a>
      </div>
      <div>
        <div
          style={{ marginRight: "50px" }}
          className={`hamburger ${isActive ? "is-active" : ""}`}
          onClick={toggleMenu}
        >
          {/* <span className="icon mr-3">
                        <img src="./images/hobo.jpeg" alt="Menu Icon" style={{ marginRight: "30px" }} />
                    </span> */}
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div>

        {/* <div className= has-background-info' style={{marginRight:"10rem"}}> */}
        <div
          className={`navbar-menu ${isActive ? "is-active" : ""}`}
          style={{ backgroundColor: "dark-blue" }}
        >
          <aside
            className={` menu  ${isActive ? "" : "is-hidden"} overflow-hidden`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <ul className="menu-list d-flex flex-row-reverse">
              {/* <li className="menu-label " style={{ marginTop: "1rem", marginLeft: "4px" }}>
                                Brand Dashboard
                            </li> */}
              <div className=" has-text-left ml-3">
                <figure className="image is-32x32">
                  <img src={logo} alt="Logo" />
                </figure>
                {/* <div className='my-5 '>
                                <MenuItem title="Create new Campaign" icon="fas fa-plus" clickHandler={handleCreateNewCampaignClick} style={{ marginLeft: "90px" }} />
                            </div> */}
                <li
                  className="my-5 has-text-white"
                  onClick={handleShowAllCampignClick}
                >
                  <MenuItem title="All Campaigns" icon="fas fa-check-circle" />
                </li>
                {/* <div className='my-5'>
                                <MenuItem title="Brand Applications" icon="fas fa-users" clickHandler={handleBrandApplicationsClick} />
                            </div> */}
                {/* <div >
                                <MenuItem title="User Roles" icon="fas fa-users" clickHandler={handleUserRolesClick} />
                            </div> */}
                {/* <div >
                            <MenuItem title="Settings" icon="fas fa-cog" />
                            </div> */}
              </div>
            </ul>
          </aside>
          {/* </div> */}
        </div>

        <ul
          className="menu1   d-flex flex-row-reverse"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {!isActive && (
            <div className=" has-text-centered ">
              <figure className="image is-32x32">
                <img src={logo} alt="Logo" />
              </figure>
              {/* {!isActive && (
                        <li className="ml-5 my-5" style={{ cursor: "pointer" }} >
                            <span className="icon is-small has-text-success mr-1" >
                                <i className="fas fa-plus" style={{ marginTop: "30px", marginRight: "90px" }}></i>
                            </span>
                        </li>
                    )} */}
              {!isActive && (
                <div className="my-5 has-text-white">
                  <MenuItem
                    icon="fas fa-check-circle"
                    clickHandler={handleShowAllCampignClick}
                    style={{ merginButton: "20vw" }}
                  />
                </div>
              )}
            </div>
          )}

          {/* {!isActive && (
                        <li className="ml-5 my-2" style={{ cursor: "pointer" }}>
                            <span className="icon is-small has-text-success" >
                                <i className="fas fa-users" style={{ marginRight: "90px" }} ></i>
                            </span>
                        </li>
                    )} */}
          {/* {!isActive && (
                        <li className="ml-5 my-2" style={{ cursor: "pointer", }}>

                            <span className="icon is-small has-text-success">
                                <i className="fas fa-cog" style={{ marginRight: "90px" }} ></i>
                            </span>

                        </li>
                    )} */}
        </ul>
      </div>

      {/* Main content */}
      <div className="column">
        {/* <h1>Welcome to {brandName} Dashboard</h1> */}
        <div className="columns is-ancestor">
          <div className="column  mt-5">
            {showCreateCampaignForm ? (
              // Render the Create Campaign form
              <div
                className="is-child  has-text-centered "
                style={{ height: "100vh", width: "50vw" }}
              >
                <h1 className="subtitle mt-5 ">Create New Campaign</h1>
                {/* <BrandCampaignForm /> */}
                <BrandPage brandName={brandName} />
              </div>
            ) : showAllCampaign ? (
              <div className="subtitle" style={{ fontSize: "15px" }}>
                {/* <div className='field'>
                                    <div className="control">
                                        <div className='select is-rounded'>
                                            <select
                                                value={selectedOption}
                                                onChange={handleOptionChange}
                                            >
                                                <option value="">Select Brand Name</option>
                                                {brandName.map((name, index) => (
                                                    <option key={index} value={name}>{name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div> */}

                <ApproveCampaignPage />
              </div>
            ) : //1o651InfluencerApplications
            showInfluencerApplications ? (
              <div
                className="is-child box"
                style={{
                  overflowX: "auto",
                  overflowY: "auto",
                  width: "1060px",
                }}
              >
                <div className="subtitle" style={{ fontSize: "15px" }}>
                  <h1 className="underline">Influencer Applications</h1>
                  {/* <BrandInfDashboard /> */}
                  <InfluencerTableBrand />
                </div>
              </div>
            ) : showShortlistedInfluencers ? (
              <div
                className="is-child box "
                style={{
                  overflowX: "auto",
                  overflowY: "auto",
                  width: "1060px",
                }}
              >
                <div className="subtitle" style={{ fontSize: "15px" }}>
                  <h1 className="subtitle mt-5 underline">
                    Shortlisted Influencers
                  </h1>
                  <ShortlistedInfluencer />
                </div>
              </div>
            ) : showDeliverables ? (
              <div
                className="is-child box has-text-centered"
                style={{
                  overflowX: "auto",
                  overflowY: "auto",
                  width: "1060px",
                }}
              >
                <h1 className="subtitle mt-5 underline"></h1>
                {/* <InfluencerDeliverblesTable /> */}
                <InfluencerDeliverablesTableBrand />
                <div></div>
              </div>
            ) : showConformation ? (
              <div
                className="is-child box has-text-centered"
                style={{
                  overflowX: "auto",
                  overflowY: "auto",
                  width: "1060px",
                }}
              >
                <h1 className="subtitle mt-5 underline"></h1>
                {/* <InfluencerConformationTable /> */}
                <InfluencerConformationTableBrand />
              </div>
            ) : // showuserRoles
            showUserRoles ? (
              <div className="has-text-right ">
                <div className="field">
                  <div className="control my-3 ">
                    <Modal />
                    <UserTable />
                  </div>
                </div>
                {/* <h1 className='subtitle mt-5'>Choose Campaigns
                                </h1> */}
              </div>
            ) : showInviteForm ? (
              // Display the invite form
              <div className="is-child box has-text-centered">
                <h1 className="subtitle mt-5">Invite form campaign</h1>
                <div className="form">
                  <div className="field ">
                    <div className="control has-icons-left">
                      <div className="colomn">
                        <input
                          className="input is-rounded "
                          type="text"
                          placeholder="Email ID"
                          value={Email}
                          onChange={(e) => setemail(e.target.value)}
                        />
                        {Email && !hasValEmail && (
                          <small
                            className={`ml-1 mt-1  ${
                              !hasValEmail && Email !== ""
                                ? "has-text-danger"
                                : "has-text-success"
                            }`}
                          >
                            {!hasValEmail && Email !== ""
                              ? "Enter a valid email address"
                              : ""}
                          </small>
                        )}
                        <span className="icon is-small is-left">
                          <i className="fas fa-envelope"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* mobile number */}
                  <div className="field ">
                    <div className="control has-icons-left ">
                      <div className="colomn">
                        <input
                          className="input  is-rounded "
                          type="text"
                          placeholder="Mobile Number"
                          value={MobileNumber}
                          onChange={(e) => setmobilenumber(e.target.value)}
                        />

                        {/* mobile number validation */}
                        {MobileNumber && !hasValNumber && (
                          <div>
                            <small
                              className={`ml-1 mt-1  ${
                                !hasValNumber && MobileNumber !== ""
                                  ? "has-text-danger"
                                  : "has-text-success"
                              }`}
                            >
                              {!hasValNumber && MobileNumber !== ""
                                ? "Please Enter valid Mobile number"
                                : ""}
                            </small>
                          </div>
                        )}

                        <span className="icon is-small is-left">
                          <i className="fas fa-phone"></i>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <button
                        className="button is-primary is-rounded  is-focused is-medium is-fullwidth "
                        disabled={
                          !MobileNumber ||
                          !Email ||
                          !hasValNumber ||
                          !hasValEmail
                        }
                      >
                        SIGN UP
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="subtitle" style={{ fontSize: "15px" }}>
                <ApproveCampaignPage />
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
    // </div>
  );
};
function MenuItem({ title, icon, clickHandler }) {
  return (
    <li
      className="ml-2 my-2"
      style={{ cursor: "pointer" }}
      onClick={clickHandler}
    >
      <span className="icon is-small has-text-success mr-1">
        <i className={icon} style={{ marginRight: "50px" }}></i>
      </span>
      <span style={{ marginLeft: "-25px" }}>{title}</span>
    </li>
  );
}

export default Administration;
