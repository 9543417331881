export const mergeInfluencerData = (data) => {
  const mergedData = {};

  // Combine campaigns, deliverables, and confirmations by FullName
  data.campaigns.forEach((campaign) => {
    if (!mergedData[campaign.FullName]) {
      mergedData[campaign.FullName] = {
        ...campaign,
        deliverables: [],
        confirmations: [],
      };
    }
  });

  data.deliverables.forEach((deliverable) => {
    if (!mergedData[deliverable.FullName]) {
      mergedData[deliverable.FullName] = {
        deliverables: [],
        confirmations: [],
      };
    }
    mergedData[deliverable.FullName].deliverables.push(deliverable);
  });

  data.confirmations.forEach((confirmation) => {
    if (!mergedData[confirmation.FullName]) {
      mergedData[confirmation.FullName] = {
        deliverables: [],
        confirmations: [],
      };
    }
    mergedData[confirmation.FullName].confirmations.push(confirmation);
  });

  return Object.values(mergedData);
};
