import React from "react";

const getCombinedRange = (followersCriteria) => {
  if (!Array.isArray(followersCriteria) || followersCriteria.length === 0)
    return "";

  // Extract numeric values from the given ranges
  const allNumbers = followersCriteria
    .flatMap((criteria) =>
      criteria.split(",").flatMap((range) => range.split("-").map(Number))
    )
    .filter((num) => !isNaN(num));

  if (allNumbers.length === 0) return "";

  return `${Math.min(...allNumbers)}-${Math.max(...allNumbers)}`;
};

const CombinedRange = ({ followersCriteria }) => {
  const range = getCombinedRange(followersCriteria);

  return (
    <div>
      <p className="card-detail-label">Followers Criteria</p>
      <p className="card-detail">{range || "N/A"}</p>
    </div>
  );
};

export default CombinedRange;
