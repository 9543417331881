import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Config } from "../.env.js";

const LiveLinks = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [fullName, setFullName] = useState("");
  const [links, setLinks] = useState([""]); // Start with one empty link
  const campaign = location.state?.campaign || null;
  const email = location.state?.email || "";
  console.log("Email from live links page:", email);
  const addLink = () => {
    if (links.length < 100) {
      setLinks([...links, ""]);
    }
  };

  const removeLink = (index) => {
    setLinks(links.filter((_, i) => i !== index));
  };

  const handleLinkChange = (index, value) => {
    const newLinks = [...links];
    newLinks[index] = value;
    setLinks(newLinks);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const filteredLinks = links.filter((link) => link.trim() !== ""); // Remove empty links
    console.log("Full Name:", fullName);
    console.log("Links:", filteredLinks);
    console.log("Campaign:", campaign);
    console.log("Brand Name:", campaign.brandName);
    console.log("Campaign Name:", campaign.campaignName);
    try {
      const response = await fetch(
        `${Config.API_URL}/api/influencers/submitLiveLinks`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fullName,
            brandName: campaign.brandName,
            campaignName: campaign.campaignName,
            links: filteredLinks,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit data");
      }

      console.log("Data successfully stored in InfDeliverables table");
      navigate("/success-page");
    } catch (error) {
      console.error("Error submitting data:", error);
      alert("Failed to submit. Please try again.");
    }
  };

  return (
    <div className="container" style={{ width: "50%" }}>
      <div
        className="mt-5"
        style={{
          backgroundColor: "white",
          borderRadius: "6px",
          padding: "20px",
        }}
      >
        <h2 className="title is-4">Live Links</h2>
        <form onSubmit={handleSubmit}>
          {/* Full Name Input */}
          <div className="field">
            <label className="label">Full Name</label>
            <div className="control">
              <input
                type="text"
                className="input"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
                placeholder="Enter your full name"
              />
            </div>
          </div>

          {/* Dynamic Link Inputs */}
          <label className="label">Links</label>
          {links.map((link, index) => (
            <div className="field is-flex" key={index}>
              <div className="control is-expanded width-100-pct">
                <input
                  type="url"
                  className="input"
                  value={link}
                  onChange={(e) => handleLinkChange(index, e.target.value)}
                  required
                  placeholder={`Link ${index + 1}`}
                />
              </div>
              {links.length > 1 && (
                <button
                  type="button"
                  className="button is-danger is-light ml-2"
                  onClick={() => removeLink(index)}
                >
                  ✖
                </button>
              )}
            </div>
          ))}

          {/* Add Link Button */}
          {links.length < 100 && (
            <button
              type="button"
              className="button is-info is-light is-fullwidth"
              onClick={addLink}
            >
              + Add Another Link
            </button>
          )}

          {/* Submit Button */}
          <div className="field">
            <div className="control">
              <button
                className="button is-primary is-rounded is-fullwidth mt-5"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LiveLinks;
