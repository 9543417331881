// src/components/DataDisplay.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Config } from "../.env.js";
const DataDisplay = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${Config.API_URL}/api/data`); // Update with your server URL
        setData(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <h2>Profile Data</h2>
      <table>
        <thead>
          <tr>
            <th>Profile ID</th>
            <th>Name</th>
            <th>Profile URL</th>
            <th>Username</th>
            <th>Media Count</th>
            <th>Followers Count</th>
            <th>Follows Count</th>
            <th>Biography</th>
            <th>Website</th>
            <th>category</th>
          </tr>
        </thead>
        <tbody>
          {data.profile &&
            data.profile.map((profile) => (
              <tr key={profile._id}>
                <td>{profile.instagramAccountId}</td>
                <td>{profile.name}</td>
                <td>
                  <a
                    href={profile.profileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {profile.profileUrl}
                  </a>
                </td>
                <td>{profile.username}</td>
                <td>{profile.mediaCount}</td>
                <td>{profile.followersCount}</td>
                <td>{profile.followsCount}</td>
                <td>{profile.biography}</td>
                <td>
                  <a
                    href={profile.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {profile.website}
                  </a>
                </td>
                <td>{profile.category}</td>
              </tr>
            ))}
        </tbody>
      </table>

      <h2>Media Data</h2>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Caption</th>
            <th>Type</th>
            <th>published_at</th>
            <th>Likes count</th>
            <th>comments_count</th>
            <th>Organic Impressions</th>
            <th>Organic Reach</th>
            <th>Media URL</th>
            <th>Thumbnail URL</th>
            <th>Permalink</th>
          </tr>
        </thead>
        <tbody>
          {data.media &&
            data.media.map((media) => (
              <tr key={media.id}>
                <td>{media.id}</td>
                <td>{media.caption}</td>
                <td>{media.mediaType}</td>
                <td>{media.publishedAt}</td>
                <td>{media.likeCount}</td>
                <td>{media.comments_count}</td>
                <td>{media.organicImpressions}</td>
                <td>{media.totalOrganicReach}</td>
                <td>
                  <a
                    href={media.mediaUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View
                  </a>
                </td>
                <td>
                  <a
                    href={media.thumbnailUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View
                  </a>
                </td>
                <td>
                  <a
                    href={media.permalink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View
                  </a>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <h2>Insights Data</h2>
      <table>
        <thead>
          <tr>
            <th>Metric</th>
            <th>Period</th>
            <th>Total Value</th>
          </tr>
        </thead>
        <tbody>
          {data.insights &&
            data.insights.map((insight) => (
              <tr key={insight._id}>
                <td>{insight.metric}</td>
                <td>{insight.period}</td>
                <td>{insight.totalValue}</td>
              </tr>
            ))}
        </tbody>
      </table>

      <h2>Account Engagements Data</h2>
      <table>
        <thead>
          <tr>
            <th>Metric</th>
            <th>Period</th>
            <th>Total Value</th>
          </tr>
        </thead>
        <tbody>
          {data.accEngegment &&
            data.accEngegment.map((engegment) => (
              <tr key={engegment._id}>
                <td>{engegment.metric}</td>
                <td>{engegment.period}</td>
                <td>{engegment.totalValue}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <h2>Follower Demographics</h2>
      <table>
        <thead>
          <tr>
            <th>Metric</th>
            <th>Period</th>
            <th>Breakdown</th>
            <th>Gender</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {data.demographics &&
            data.demographics.map((demographic) => (
              <React.Fragment key={demographic._id}>
                {demographic.totalValue.map((value, index) => (
                  <tr key={`${demographic._id}-${index}`}>
                    {index === 0 && (
                      <>
                        <td rowSpan={demographic.totalValue.length}>
                          {demographic.metric}
                        </td>
                        <td rowSpan={demographic.totalValue.length}>
                          {demographic.period}
                        </td>
                        <td rowSpan={demographic.totalValue.length}>
                          {demographic.breakdown}
                        </td>
                      </>
                    )}
                    <td>{value.gender}</td>
                    <td>{value.value}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
        </tbody>
      </table>
      <h2>Age Range Data</h2>
      <table>
        <thead>
          <tr>
            <th>Metric</th>
            <th>Period</th>

            <th>value(followersCount)</th>
            <th>Percentage</th>
          </tr>
        </thead>
        <tbody>
          {data.ageRanges &&
            data.ageRanges.map((ageRange, index) => (
              <React.Fragment key={ageRange._id}>
                {ageRange.totalValue.map((value, index) => (
                  <tr key={`${ageRange._id}-${index}`}>
                    {index === 0 && (
                      <>
                        <td rowSpan={ageRange.totalValue.length}>
                          {ageRange.metric}
                        </td>
                        <td rowSpan={ageRange.totalValue.length}>
                          {ageRange.period}
                        </td>
                        <td rowSpan={ageRange.totalValue.length}>
                          {ageRange.breakdown}
                        </td>
                      </>
                    )}

                    <td>{value.value}</td>
                    <td>{value.percentage}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
        </tbody>
      </table>

      <h2>Country Codes Data</h2>
      <table>
        <thead>
          <tr>
            <th>Account ID</th>
            <th>Period</th>
            <th>Country Code</th>
            <th>Value (followersCount)</th>
            <th>Percentage</th>
          </tr>
        </thead>
        <tbody>
          {data.countryCodes &&
            data.countryCodes.map((countryCodeData, index) => (
              <React.Fragment key={index}>
                {countryCodeData.totalValue.map((value, idx) => (
                  <tr key={`${countryCodeData._id}-${idx}`}>
                    {idx === 0 && (
                      <>
                        <td rowSpan={countryCodeData.totalValue.length}>
                          {countryCodeData.accountId}
                        </td>
                        <td rowSpan={countryCodeData.totalValue.length}>
                          {countryCodeData.period}
                        </td>
                      </>
                    )}
                    <td>{value.countryCode}</td>
                    <td>{value.value}</td>
                    <td>{value.percentage}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
        </tbody>
      </table>

      <h2>City Data</h2>
      <table>
        <thead>
          <tr>
            <th>Account ID</th>
            <th>City Name</th>
            <th>Value (followersCount)</th>
            <th>Percentage</th>
          </tr>
        </thead>
        <tbody>
          {data && data.city_count && data.city_count.length > 0 ? (
            data.city_count.map((city_countData, index) => (
              <React.Fragment key={index}>
                {city_countData.cities && city_countData.cities.length > 0 ? (
                  city_countData.cities.map((city, idx) => (
                    <tr key={`${city_countData._id}-${idx}`}>
                      {idx === 0 && (
                        <>
                          <td rowSpan={city_countData.cities.length}>
                            {city_countData.accountId}
                          </td>
                        </>
                      )}
                      <td>{city.cityName}</td>
                      <td>{city.value}</td>
                      <td>{city.percentage}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No city data found.</td>
                  </tr>
                )}
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan="4">No city data found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DataDisplay;
