import React, { useState } from "react";
import InviteForm from "../pages/userRole"; // Import the InviteForm component
import InviteFormBrand from "./userBrandROle";
import { useLocation } from "react-router-dom";

const Modal = ({
  path,
  onSubmitBrand,
  onSubmitAdmin,
  fromInternalSignIn,
  showBrandUsers,
  role,
  brandName,
}) => {
  const location = useLocation();
  // const [users, setUsers] = useState([]);
  console.log("Role:", role);
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  // Function to update the user table when a new user is added
  // const updateUserTable = (newUser) => {
  //   setUsers((prevUsers) => [...prevUsers, newUser]); // Add the new user to the existing list
  // };
  // console.log("Updated Users:", users);
  // console.log("showBrandUsers:", showBrandUsers);

  return (
    <>
      <button className="button is-primary mb-5" onClick={openModal}>
        Open Invite Form
      </button>
      {isOpen && (
        <div className="modal is-active">
          <div className="modal-background" onClick={closeModal}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title has-text-centered mt-5">
                Invite Form
              </p>
              <button
                className="delete"
                aria-label="close"
                onClick={closeModal}
              ></button>
            </header>
            <section className="modal-card-body">
              {/* {location.pathname === "/brand-dashboard" ? ( */}
              {showBrandUsers || role !== "admin" ? (
                <InviteFormBrand
                  // updateUserTable={updateUserTable}
                  // users={users}
                  // setUsers={setUsers}
                  onSubmitBrand={onSubmitBrand}
                  onClose={closeModal}
                  fromInternalSignIn={fromInternalSignIn}
                  showBrandUsers={showBrandUsers}
                  role={role}
                  brandName={brandName}
                />
              ) : (
                <InviteForm
                  role={role}
                  onSubmitAdmin={onSubmitAdmin}
                  onClose={closeModal}
                  brandName={brandName}
                />
              )}
              {/* Pass onClose prop to the InviteForm */}
            </section>
          </div>
        </div>
      )}
    </>
  );
};
export default Modal;
